import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import ProductsMovementsTrackingStore from "./products.movements.tracking.store";
import {useEffect} from "react";
import {Text} from "../../../styles/text";

interface ProductsMovementsTrackingViewProps {
    operationID: number;
    operationType: string;
    serialID?: number;
}

const ProductsMovementsTrackingView = observer(({operationID, operationType, serialID}: ProductsMovementsTrackingViewProps) => {
    const {trackList, setMovementTrackFilters ,destroy} = ProductsMovementsTrackingStore;
    const handlePageInit = (operationID: number, operationType: string, signal: AbortSignal) => {
        switch (operationType) {
            case 'TRANSACTION':
                return setMovementTrackFilters({transaction_id: operationID}, signal)
            case 'TRANSFER':
                return setMovementTrackFilters({transfer_id: operationID}, signal)
            case 'SHIPMENT':
                return setMovementTrackFilters({shipment_id: operationID}, signal)
            case 'MOVEMENT':
                if (serialID !== undefined && serialID !== 0) {
                    return setMovementTrackFilters({serial_id: serialID}, signal)
                } else {
                    return setMovementTrackFilters({movement_id: operationID}, signal)
                }
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        destroy();
        handlePageInit(operationID, operationType, controller.signal);

        return () => {
            return controller.abort();
        }
    }, []);


    return (
        <Layout vertical between={5}>
            {trackList?.map((track, index) => (
                <Layout
                    hAlign={"space-around"}
                    vAlign={"center"}
                    style={{
                        maxWidth: '100%',
                        display: 'flex',
                        boxShadow: '0 0 5px grey',
                        padding: '15px',
                        borderRadius: '5px',
                    //    marginBottom: '5px',
                    }}
                >
                    <Layout vertical between={10} widthSize={'25%'} hAlign={"flex-start"}>
                        <Text>{track.responsible_position}</Text>
                    </Layout>
                    <Layout vertical between={10} widthSize={'25%'} hAlign={"flex-start"}>
                        <Layout>
                            <Text color={track.inflow ? 'green' : 'red'}>{track.operation_type}</Text>
                        </Layout>
                    </Layout>
                    <Layout vertical between={10}>
                        <Layout>
                            <Text>{track.value_text}</Text>
                        </Layout>
                    </Layout>
                    <Layout>
                        <Text>{track.date_text}</Text>
                    </Layout>
                </Layout>
            ))}
        </Layout>
    );
});

export default ProductsMovementsTrackingView;