import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {AddButton, TableHeader} from "../../styles/table-header";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import ModalView from "../../components/modal/modal.view";
import {Page} from "../../styles/page";
import BusinessUnitsTransferFundsStore from "./business_units.transfer_funds.store";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {useEffect, useState} from "react";
import BusinessUnitsStore from "../business_units/business_units.store";
import BusinessUnitsTransferFundsCreateView
    from "./business_units.transfer_funds.modal/business_units.transfer_funds.create.view";
import {Layout} from "../../styles/layout";

const TransferFundsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        transferFunds,
        transferFundsPageInit,
        transferFundsFilters,
        setTransferFundsFilters,
        loading
    } = BusinessUnitsTransferFundsStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const {setBusinessUnitFilter, businessUnits} = BusinessUnitsStore;
    const [filterModal, setFilterModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        void transferFundsPageInit(controller.signal);
        destroyDictionary();
        void dictionaryListReq('TRANSFER_FUNDS', 'TABLE HEADERS', controller.signal);
        setBusinessUnitFilter({page: 1, page_limit: 100000});

        return () => {
            controller.abort();
        }

    }, [transferFundsPageInit]);

    const typeIdOptions = [{label: "Перевод между офисами", value: "1"}, {label: "Вывод средств из бизнеса", value: "2"}, {label: "Внесение средств в бизнес извне", value: "3"}]

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const prevPage = () => {
        if (transferFundsFilters.page! > 1) {
            setTransferFundsFilters({page: transferFundsFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (transferFundsFilters.page! < transferFundsFilters.pages!) {
            setTransferFundsFilters({page: transferFundsFilters.page! + 1});
        }
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setTransferFundsFilters({page: index + 1});
            return;
        }
        setTransferFundsFilters({[objectKey]: options[selectedIndex].value});
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const filterInputs: any[] = [
        {
            fieldName: 'business_unit_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
        },
        {
            fieldName: 'type_id',
            placeholder: 'Все типы',
            type: 'select',
            options: typeIdOptions,
        },
    ];

    const handleCloseCreateEditModal = () => {
        setCreateModal(false);
    }

    return (
        <Layout vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('TRANSFER_FUNDS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={transferFundsFilters}
                        handleFilterChange={handleFilterChange}
                    />
                </Filters>
                <FilterButton
                    id={'movements-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton onClick={() => setCreateModal(true)}> Создать </AddButton>
            </TableHeader>
            <TableDesktopView
                data={transferFunds}
                headers={dictionaryList}
                loading={loading}
            />
            <TableMobileView
                data={transferFunds}
                headers={dictionaryList}
                loading={loading}
            />
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={transferFundsFilters.pages}
                currentPage={transferFundsFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {filterModal && (
                <ModalView onClose={() => setFilterModal(false)}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={transferFunds}
                        handleFilterChange={handleFilterChange}
                    />
                </ModalView>
            )}
            {createModal && (
                <ModalView
                    title={'Создать операцию'}
                    onClose={handleCloseCreateEditModal}
                >
                    <BusinessUnitsTransferFundsCreateView setModal={setCreateModal}/>
                </ModalView>
            )}
        </Layout>
    )
})

export default TransferFundsView
