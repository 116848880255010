
import {AddButton, TableHeader} from "../../styles/table-header";
import { Filters } from '../../styles/filters';
import ProductSerialNumberFilter from './product_serial_numbers.filters.view';
import {TableContainer} from "../../styles/table";
import TablePaginationView from "../../utils/table.pagination.view";
import {useEffect, useState} from "react";
import SerialNumbersStore from './product_serial_numbers.store';
import BusinessUnitsStore from "../business_units/business_units.store";
import {UsersModel} from "../users/users.model";
import SerialNumbersTableDesktopView from "./product_serial_numbers.table.desktop.view";
import {observer} from "mobx-react";
import Modal from "../../components/modal/modal.view";
import SerialNumbersCreateView from "./product_serial_number.create.view";
import {Layout} from '../../styles/layout';
import AppStore from "../../app.store";

interface SerialNumbersViewProps {
    product_id: number,
    business_unit_id: number,
}

const SerialNumbersView = observer(  ({product_id, business_unit_id}:SerialNumbersViewProps) => {
    const {
        serialNumbers,
        serialNumbersFilter,
        serialNumbersPageInit,
        setSerialNumbersFilter,
        loading,
        clearFilter,
    } = SerialNumbersStore;

    const { businessUnits, businessUnitsInit } = BusinessUnitsStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<UsersModel | null>(null);
    useEffect(() => {
        const controller = new AbortController();
        serialNumbersPageInit(controller.signal, product_id, business_unit_id);
        businessUnitsInit(controller.signal);

        return () => {
            controller.abort();
        };
    }, [businessUnitsInit, serialNumbersPageInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: { options, selectedIndex },
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setSerialNumbersFilter({ page: index + 1 });
            return;
        }
        setSerialNumbersFilter({ [objectKey]: options[selectedIndex].value });
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && serialNumbersFilter.num !== '') {
            setSerialNumbersFilter({ num: '' });
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setSerialNumbersFilter({ num: e.target.value });
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const prevPage = () => {
        if (serialNumbersFilter.page > 1) {
            setSerialNumbersFilter({ page: serialNumbersFilter.page - 1 });
        }
    };

    const nextPage = () => {
        if (serialNumbersFilter.page < serialNumbersFilter.pages) {
            setSerialNumbersFilter({ page: serialNumbersFilter.page + 1 });
        }
    };

    const pageLimits = [5, 10, 25, 100, 500];

    return (
        <Layout vertical between={10}>
            <>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <ProductSerialNumberFilter
                            handleKeyDown={handleKeyDown}
                            handleFilterChange={handleFilterChange}
                            cancelSearch={cancelSearch}
                            businessUnits={businessUnits}
                            inModal={false}
                        />
                    </Filters>
                    <AddButton type="button" id="add-user" onClick={() => setModal(true)}>
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <SerialNumbersTableDesktopView
                        loading={loading}
                        serialNumbers={serialNumbers!}
                        /*setConfirmDeleteModal={setConfirmDeleteModal}*/
                        handleContext={handleContext}
                        anchorEl={anchorEl}
                        /*tableHeaders={getTableHeaders}*/
                    />
                </TableContainer>
                {/*<TableContainerMobile>
                    <UsersTableMobileView
                        loading={loading}
                        users={users!}
                        handleAccountClick={handleAccountClick}
                        handleEditClick={handleEditClick}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>*/}
                {/*<TablePaginationView
                    prevPage={prevPage}
                    nextPage={nextPage}
                    pages={serialNumbersFilter.pages}
                    currentPage={serialNumbersFilter.page}
                    pageLimits={pageLimits}
                    handleFilterChange={handleFilterChange}
                />*/}
            </>
            {modal && (
                <Modal
                    title={
                        'Добавить новые серийные номера'
                    }
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <SerialNumbersCreateView setModal={setModal} />
                </Modal>
            )}
        </Layout>
    )
})

export default SerialNumbersView;