import { observer } from "mobx-react";
import AppStore from "../../app.store";
import { useEffect, useState } from "react";
import { Page } from "../../styles/page";
import { Headline } from "../../styles/headline";
import { primaryColor } from "../../utils/variables";
import { Layout } from "../../styles/layout";
import {
  Search,
  SearchLayout,
  Select,
  TableHeader,
} from "../../styles/table-header";
import { Image } from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import BusinessServicesStore from "./business_service.store";
import {
  Table,
  TableBody,
  TableContainer, TableContainerMobile,
  TableHead,
  TCell,
  THeadCell,
  TRow,
} from "../../styles/table";
import Modal from "../../components/modal/modal.view";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {Filters} from "../../styles/filters";
import {Button, FilterButton} from "../../styles/button";
import TablePaginationView from "../../utils/table.pagination.view";
import BusinessServiceFiltersView from "./business_service.filters.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import OperationView from "../products_transactions/create.operation.modal/operation.view";

const BusinessServiceView = observer(() => {
  const { isNavOpen } = AppStore;
  const {
    businessServices,
    businessServicesFilter,
    businessServicesPageInit,
    setBusinessServicesFilter,
    loading,
      clearFilter
  } = BusinessServicesStore;

  const [filterModal, setFilterModal] = useState(false);
  const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

  useEffect(() => {
    destroyDictionary();
    const controller = new AbortController();
    dictionaryListReq('SERVICES', 'TABLE HEADERS', controller.signal);
    businessServicesPageInit(controller.signal);
    return () => controller.abort();
  }, [destroyDictionary, dictionaryListReq, businessServicesPageInit]);

  const handleFilterChange = (e: any, objectKey: string, index?: number) => {
    const {
      target: { options, selectedIndex },
    } = e;
    if (objectKey === "page" && index !== undefined) {
      setBusinessServicesFilter({ page: index + 1 });
      return;
    }
    setBusinessServicesFilter({ [objectKey]: options[selectedIndex].value });
  };

  const cancelSearch = (e: any) => {
    if (e.target.value === "" && businessServicesFilter.search !== "") {
      setBusinessServicesFilter({ search: "" });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      setBusinessServicesFilter({ search: e.target.value });
    }
  };

  const prevPage = () => {
    setBusinessServicesFilter({ page: businessServicesFilter.page - 1 });
  };

  const nextPage = () => {
    setBusinessServicesFilter({ page: businessServicesFilter.page + 1 });
  };

  const handleClearFilter = () => {
    clearFilter();
    setFilterModal(false);
  }

  const handleConfirmFilter = () => {
    setFilterModal(false);
  }

  const pageLimits = [5, 10, 25, 100, 500];

  return (
    <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
      <>
        <Headline color={primaryColor}>{getPageTitle('SERVICES')}</Headline>
        <TableHeader>
          <Filters>
              <Layout between={10} wrapped vAlign="center">
                <SearchLayout>
                  <Search
                    type="search"
                    id="search"
                    placeholder="Поиск по названию"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => cancelSearch(e)}
                  ></Search>
                  <Image src={SearchIcon} height="16px" />
                </SearchLayout>
                <Select
                  id="outflow"
                  onChange={(e) => handleFilterChange(e, "outflow")}
                >
                  <option value="">Все типы операций</option>
                  <option value="false">Приход</option>
                  <option value="true">Расход</option>
                </Select>
              </Layout>
          </Filters>
          <FilterButton
              id={'business-service-filter-button'}
              onClick={() => setFilterModal(true)}
          >
            Фильтры
          </FilterButton>
          <div style={{marginLeft: 10}}>
            <OperationView />
          </div>
        </TableHeader>
        <TableContainer isMacOS={AppStore.isMacOS}>
          <Table>
            <TableHead>
              <TRow tableHead>
                <THeadCell>{getTableHeaders('service_name')}</THeadCell>
                <THeadCell>{getTableHeaders('service_description')}</THeadCell>
                <THeadCell>{getTableHeaders('category')}</THeadCell>
              </TRow>
            </TableHead>
            {loading ? (<TableSkeleton columns={3}/>) : (
                <TableBody>
                  {businessServices!.length ? (
                      businessServices?.map((businessService, index) => (
                          <TRow key={index} menu>
                            <TCell>{businessService.service_name}</TCell>
                            <TCell>{businessService.service_description}</TCell>
                            <TCell>{businessService.category}</TCell>
                          </TRow>
                      ))
                  ) : (
                      <NoResultTitle colspan={3} />
                  )}
                </TableBody>
            )}
          </Table>
        </TableContainer>
        <TableContainerMobile>
          <Table>
            <TableHead>
              <TRow tableHead>
                <THeadCell>{getTableHeaders('service_name')}</THeadCell>
                <THeadCell>{getTableHeaders('service_description')}</THeadCell>
                <THeadCell>{getTableHeaders('category')}</THeadCell>
              </TRow>
            </TableHead>
            {loading ? (<TableSkeleton columns={3}/>) : (
                <TableBody>
                  {businessServices!.length ? (
                      businessServices?.map((businessService, index) => (
                          <TRow key={index} menu>
                            <TCell>{businessService.service_name}</TCell>
                            <TCell>{businessService.service_description}</TCell>
                            <TCell>{businessService.category}</TCell>
                          </TRow>
                      ))
                  ) : (
                      <NoResultTitle colspan={3} />
                  )}
                </TableBody>
            )}
          </Table>
        </TableContainerMobile>
        <TablePaginationView
            prevPage={prevPage}
            nextPage={nextPage}
            pages={businessServicesFilter.pages}
            currentPage={businessServicesFilter.page}
            pageLimits={pageLimits}
            handleFilterChange={handleFilterChange}
        />
      </>
      {filterModal && (
          <Modal
              title={"Фильтры"}
              onClose={() => setFilterModal(false)}
          >
            <Layout vertical between={10}>
              <BusinessServiceFiltersView
                  handleKeyDown={handleKeyDown}
                  cancelSearch={cancelSearch}
                  handleFilterChange={handleFilterChange}
                  inModal={true}
              />
              <Layout hAlign={"space-between"} between={10}>
                <Button
                    extent={"L"}
                    id={"clear-filter-button"}
                    onClick={handleClearFilter}
                    background={"#333"}
                    color={"red"}
                > Очистить </Button>
                <Button
                    extent={"L"}
                    id={"confirm-filter-button"}
                    onClick={handleConfirmFilter}
                > Потвердить </Button>
              </Layout>
            </Layout>
          </Modal>
      )}

    </Page>
  );
});

export default BusinessServiceView;
