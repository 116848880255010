import { Layout } from "../../styles/layout";
import { Form, TextInput } from "../../styles/form";
import { Image } from "../../styles/image";
import { Button } from "../../styles/button";
import { Error } from "../../styles/error";
import { darkBackground } from "../../utils/variables";

import { observer } from "mobx-react";
import AppStore from "../../app.store";
import LoginStore from "./login.store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import logo from "../../assets/logo.svg";
import showPasswordIcon from "../../assets/icons/show-password.svg";
import hidePasswordIcon from "../../assets/icons/hide-password.svg";
import { PasswordLayout } from "./login.style";

const LoginView = observer(() => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoggedIn, setIsLoggedIn, isNavOpen } = AppStore;
  const {
    form: { username, password },
    setForm,
    isValid,
    loginReq,
    isLoading,
    backendError,
    destroy,
  } = LoginStore;

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const login = await loginReq();
    if (login) {
      setIsLoggedIn(true);
      destroy();
      setTimeout(() => {
        navigate('/');
      }, 1500);
    }
  };

  return (
    <Layout
      fullPage
      centered
      background={darkBackground}
      loginAnimation={{ isLoggedIn, isNavOpen }}
    >
      <Form>
        <Layout centered vertical between={20}>
          <Layout centered>
            <Image src={logo} extent="S" />
          </Layout>
          <Layout between={8} vertical hAlign="center">
            <TextInput
              darkMode
              id="username"
              placeholder="Логин"
              login
              extent="M"
              type="text"
              value={username}
              onChange={(e) => setForm(e.target.value, "username")}
            />
            <PasswordLayout>
              <TextInput
                darkMode
                id="password"
                placeholder="Пароль"
                type={showPassword ? "text" : "password"}
                login
                extent="M"
                value={password}
                onChange={(e) => setForm(e.target.value, "password")}
              />
              <Image
                src={showPassword ? showPasswordIcon : hidePasswordIcon}
                height="16px"
                onClick={() => setShowPassword(!showPassword)}
              />
            </PasswordLayout>
            <Button
              extent="M"
              isLoading={isLoading}
              onClick={handleLogin}
              disabled={isValid}
            >
              {isLoading ? "" : "Вход"}
            </Button>
            <Error>{backendError}</Error>
          </Layout>
        </Layout>
      </Form>
    </Layout>
  );
});

export default LoginView;
