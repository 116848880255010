import {RoleModel, RoleFilterModel} from "./roles.model";
import { makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";
import { toast } from "react-toastify";


class RolesStore {
    public roles ?: RoleModel[] = [];
    public rolesFilter = {
        search: '',
        page: 1,
        page_limit: 25,
        pages: 0,
        order_by: 'name',
        order_descending: false,
    }

    public loading = false;
    public message = '';
    public error = '';

    public rolesInit = (signal: any) => {
        void this.rolesReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setRoles(data: any) {
        const {roles} = data

        this.roles = roles;
    }

    public rolesReq = async (signal?: any) => {
        this.setLoading(true);
        try {
            const {getToken} = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!,
            }

            const filterUrl = Object.keys(this.rolesFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof RoleFilterModel;
                if (this.rolesFilter[filterKeyName] !== 0 &&
                    this.rolesFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.rolesFilter[filterKeyName];
                }

                return filters;
            }, '');

            const response = await axios.get(api.get_roles + filterUrl, {headers, signal});

            this.setRoles(response.data)
            this.setRolesFilterPages(response.data.pages)
        } catch (err) {
            console.error(err);
        } finally {
            this.setLoading(false);
        }
    }

    public setRolesFilter = ({search, page, page_limit}: RoleFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.rolesFilter = {
            ...this.rolesFilter,
            search: isUndefined(search) ? this.rolesFilter.search! : search!,
            page: isUndefined(page) ? this.rolesFilter.page! : page!,
            page_limit: isUndefined(page_limit) ? this.rolesFilter.page_limit! : page_limit!,
        };

        if (page_limit) {
            this.rolesFilter.page = 1;
        }

        void this.rolesReq();
    }

    public deleteRole = async (id: number) => {
        this.loading = true;

        try {
            const {getToken} = AppStore
            const headers = {
                Authorization: "Bearer " + getToken()!
            }
            const response = await axios.delete(api.role + '/' + id, {headers});
            // if (res?.code >= 400) {
            //     throw new Error(res)
            // }
            this.message = response?.data.message;
            toast.success(this.message);

            if (response) {
                this.loading = false;
                void this.rolesReq();
            }
        } catch (err:any) {
            this.loading = false;
            this.error = err?.response.data.reason;
            toast.error(this.error);
        }
    }

    public setRolesFilterPages = (pages: number) => {
        this.rolesFilter.pages = pages;
    }
    private setLoading = (loadingState: boolean) => {
        this.loading = loadingState;
    }

    public clearRolesFilter = () => {
        this.rolesFilter = {
            search: '',
            page: 1,
            page_limit: 25,
            pages: 0,
            order_by: 'name',
            order_descending: false,
        }
    }
}

export default new RolesStore();