import {observer} from "mobx-react";
import {Page} from "../../../styles/page";
import AppStore from "../../../app.store";
import {useEffect, useState} from "react";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import FinReportsStore from "./fin.reports.store";
import {FinReportFilterModel} from "./fin.reports.model";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import {TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import {Button, FilterButton} from "../../../styles/button";
import {Layout} from "../../../styles/layout";
import {Text} from "../../../styles/text";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import FinReportsDesktopView from "./fin.reports.desktop.view";
import FinReportsMobileView from "./fin.reports.mobile.view";
import FinReportsFiltersView from "./fin.reports.filters.view";
import ModalView from "../../../components/modal/modal.view";

const FinReportsView = observer(() => {
    const {isNavOpen} = AppStore;
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const {
        getFinReportsReq,
        setFinReportFilters,
        totals,
        clearFilter
    } = FinReportsStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('FINANCIAL REPORTS', 'TABLE HEADERS', controller.signal);
        void getFinReportsReq(controller.signal);
        clearFilter()
        return () => {
            controller.abort();
        };

    }, [destroyDictionary, dictionaryListReq]);

    const setDates = (e: any) => {
        if (e !== null) {
            setFinReportFilters({date_to: e[1].format('YYYY-MM-DD')} as FinReportFilterModel);
            setFinReportFilters({date_from: e[0].format('YYYY-MM-DD')} as FinReportFilterModel);
        } else {
            setFinReportFilters({date_from: ''} as FinReportFilterModel);
            setFinReportFilters({date_to: ''} as FinReportFilterModel);
        }
    };

    const handleFilterChange = (e: any, objectKey: string) => {
        const {
            target: {options, selectedIndex},
        } = e;
        setFinReportFilters({[objectKey]: options[selectedIndex].value} as FinReportFilterModel);
    };

    const handleOrder = (column: string, asc: boolean) => {
        setFinReportFilters({order_by: column, order_asc: `${asc}`} as FinReportFilterModel)
    }

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('FINANCIAL REPORTS')}</Headline>
            <Layout vertical between={10}>
                <TableHeader>
                    <Filters>
                        <FinReportsFiltersView setDates={setDates} handleFilterChange={handleFilterChange}/>
                    </Filters>
                    <FilterButton
                        id={'fin-report-filter-button'}
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                </TableHeader>
                <Layout vertical between={10}>
                    <Layout>
                        <Text weight='bold'>Итого</Text>
                    </Layout>
                    <Layout
                        vAlign={"center"}
                        between={30}
                        style={{
                            padding: '15px',
                            borderRadius: '5px',
                            width: '100%',
                            background: '#ffffff'
                        }}
                    >
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('sales')}:`}</Text>
                            <Text>{totals.sales}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('cost')}:`}</Text>
                            <Text>{totals.cost}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('sales_profit')}:`}</Text>
                            <Text>{totals.sales_profit}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('other_expenses')}:`}</Text>
                            <Text>{totals.other_expenses}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('salary')}:`}</Text>
                            <Text>{totals.salary}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('bonuses')}:`}</Text>
                            <Text>{totals.bonuses}</Text>
                        </Layout>
                        <Layout between={5}>
                            <Text>{`${getTableHeaders('saldo')}:`}</Text>
                            <Text>{totals.saldo}</Text>
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
            <TableContainer isMacOS={AppStore.isMacOS}>
                <FinReportsDesktopView
                    getTableHeaders={getTableHeaders}
                    handleOrder={handleOrder}
                />
            </TableContainer>
            <TableContainerMobile>
                <FinReportsMobileView
                    getTableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            {filterModal && (
                <ModalView
                    onClose={() => setFilterModal(false)}
                    title='Фильтры'
                >
                    <Layout vertical between={10}>
                        <FinReportsFiltersView
                            setDates={setDates}
                            handleFilterChange={handleFilterChange}
                            inModal
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </ModalView>
            )}
        </Page>
    );
});

export default FinReportsView;