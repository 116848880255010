import {observer} from "mobx-react";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import PurchaseBillsStore from "./purchase.bills.store";
import {useEffect, useState} from "react";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {AddButton, TableHeader} from "../../styles/table-header";
import TableDesktopView from "../../components/table/render.table.desktop";
import TableMobileView from "../../components/table/render.table.mobile";
import TablePaginationView from "../../utils/table.pagination.view";
import {PurchaseBillsModel} from "./purchase.bills.model";
import SuppliersStore from "../suppliers/suppliers.store";
import BusinessUnitsStore from "../business_units/business_units.store";
import ProductsStore from "../products/products.store";
import UsersStore from "../users/users.store";
import {Filters} from "../../styles/filters";
import FilterView from "../../components/filters/filters.view";
import {FilterButton} from "../../styles/button";
import ModalView from "../../components/modal/modal.view";
import PurchaseBillsModalView from "./purchase.bills.modal/purchase.bills.modal.view";
import AccountModalView from "../../components/modal/account.modal.view";
import PurchaseBillsModalStore from "./purchase.bills.modal/purchase.bills.modal.store";
import PurchaseBillsOperationsView from "../purchase.bills.operations/purchase.bills.operations.view";
import PurchaseBillsPaymentView from "./purchase.bills.payment.modal/purchase.bills.payment.view";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";

const PurchaseBillsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        bills,
        billsFilters,
        loading,
        setBillsFilter,
        initBillsPage,
        receiveBillReq,
        getBillsReq,
        cancelBillReq
    } = PurchaseBillsStore;

    const {destroy} = PurchaseBillsModalStore;
    const {destroyDictionary, dictionaryListReq, getPageTitle, dictionaryList} = DictionaryStore;
    const [filterModal, setFilterModal] = useState(false);
    const [initialValue, setInitialValue] = useState<PurchaseBillsModel | null>(null);
    const {suppliers, setSuppliersFilter} = SuppliersStore;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;
    const {products, setProductsFilter} = ProductsStore;
    const {users, setUsersFilter} = UsersStore;
    const [createEditModal, setCreateEditModal] = useState(false);
    const [cancelModal, setCancelModal] = useState<DeleteItem>({isModalOpen: false});
    const [receiveModal, setReceiveModal] = useState<DeleteItem>({isModalOpen: false});
    const [paymentModal, setPaymentModal] = useState(false);
    const [operationsModal, setOperationsModal] = useState(false);

    useEffect(() => {
        destroyDictionary()
        const controller = new AbortController();
        void initBillsPage(controller.signal);
        void dictionaryListReq('PURCHASE_BILLS', 'TABLE HEADERS', controller.signal);
        setSuppliersFilter({page: 1, page_limit: 100000, technical: true});
        setBusinessUnitFilter({page: 1, page_limit: 100000});
        setProductsFilter({page: 1, page_limit: 100000});
        setUsersFilter({page: 1, page_limit: 10000});
        return () => {
            return controller.abort();
        }
    }, [initBillsPage, dictionaryListReq, setSuppliersFilter, setBusinessUnitFilter, setProductsFilter, setUsersFilter]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setBillsFilter({page: index + 1});
            return;
        }
        setBillsFilter({[objectKey]: options[selectedIndex].value});
    };

    const prevPage = () => {
        if (billsFilters.page! > 1) {
            setBillsFilter({page: billsFilters.page! - 1});
        }
    };

    const nextPage = () => {
        if (billsFilters.page! < billsFilters.pages!) {
            setBillsFilter({page: billsFilters.page! + 1});
        }
    };

    const pageLimits = [25, 50, 100, 250, 500];

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const usersOptions = users?.map(
        ({id, full_name}) => ({label: full_name, value: id})
    )

    const productsOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const suppliersOptions = suppliers?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const deliveryStatusOptions = [
        {
            label: 'В процессе',
            value: 1
        },
        {
            label: 'Получено',
            value: 2
        },
        {
            label: 'Отменена',
            value: 3
        },
    ];

    const paymentStatusOptions = [
        {
            label: 'Ожидает оплаты',
            value: 1
        },
        {
            label: 'Частично оплачен',
            value: 2
        },
        {
            label: 'Оплачено',
            value: 3
        }
    ]

    const filterInputs = [
        {
            fieldName: 'user_id',
            placeholder: 'Все сотрудники',
            type: 'select',
            options: usersOptions,
        },
        {
            fieldName: 'business_unit_id',
            placeholder: 'Все подразделения',
            type: 'select',
            options: businessUnitsOptions,
        },
        {
            fieldName: 'product_id',
            placeholder: 'Все продукты',
            type: 'select',
            options: productsOptions,
        },
        {
            fieldName: 'supplier_id',
            placeholder: 'Все поставщики',
            type: 'select',
            options: suppliersOptions,
        },
        {
            fieldName: 'delivery_status_id',
            placeholder: 'Все статусы доставки',
            type: 'select',
            options: deliveryStatusOptions,
        },
        {
            fieldName: 'payment_status_id',
            placeholder: 'Все статусы оплаты',
            type: 'select',
            options: paymentStatusOptions,
        },
    ];

    const handleCloseFilterModal = () => {
        setFilterModal(false);
    }

    const handlePayClick = (bill: PurchaseBillsModel) => {
        setPaymentModal(true);
        setInitialValue(bill);
    }

    const handleClosePayClick = () => {
        setPaymentModal(false);
        setInitialValue(null);
    }

    const handleCancelClick = (bill: PurchaseBillsModel) => {
        setCancelModal({isModalOpen: true});
        setInitialValue(bill);
    }

    const handleCloseCancelModal = () => {
        setCancelModal({isModalOpen: false});
        setInitialValue(null);
    }

    const handleReceiveClick = (bill: PurchaseBillsModel) => {
        setReceiveModal({isModalOpen: true});
        setInitialValue(bill);
    }

    const handleCloseReceiveModal = () => {
        setReceiveModal({isModalOpen: false});
        setInitialValue(null);
    }

    /*    const handleEditClick = (bill: PurchaseBillsModel) => {
            setCreateEditModal(true);
            setInitialValue(bill);
        }*/

    const handleCloseEditModal = () => {
        setCreateEditModal(false);
        setInitialValue(null);
        destroy();
    }

    const handleOperationsClick = (bill: PurchaseBillsModel) => {
        setOperationsModal(true);
        setInitialValue(bill);
    }

    const handleCloseOperationsClick = () => {
        setOperationsModal(false);
        setInitialValue(null);
    }

    const handleReceiveBill = async () => {
        const response = await receiveBillReq(initialValue?.id!)

        if (response) {
            setReceiveModal({isModalOpen: false})
            await getBillsReq()
        }
    }

    const handleCancelBill = async () => {
        const response = await cancelBillReq(initialValue?.id!);

        if (response) {
            setCancelModal({isModalOpen: false})
            await getBillsReq();
        }
    }

    const paymentConditions = (bill: PurchaseBillsModel) => {
        return bill.remaining > 0 || bill.payment_status !== 3
    }

    const receiveConditions = (bill: PurchaseBillsModel) => {
        return bill.delivery_status === 1;
    }

    const contextMenuOptions = [
        /* {
             name: 'Изменить',
             onClick: (bill: PurchaseBillsModel) => handleEditClick(bill)
         },*/
        {
            name: 'Получить',
            onClick: (bill: PurchaseBillsModel) => handleReceiveClick(bill),
            condition: (bill: PurchaseBillsModel) => receiveConditions(bill)
        },
        {
            name: 'Отменить',
            onClick: (bill: PurchaseBillsModel) => handleCancelClick(bill),
            condition: () => true,
        },
        {
            name: 'Оплатить',
            onClick: (bill: PurchaseBillsModel) => handlePayClick(bill),
            condition: (bill: PurchaseBillsModel) => paymentConditions(bill)
        },
    ]

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('PURCHASE_BILLS')}</Headline>
            <TableHeader>
                <Filters hAlign="space-between" wrapped between={10}>
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={false}
                        filters={billsFilters}
                        handleFilterChange={handleFilterChange}
                    />
                </Filters>
                <FilterButton
                    id={'bills-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton onClick={() => setCreateEditModal(true)}>Создать</AddButton>
            </TableHeader>
            <TableDesktopView data={bills} headers={dictionaryList} loading={loading} hasContext
                              contextOptions={contextMenuOptions}/>
            <TableMobileView data={bills} headers={dictionaryList} loading={loading} hasContext
                             contextOptions={contextMenuOptions}/>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={billsFilters.pages}
                currentPage={billsFilters.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {createEditModal && (
                <AccountModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseEditModal}
                    fullScreen
                >
                    <PurchaseBillsModalView initialValues={initialValue} setModal={setCreateEditModal}/>
                </AccountModalView>
            )}
            {paymentModal && (
                <ModalView
                    title={'Оплатить поставку'}
                    onClose={handleClosePayClick}
                >
                    <PurchaseBillsPaymentView initialValue={initialValue!} setModal={setPaymentModal}/>
                </ModalView>
            )}
            {cancelModal.isModalOpen && (
                <ModalView
                    title={'Отменить заявку'}
                    onClose={handleCloseCancelModal}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что отменить этот закуп?`}
                        setModal={setCancelModal}
                        callback={handleCancelBill}
                        isLoading={loading}
                        buttonColor={primaryColor}
                        buttonText={'Потвердить'}
                    />
                </ModalView>
            )}
            {receiveModal.isModalOpen && (
                <ModalView
                    title={'Получить заказ'}
                    onClose={handleCloseReceiveModal}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что получили этот закуп?`}
                        setModal={setReceiveModal}
                        callback={handleReceiveBill}
                        isLoading={loading}
                        buttonColor={primaryColor}
                        buttonText={'Потвердить'}
                    />
                </ModalView>
            )}
            {operationsModal && (
                <AccountModalView
                    title={`Операции`}
                    onClose={handleCloseOperationsClick}
                >
                    <PurchaseBillsOperationsView/>
                </AccountModalView>
            )}
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={handleCloseFilterModal}
                >
                    <FilterView
                        filterInputs={filterInputs}
                        isInModal={true}
                        filters={billsFilters}
                        handleFilterChange={handleFilterChange}
                    />
                </ModalView>
            )}
        </Page>
    );
});

export default PurchaseBillsView;