import {ShipmentsModel} from "../shipments.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const loadingInitialValue: boolean = false;
const formInitialValues: ShipmentsModel = {
    id: 0,
    shipment_owner_id: 0,
    supplier_id: 0,
    supplier_name: '',
    business_unit_id: 0,
    purchase_bill_id: 0,
    business_unit_name: '',
    user_id: 0,
    user_name: '',
    product_id: 0,
    product_name: '',
    quantity: 0,
    remaining: 0,
    unit_cost: 0,
    created_at_text: '',
    total: 0,
    brand: '',
    length: 0,
    width: 0,
    height: 0,
    quantity_in_pack: 0,
    pack_weight: 0,
    original_cost: 0,
    extra_expense: 0,
    cost_by_weight: 0,
    cost_by_cbm: 0,
    has_serial: false,
    serials: [],
}

class ShipmentsModalStore {
    public loading = loadingInitialValue;
    public form = formInitialValues;
    public serials: string[] = [];
    public message: string = '';
    public reason: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    public destroy = () => {
        this.form = formInitialValues;
        this.loading = loadingInitialValue;
        this.message = '';
        this.reason = '';
        this.serials = [];
    }

    public setForm = (form: ShipmentsModel) => {
        this.form = form
    }

    public addSerials = (serials: string[]) => {
        this.serials.push(...serials)
    }

    public deleteSerialFromArray = (index: number) => {
        this.serials.splice(index, 1)
    }

    public shipmentsCreateEditInit = (initialValue: ShipmentsModel | null) => {
        if (initialValue) {
            this.form = initialValue;
        } else {
            this.form = formInitialValues;
        }
    }

    public shipmentCreateEditReq = async (edit: boolean) => {
        this.loading = true
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        if (this.serials.length > this.form.quantity) {
            this.reason = 'Количество серийных номеров больше чем положено'
            toast.error(this.reason);
            this.serials = [];
            this.loading = false;
            return;
        }

        const payload = {
            shipment: {
                id: Number(this.form.id),
                business_unit_id: Number(this.form.business_unit_id),
                product_id: Number(this.form.product_id),
                supplier_id: Number(this.form.supplier_id),
                quantity: Number(this.form.quantity),
                unit_cost: Number(this.form.unit_cost),
            },
            serials: this.serials
        }

        try {
            let response;

            if (edit) {
                response = await axios.put(api.shipments, payload, {headers});
            } else {
                response = await axios.post(api.shipments, payload, {headers});
            }

            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            console.log('e:', e)
            this.reason = e?.response?.data.reason
            this.serials = [];
            toast.error(this.reason);
        } finally {
            this.loading = false;
            this.serials = [];
        }
    }
}

export default new ShipmentsModalStore();