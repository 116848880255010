import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import NoResultTitle from "../no_result_title/no_result_title.view";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import {Image} from "../../styles/image";
import {useState} from "react";
import {DictionaryModel} from "../../utils/dictionary/dictionary.model";
import AppStore from "../../app.store";

export interface ContextOptionsProps {
    name: string;
    onClick: (item: any) => void;
    condition?: (item: any) => boolean;
}

interface TableDesktopViewProps {
    data: any[];
    headers: DictionaryModel[];
    hasContext?: boolean;
    loading: boolean;
    contextOptions?: ContextOptionsProps[];
}

const TableDesktopView = (({data, headers, hasContext, loading, contextOptions}: TableDesktopViewProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    return (
        <TableContainer isMacOS={AppStore.isMacOS}>
            <Table>
                <TableHead>
                    <TRow tableHead>
                        {headers.map((header, index) => (
                            <THeadCell key={`${header.code}_${index}`}>{header.value}</THeadCell>
                        ))}
                        {hasContext && (<THeadCell></THeadCell>)}
                    </TRow>
                </TableHead>
                {loading ? (<TableSkeleton columns={hasContext ? headers.length + 1 : headers.length}/>) : (
                    <TableBody>
                        {data?.length ? (
                            data.map((item, index) => (
                                <TRow key={item + index} menu>
                                    {headers.map((header, ind) => (
                                        <TCell key={ind}>{item[header.key]}</TCell>
                                    ))}
                                    {hasContext && (
                                        <TCell relative>
                                            <Image
                                                src={ContextMenuIcon}
                                                height="18px"
                                                onClick={handleContext}
                                            />
                                            <ContextMenu anchorEl={anchorEl}>
                                                {contextOptions?.map((context, i) =>
                                                    context.condition?.(item) ? ( // Check the condition for each menu item
                                                        <ContextMenuItem
                                                            onClick={() => {
                                                                context.onClick(item); // Execute action
                                                             //   handleCloseContextMenu(); // Close menu after action
                                                            }}
                                                            key={`${context.name}_${i}`}
                                                        >
                                                            {context.name}
                                                        </ContextMenuItem>
                                                    ) : null
                                                )}
                                                {/*{contextOptions?.map((context, i) => (
                                                    context.condition!(item) && (
                                                        <ContextMenuItem
                                                            onClick={() => context.onClick(item)}
                                                            key={`${context.name}_${i}`}
                                                        >
                                                            {context.name}
                                                        </ContextMenuItem>
                                                    )
                                                ))}*/}
                                            </ContextMenu>
                                        </TCell>
                                    )}
                                </TRow>
                            ))
                        ) : (
                            <NoResultTitle colspan={hasContext ? headers.length + 1 : headers.length}/>
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
});

export default TableDesktopView;