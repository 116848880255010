import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {useEffect, useRef, useState} from "react";
import {TextInput} from "../../../styles/form";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import * as XLSX from 'xlsx'
import ProductsImportExcelStore from "./products.create.store";
import {ProductQuantitiesModel, ProductsCreateModel, ProductsImportModel} from "./products.create.model";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import {Button} from "../../../styles/button";
import ProductsStore from "../products.store";
import ModalView from "../../../components/modal/modal.view";
import ProductsCreateQuantityView from "./products.create.quantity.view";
import BusinessUnitsStore from "../../business_units/business_units.store";
import {Badge} from "../../../styles/badge";
import {handleDownloadFile} from "../../../utils/handleDownloadFile";
import {api} from "../../../api/endpoints";
import AppStore from "../../../app.store";
import ProductsCreateAddProductView from "./products.create.add.product.view";

interface ProductsImportExcelViewProps {
    setModal: (isOpen: boolean) => void;
}

const ProductsCreateView = observer(({setModal}: ProductsImportExcelViewProps) => {

    const [typeError, setTypeError] = useState('');
    const {
        setExcelData,
        loading,
        importProductsReq,
        destroy,
        form,
        destroyProduct,
    } = ProductsImportExcelStore;
    const {productsReq} = ProductsStore;
    const {getToken} = AppStore;
    const hiddenFileInput = useRef(null);
    const [quantityModal, setQuantityModal] = useState(false);
    const [initialValue, setInitialValue] = useState<ProductsImportModel | null>(null);
    const {businessUnits, setBusinessUnitFilter, clearBusinessUnitFilter} = BusinessUnitsStore;
    const [productIndex, setProductIndex] = useState(0);
    const [addProductModal, setAddProductModal] = useState(false);

    useEffect(() => {
        setBusinessUnitFilter({page: 1, page_limit: 999999, return_balance: false})
        clearBusinessUnitFilter();
    }, [setBusinessUnitFilter]);

    const handleFileImport = (e: any) => {
        const fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (fileTypes.includes(selectedFile.type)) {
                setTypeError('');
                const reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    const workbook = XLSX.read(e.target!.result, {type: 'buffer'});
                    const worksheetName = workbook.SheetNames[0];
                    const activeSheet = workbook.Sheets[worksheetName];
                    const data = XLSX.utils.sheet_to_json<ProductsCreateModel>(activeSheet);
                    setExcelData(data);
                    for (let i = 0; i < form.length; i++) {
                        businessUnits?.forEach((bu) => {
                            const quantityModel: ProductQuantitiesModel = {
                                business_unit_id: bu.id,
                                quantity: 0,
                                expiry: [
                                    {
                                        production_date: new Date().toLocaleDateString('en-CA'),
                                        expiry_date: new Date().toLocaleDateString('en-CA'),
                                        quantity: 0,
                                    }
                                ],
                            }
                            form[i].quantities.push(quantityModel);
                        })
                    }
                }
            } else {
                setTypeError('Пожалуйста выберите файл Excel');
            }
        }
    }

    const handleUploadFileButtonClick = () => {
        //@ts-ignore
        hiddenFileInput.current?.click();
    }

    const handleTotalQuantity = (product: ProductsImportModel) => {
        let value = 0;
        if (product.has_expiry) {
            product.quantities.forEach((item) => {
                item.expiry.forEach((expiry) => {
                    value += expiry.quantity
                })
            })
        } else {
            product.quantities.forEach((item) => {
                value += item.quantity
            })
        }

        return value;
    }

    const handleSubmit = async () => {
        const resp = await importProductsReq();

        if (resp) {
            destroy()
            setModal(false);
            clearBusinessUnitFilter()
            await productsReq();
        }
    }

    const handleAddQuantityButtonClick = (product: ProductsImportModel, index: number) => {
        setQuantityModal(true);
        setInitialValue(product);
        setProductIndex(index);
    }

    const handleDownloadTemplate = async () => {
       await handleDownloadFile(api.product + `/excel-template`, getToken()!, 'Template', 'excel')
    }

    const handleCloseAddProductModal = () => {
        setAddProductModal(false);
        destroyProduct();
    }

    return (
        <Layout vertical between={20}>
            <Layout centered vertical between={5}>
                <Layout hAlign={"space-between"} vAlign={"center"} between={25}>
                    <Layout>
                        <Button
                            extent={'S'}
                            onClick={() => setAddProductModal(true)}
                        >
                            Добавить продукт
                        </Button>
                    </Layout>
                    <Layout>
                        <Button
                            extent={'S'}
                            onClick={handleUploadFileButtonClick}
                        >
                            Выберите файл
                        </Button>
                        <TextInput
                            type='file'
                            onChange={(e) => handleFileImport(e)}
                            style={{
                                display: "none"
                            }}
                            ref={hiddenFileInput}
                        />
                        {typeError !== '' && (
                            <Layout>
                                <Text color={modalTextColor} extent={'S'}>{typeError}</Text>
                            </Layout>
                        )}
                    </Layout>
                    <Layout>
                        <Button
                            extent={'S'}
                            onClick={handleDownloadTemplate}
                        >
                            Скачать шаблон
                        </Button>
                    </Layout>
                </Layout>
            </Layout>
            <Layout vertical between={5}>
                <Layout centered>
                    <Text color={primaryColor} extent={'M'} weight={'bold'}>Товары</Text>
                </Layout>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <Table inModal>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>Название</THeadCell>
                                <THeadCell>Описание</THeadCell>
                                <THeadCell>Номер</THeadCell>
                                <THeadCell>Категория</THeadCell>
                                <THeadCell>Бренд</THeadCell>
                                <THeadCell>Стоимость</THeadCell>
                                <THeadCell>Цена</THeadCell>
                                <THeadCell>Бонус</THeadCell>
                                <THeadCell>Срок годности</THeadCell>
                                <THeadCell>Количество</THeadCell>
                            </TRow>
                        </TableHead>
                        <TableBody>
                            {form.length ? (
                                form?.map((data, index) => (
                                    <TRow
                                        menu
                                        key={index}
                                        onClick={() => handleAddQuantityButtonClick(data, index)}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <TCell>{data.name}</TCell>
                                        <TCell>{data.description}</TCell>
                                        <TCell>{data.part_number}</TCell>
                                        <TCell>{data.category}</TCell>
                                        <TCell>{data.brand}</TCell>
                                        <TCell>{data.cost}</TCell>
                                        <TCell>{data.price}</TCell>
                                        <TCell>{data.bonus_percentage}</TCell>
                                        <TCell>
                                            <Badge online={data.has_expiry}>{data.has_expiry ? 'Да' : 'Нет'}</Badge>
                                        </TCell>
                                        <TCell>
                                            <Layout widthSize='20%' between={5}>
                                                <Text>{handleTotalQuantity(data)}</Text>
                                                <Button
                                                    extent={'S'}
                                                    onClick={() => handleAddQuantityButtonClick(data, index)}
                                                    style={{
                                                        display: "none"
                                                    }}
                                                >
                                                    Открыть
                                                </Button>
                                            </Layout>
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={10}/>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Layout>
            <Layout centered>
                <Button
                    extent={'S'}
                    isLoading={loading}
                    disabled={form?.length === 0}
                    onClick={handleSubmit}
                >
                    {loading ? '' : 'Потвердить'}
                </Button>
            </Layout>
            {quantityModal && (
                <ModalView
                    title={`Информация о продукте ${initialValue?.name}`}
                    onClose={() => setQuantityModal(false)}
                >
                    <ProductsCreateQuantityView
                        product={initialValue}
                        setModal={setQuantityModal}
                        businessUnits={businessUnits}
                        prodIndex={productIndex}
                    />
                </ModalView>
            )}
            {addProductModal && (
                <ModalView
                    title='Добавить продукт'
                    onClose={handleCloseAddProductModal}
                >
                    <ProductsCreateAddProductView
                        setModal={setAddProductModal}
                        businessUnits={businessUnits!}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default ProductsCreateView;