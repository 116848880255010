import { observer } from 'mobx-react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TCell,
  THeadCell,
  TRow,
} from '../../styles/table';
import { PageView } from '../../styles/page';
import { useEffect } from 'react';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import TransferStore from './transfer.store';
import { TableContainerMobile } from '../../styles/table';
import AppStore from "../../app.store";
interface TransferProductViewProps {
  transferId: string;
}
const TransferProductsView = observer(
  ({ transferId }: TransferProductViewProps) => {
    const { transfersProducts, loading, tranfersProductsReq } = TransferStore;

    useEffect(() => {
      const controller = new AbortController();
      tranfersProductsReq(transferId, controller.signal);

      return () => {
        controller.abort();
      };
    }, [tranfersProductsReq]);

    return (
      <PageView isNavOpen={false} vertical between={10}>
        <>
          <TableContainer isMacOS={AppStore.isMacOS}>
            <Table>
              <TableHead>
                <TRow tableHead>
                  <THeadCell>Номер</THeadCell>
                  <THeadCell>Название</THeadCell>
                  <THeadCell>Бренд</THeadCell>
                  <THeadCell>Количество</THeadCell>
                </TRow>
              </TableHead>
              {!loading ? (
                <TableBody>
                  {transfersProducts?.length ? (
                    transfersProducts?.map((product, index) => (
                      <TRow key={index} menu>
                        <TCell>{product.part_number}</TCell>
                        <TCell>{product.name}</TCell>
                        <TCell>{product.brand}</TCell>
                        <TCell>{product.quantity}</TCell>
                      </TRow>
                    ))
                  ) : (
                    <NoResultTitle colspan={4} />
                  )}
                </TableBody>
              ) : (
                <TableSkeleton columns={4} />
              )}
            </Table>
          </TableContainer>
          <TableContainerMobile>
            <Table>
              <TableHead>
                <TRow tableHead>
                  <THeadCell>Номер</THeadCell>
                  <THeadCell>Название</THeadCell>
                  <THeadCell>Бренд</THeadCell>
                  <THeadCell>Количество</THeadCell>
                </TRow>
              </TableHead>
              {!loading ? (
                <TableBody>
                  {transfersProducts?.length ? (
                    transfersProducts?.map((product, index) => (
                      <TRow key={index} menu>
                        <TCell>{product.part_number}</TCell>
                        <TCell>{product.name}</TCell>
                        <TCell>{product.brand}</TCell>
                        <TCell>{product.quantity}</TCell>
                      </TRow>
                    ))
                  ) : (
                    <NoResultTitle colspan={4} />
                  )}
                </TableBody>
              ) : (
                <TableSkeleton columns={4} />
              )}
            </Table>
          </TableContainerMobile>
        </>
      </PageView>
    );
  }
);

export default TransferProductsView;
