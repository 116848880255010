import {BusinessUnitFilterModel, BusinessUnitModel} from "./business_units.model";
import AppStore from "../../app.store";
import axios, { GenericAbortSignal } from "axios";
import { makeAutoObservable } from "mobx";
import {api} from "../../api/endpoints";
import { toast } from "react-toastify";

class BusinessUnitsStore {
    public businessUnits ?: BusinessUnitModel[] = [];
    public businessUnitFilter = {
        search: '',
        show_deleted: false,
        page: 1,
        page_limit: 25,
        pages: 0,
        order_by: 'name',
        order_descending: false,
        return_balance: true
    }
    public loading = false;
    public error = "";
    public message = "";

    public businessUnitsInit = (signal: GenericAbortSignal) => {
        void this.businessUnitsReq(signal);
    }

    constructor() {
        makeAutoObservable(this);
    }

    setBusinessUnits(data: any) {
        const {business_units} = data

        this.businessUnits = business_units;
    }

    public businessUnitsReq = async (signal?: GenericAbortSignal) => {
        this.setLoading(true);
        try {
            const { getToken } = AppStore

            const headers = {
                Authorization: "Bearer " + getToken()!,
            }

            const filterUrl = Object.keys(this.businessUnitFilter).reduce((filters, filter) => {
                const symbol = filters ? '&' : '?';
                const filterKeyName = filter as keyof BusinessUnitFilterModel;
                if (this.businessUnitFilter[filterKeyName] !== 0 &&
                    this.businessUnitFilter[filterKeyName] !== '') {
                    filters += symbol + filter + '=' + this.businessUnitFilter[filterKeyName];
                }

                return filters;
            }, '');

            let response: any; response = await axios.get(api.get_business_units + filterUrl, {headers, signal});
            this.setBusinessUnits(response?.data)
            this.setBusinessUnitFilterPages(response?.data.pages)
        } catch (err) {
        } finally {
            this.setLoading(false);
        }
    };

    public deleteBusinessUnit = async (id: number) => {
        const { getToken } = AppStore

        const headers = {
            Authorization: "Bearer " + getToken()!,
        }

        try {
            const response = await axios.delete(`${api.business_unit}/${id}`, {headers});

            this.message = response?.data.message;
            toast.success(this.message);
        } catch (e: any) {
            this.error = e?.response.data.reason;
            toast.error(this.error);
        } finally {
            await this.businessUnitsReq();
        }
    };

    public setBusinessUnitFilter = ({search, show_deleted, page, page_limit, return_balance}: BusinessUnitFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.businessUnitFilter = {
            ...this.businessUnitFilter,
            search: isUndefined(search) ? this.businessUnitFilter.search! : search!,
            page: isUndefined(page) ? this.businessUnitFilter.page! : page!,
            page_limit: isUndefined(page_limit) ? this.businessUnitFilter.page_limit! : page_limit!,
            show_deleted: isUndefined(show_deleted) ? this.businessUnitFilter.show_deleted! : show_deleted!,
            return_balance: isUndefined(return_balance) ? this.businessUnitFilter.return_balance!: return_balance!
        }

        if (page_limit) {
            this.businessUnitFilter.page = 1;
        }

        void this.businessUnitsReq();
    }

    public setBusinessUnitFilterPages(pages: number) {
        this.businessUnitFilter.pages = pages;
    }
    
    private setLoading(loadingState: boolean) {
        this.loading = loadingState;
    }

    public clearBusinessUnitFilter = () => {
        this.businessUnitFilter = {
            search: '',
            show_deleted: false,
            page: 1,
            page_limit: 25,
            pages: 0,
            order_by: 'name',
            order_descending: false,
            return_balance: true
        }
    }
}

export default new BusinessUnitsStore();