import {observer} from 'mobx-react';
import ModalView from '../../../components/modal/modal.view';
import React, {OptionHTMLAttributes, useEffect, useRef, useState} from 'react';
import CreateOperationStore from './create.operation.store';
import {Layout} from '../../../styles/layout';
import {hasPermission} from '../../../utils/hasPermission';
import {Text} from '../../../styles/text';
import {
    borderRadius,
    inputBackground,
    inputColor,
    modalTextColor,
    primaryColor
} from '../../../utils/variables';
import {SelectView, TextInput} from '../../../styles/form';
import AppStore from '../../../app.store';
import {Collapse, Divider, Input, Select, Space, Switch, Tooltip} from 'antd';
import ServicesModalView from './services.modal.view';
import StocksModalView from './stocks.modal.view';
import {Button} from '../../../styles/button';
import {TransactionsModel} from '../../transactions/transactions.model';
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import StockStore from "../../stock/stock.store";
import SerialNumbersStore from "../../product_serial_numbers/product_serial_numbers.store";
import UsersStore from "../../users/users.store";
import InfoIcon from "../../../assets/icons/information.svg";
import {Image} from "../../../styles/image";
import ConfirmDelete, {DeleteItem} from "../../../components/confirm/confirm.delete";
import {CloseOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

interface CreateOperationViewProps {
    initialValue: TransactionsModel | null;
    setModal: (isOpen: boolean) => void;
}

const CreateOperationView = observer(
    ({initialValue, setModal}: CreateOperationViewProps) => {
        const {permissions, businessSettings} = AppStore;

        const {
            isLoading,
            serviceAccounts,
            serviceAccountsReq,
            selectedProducts,
            stocksReq,
            servicesReq,
            setProductField,
            changeForm,
            createOperationReq,
            form,
            deleteProduct,
            destroy,
            businessUnits,
            paymentMethods,
            clients,
            accounts,
            serviceLoading,
            stockLoading,
            setSearch,
            confirmDuplicateOperationModal,
            setConfirmDuplicateOperationModal
        } = CreateOperationStore;
        const {users} = UsersStore;

        const handleSubmit = async (confirmDuplicateOperation: boolean) => {
            const resp = await createOperationReq(!!initialValue, confirmDuplicateOperation);
            if (resp) {
                destroy();
                setModal(false);
            }
        };

        const [goodsModal, setGoodsModal] = useState(false);

        const handleAddGoodsButtonClick = async () => {
            await stocksReq();
            setGoodsModal(true);
        };

        const [serviceModal, setServiceModal] = useState(false);
        const {expiryDates, getExpiryDatesReq, destroyExpiryDates, expiryLoading} = StockStore;

        const handleAddServiceButtonClick = async () => {
            await servicesReq();
            setServiceModal(true);
        };

        const businessUnitsOptions = businessUnits.businessUnits?.map(
            ({id, name}) => ({label: name, value: id})
        );

        const clientsOptions = clients.clients?.map(({id, full_name}) => ({
            label: full_name,
            value: id,
        }));

        const paymentMethodOptions = paymentMethods.paymentMethods?.map(
            ({id, name}) => ({label: name, value: id})
        );

        const expiryDatesOptions = expiryDates?.map(
            ({id, expiry_date}) => ({label: expiry_date, value: id})
        );

        const handleClientChange = (key: string, value: any) => {
            const controller = new AbortController();
            void accounts.accountsReq(true, 'client', value, controller.signal);
            changeForm(key, value);
        };

        const inputRef = useRef(null);

        const handleServiceOptionChange = (e: any, product: any) => {
            product.account = e;
        };

        const getBuID = () => {
            if (hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE')) {
                return form.bu_id ? form.bu_id : businessUnits.businessUnits![0].id;
            }
            return 0;
        };

        // commission types
        const onlyPercentage = 1
        const onlyFixed = 2
        const fixedAmountAndPercentageWithLimit = 3 // gets fixed amount of commission if amount is less than limit, else gets percentage commission
        const doubleFixedWithLimit = 4 // two given commissions are fixed
        //doublePercentageWithLimit = 4 // two given commissions are percentage


        const handleCommission = (product: any) => {
            switch (product.commission_type_id) {
                case onlyPercentage:
                    product.commission_amount = product.amount * product.commission / 100;
                    break;

                case onlyFixed:
                    product.commission_amount = product.fixed_commission;
                    break;

                case fixedAmountAndPercentageWithLimit:
                    if (product.amount <= product.commission_limit) {
                        product.commission_amount = product.commission_before_limit;
                    } else {
                        product.commission_amount = Number(product.amount * product.commission_after_limit / 100);
                    }
                    break;

                case doubleFixedWithLimit:
                    if (product.amount <= product.commission_limit) {
                        product.commission_amount = product.commission_before_limit;
                    } else {
                        product.commission_amount = product.commission_after_limit;
                    }
                    break;
                default:
                    break;
            }

            if (product.commission_from_amount) {
                product.amount_with_commission = product.amount - product.commission_amount;
            } else {
                product.amount_with_commission = product.amount + product.commission_amount;
            }

            if (product.commission_amount % 1 > 0) {
                product.commission_amount = product.commission_amount.toFixed(2);
            }

            if (product.amount_with_commission % 1 > 0) {
                product.amount_with_commission = product.amount_with_commission.toFixed(2);
            }
        }

        const handleServiceModalClose = () => {
            setServiceModal(false);
            setSearch('', 'service');
        }

        const handleGoodsModalClose = () => {
            setGoodsModal(false);
            setSearch('', 'stock');
        }

        const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

        useEffect(() => {
            destroyModalDictionary();
            const controller = new AbortController();
            void dictionaryListReq('TRANSACTIONS', 'MODAL', controller.signal);
            businessUnits.businessUnitsInit(controller.signal);
            paymentMethods.paymentMethodsInit(controller.signal);
            void clients.clientsPageInit(true, controller.signal);
        }, [
            destroyModalDictionary,
            dictionaryListReq,
            businessUnits.businessUnitsInit,
            paymentMethods.paymentMethodsInit,
            clients.clientsPageInit,
        ]);

        const handleChangeQuantity = (product: any, e: any) => {
            product.quantity = +e.target.value;
            product.total_amount = product.quantity * product.set_price;

            form.total_amount = 0;
            selectedProducts.map((product: any) => {
                form.total_amount += Number(product.total_amount)
            })
        }

        const handleChangeTotalAmount = (product: any, e: any) => {
            let rawValue = e.target.value;

            rawValue = rawValue.replace(",", ".");

            const regex = /^(\d+(\.\d{0,2})?|\.|0\.)$/;
            if (regex.test(rawValue) || rawValue === "") {
                product.total_amount = rawValue;

                if (
                    rawValue !== "" &&
                    businessSettings?.changeable_total_amount &&
                    !isNaN(parseFloat(rawValue)) &&
                    product.set_price
                ) {
                    product.quantity = (parseFloat(rawValue) / product.set_price).toFixed(2);
                }
            }

            form.total_amount = 0;
            selectedProducts.map((product: any) => {
                form.total_amount += Number(product.total_amount)
            })
        };

        const handleBlur = (product: any) => {
            if (!product.total_amount || product.total_amount === ".") {
                product.total_amount = "";
                return;
            }

            if (product.total_amount.includes(".")) {
                const formattedValue = parseFloat(product.total_amount).toFixed(2);
                product.total_amount = formattedValue;
            }
        };

        const handleChangePrice = (product: any, e: any) => {
            product.set_price = +e.target.value;
            product.total_amount = product.quantity * product.set_price;
        }

        const handlePanelHeader = (product: any) => {
            switch (product.type) {
                case 'goods':
                    if (product.quantity !== 0 && product.total_amount !== 0) {
                        return `${product.name} x ${product.quantity | 0} = ${product.total_amount | 0}`
                    } else {
                        return `${product.name}`
                    }
                case 'service':
                    return product.name;
                default:
                    return '';
            }
        }

        const handleChangeExpiryDate = (product: any, expiryID: number) => {
            product.expiry_id = expiryID;
        }

        const handleFocusExpiryDates = (productID: number, businessUnitID: number) => {
            destroyExpiryDates()
            void getExpiryDatesReq(productID, businessUnitID)
        }

        const handleSerialNumbersOptionsChange = (value: string, product: any) => {
            product.serial_id = value;
        }

        const handleSerialNumbersOptionsSearch = (value: string, product: any) => {
            product.serial_id = value;
        }


        // барои ролики мышкара неактивный кардан вакте ки инпут типш number-ай (наверное хубтарай вес проектда як чода монемша)
        // чтобы случайно ракам иваз нашава вакте ролики мышкара истифода мебара барои болову поён кардан
        document.body.addEventListener("wheel", (ev) => {
            const target = ev.composedPath()[0] as HTMLElement;
            if (target.matches('input[type="number"]')) {
                target.blur();
            }
        });

        // Function to generate regex for the range between min and max
        const generateRangeRegex = (min: number, max: number): string => {
            /*
            if (min < 0 || max < 0 || min > max) {
                throw new Error('Invalid range. Ensure min is >= 0 and min <= max');
            }
            */

            // Function to escape numbers for use in regex
            const escapeNumber = (num: number): string => {
                return num.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            };

            // Handle specific cases for regex
            if (min === 0 && max === 0) {
                return `^0$`; // only match zero
            }

            if (min === max) {
                return `^${escapeNumber(min)}$`; // only match the exact number
            }

            const minLength = min.toString().length;
            const maxLength = max.toString().length;

            let regexParts: string[] = [];

            for (let i = minLength; i <= maxLength; i++) {
                let pattern = '';
                if (i === minLength) {
                    pattern = `(${escapeNumber(min)}|\\d{${i}})`;
                } else if (i === maxLength) {
                    pattern = `(\\d{${i - 1}}[0-${max.toString().charAt(0)}])`;
                } else {
                    pattern = `\\d{${i}}`;
                }

                regexParts.push(pattern);
            }

            return `^(${regexParts.join('|')})$`;
        };

        return (
            <>
                <Layout vertical between={10}>
                    {/* Paying client's info */}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_phone && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Номер телефона клиента
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_phone"
                                    value={form.paying_client_phone}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}

                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_full_name && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    ФИО клиента
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_full_name"
                                    value={form.paying_client_full_name}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_date_of_birth && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Дата рождения
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_date_of_birth"
                                    value={form.paying_client_date_of_birth}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_number && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Номер паспорта
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_passport_number"
                                    value={form.paying_client_passport_number}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_issuing_authority && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Кем выдан паспорт
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_passport_issuing_authority"
                                    value={form.paying_client_passport_issuing_authority}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_date_of_issue && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Дата выдачи паспорта
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_passport_date_of_issue"
                                    value={form.paying_client_passport_date_of_issue}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {businessSettings?.ask_for_paying_client_info && businessSettings?.paying_client_passport_date_of_expiry && (
                        <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    Дата окончания пасспорта
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <TextInput
                                    type="text"
                                    name="paying_client_passport_date_of_expiry"
                                    value={form.paying_client_passport_date_of_expiry}
                                    onChange={(event) =>
                                        changeForm(event.target.name, event.target.value)
                                    }
                                />
                            </Layout>
                        </Layout>
                    )}
                    {hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE') && (
                        <Layout
                            hAlign={'space-between'}
                            vAlign={'center'}
                        >
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    {getModalPlaceholders('bu_id')}
                                </Text>
                            </Layout>
                            <Layout widthSize={'50%'} style={{maxWidth: '100%'}}>
                                <SelectView
                                    extent={'S'}
                                    name="business-unit-options"
                                    value={form.bu_id}
                                    onChange={(event) =>
                                        changeForm('bu_id', event.target.value)
                                    }
                                >
                                    {businessUnitsOptions?.map((option: any, index: any) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </SelectView>
                            </Layout>
                        </Layout>
                    )}
                    <Layout vAlign="center" hAlign={'space-between'} widthSize={'100%'}>
                        <Layout widthSize={'50%'}>
                            <Text extent="S" color={modalTextColor}>
                                {getModalPlaceholders('payment_method_id')}
                            </Text>
                        </Layout>
                        <Layout widthSize={'50%'} style={{maxWidth: '100%'}}>
                            <SelectView
                                extent={'S'}
                                name="company"
                                value={form.payment_method_id}
                                onChange={(event) =>
                                    changeForm('payment_method_id', +event.target.value)
                                }
                            >
                                {paymentMethodOptions?.map((option: any, index: any) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </SelectView>
                        </Layout>
                    </Layout>

                    <Layout vAlign="center" hAlign={'space-between'}>
                        <Layout widthSize={'50%'}>
                            <Text extent="S" color={modalTextColor}>
                                {getModalPlaceholders('client_id')}
                            </Text>
                        </Layout>
                        <Layout widthSize="50%">
                            <Select
                                showSearch
                                placeholder="Выберите клиента"
                                value={form.client_id}
                                filterOption={(input, option: any) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(value) => {
                                    handleClientChange('client_id', value);
                                }}
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    borderRadius: borderRadius,
                                    backgroundColor: inputBackground,
                                    color: inputColor
                                }}
                                options={clientsOptions}
                            >
                            </Select>
                        </Layout>
                    </Layout>

                    {(form.client_id !== 1 && form.payment_method_id == 3) && (
                        <Layout vAlign="center" hAlign={'space-between'}>
                            <Layout widthSize={'50%'}>
                                <Text extent="S" color={modalTextColor}>
                                    {getModalPlaceholders('account_id')}
                                </Text>
                            </Layout>
                            <Layout widthSize="50%">
                                <SelectView
                                    name="account"
                                    value={form.account_id}
                                    onChange={(event) =>
                                        changeForm('account_id', event.target.value)
                                    }
                                >
                                    {accounts.accounts?.map((option: any, index: any) => (
                                        <option key={index} value={option.id}>
                                            {option.number}
                                        </option>
                                    ))}
                                </SelectView>
                            </Layout>
                        </Layout>
                    )}

                    <Layout vAlign="center" hAlign={'space-between'}>
                        <Layout widthSize="50%">
                            <Text extent="S" color={modalTextColor}>
                                {getModalPlaceholders('details')}
                            </Text>
                        </Layout>
                        <Layout widthSize="50%">
                            <TextInput
                                type="text"
                                name="details"
                                value={form.details}
                                onChange={(event) =>
                                    changeForm(event.target.name, event.target.value)
                                }
                            />
                        </Layout>
                    </Layout>
                    {businessSettings?.select_employee && hasPermission(permissions!, 'ALL_BU_TRANSACTIONS', 'CREATE') && (
                        <Layout>
                            <Layout widthSize='50%'>
                                <Text extent='S' color={modalTextColor}>
                                    {getModalPlaceholders('employee_id')}
                                </Text>
                            </Layout>
                            <Layout widthSize='50%'>
                                <SelectView
                                    name="employee"
                                    value={form.employee_id}
                                    onChange={(event) =>
                                        changeForm('employee_id', +event.target.value)
                                    }
                                >
                                    {users?.map((user, index: number) => (
                                        <option key={index} value={user.id}>
                                            {`${user.name} ${user.surname}`}
                                        </option>
                                    ))}
                                </SelectView>
                            </Layout>
                        </Layout>
                    )}
                    <Layout
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: primaryColor,
                            fontSize: '20px',
                            marginTop: '10px',
                        }}
                    >
                        {getModalPlaceholders('products_title')}
                    </Layout>

                    <Layout
                        style={{
                            maxHeight: 300,
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                        }}
                        widthSize={'100%'}
                        vertical
                        between={10}
                    >
                        {selectedProducts.map((product: any, index: any) => (
                            <Collapse
                                defaultActiveKey={['0']}
                                style={{width: '100%'}}
                                accordion
                            >
                                <Panel
                                    header={
                                        <Layout vAlign="center" hAlign="space-between">
                                            <Layout>
                                                <span>{handlePanelHeader(product)}</span>
                                            </Layout>

                                            <Button
                                                onClick={() => deleteProduct(product.id)}
                                            >
                                                <CloseOutlined/>
                                            </Button>
                                        </Layout>
                                    }

                                    key={index}
                                >
                                    <Layout vertical between={10}>
                                        {product.type === 'goods' && (
                                            <Layout vertical between={10}>
                                                <Layout vAlign="center" hAlign={'space-between'}>
                                                    <Layout widthSize={'50%'}>
                                                        <Text extent="S" color={modalTextColor}>
                                                            {getModalPlaceholders('quantity')}
                                                        </Text>
                                                    </Layout>
                                                    <Layout widthSize="50%">
                                                        <TextInput
                                                            type="text"
                                                            onChange={(e) =>
                                                                handleChangeQuantity(product, e)
                                                            }
                                                            name="quantity"
                                                            value={product.quantity}
                                                        />
                                                    </Layout>
                                                </Layout>
                                            </Layout>
                                        )}

                                        {product.type === 'goods' && (
                                            <Layout vertical between={10}>
                                                <Layout vAlign="center" hAlign="space-between">
                                                    <Text extent="S" color={modalTextColor}>
                                                        {getModalPlaceholders('set_price')}
                                                    </Text>
                                                    <Layout widthSize="50%">
                                                        <TextInput
                                                            type="text"
                                                            disabled
                                                            onChange={(e) =>
                                                                handleChangePrice(product, e)
                                                            }
                                                            value={product.set_price}
                                                        />
                                                    </Layout>
                                                </Layout>
                                            </Layout>
                                        )}

                                        {product.type === 'service' &&
                                            (form.client_id !== 1 ? (
                                                <Layout vertical between={10}>
                                                    <Layout vAlign="center" hAlign="space-between">
                                                        <Text extent="S" color={modalTextColor}>
                                                            {getModalPlaceholders('account')}
                                                        </Text>
                                                        <Layout widthSize="50%">
                                                            <Select
                                                                value={product.account}
                                                                onChange={(e) => {
                                                                    handleServiceOptionChange(e, product);
                                                                }}
                                                                onFocus={() => {
                                                                    void serviceAccountsReq(
                                                                        form.client_id,
                                                                        product.id
                                                                    );
                                                                }}
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                placeholder=""
                                                                dropdownRender={(menu) => (
                                                                    <>
                                                                        <Space
                                                                            style={{
                                                                                padding: '0 8px 4px',
                                                                            }}
                                                                        >
                                                                            <Input
                                                                                maxLength={16}
                                                                                placeholder="Введите счет"
                                                                                ref={inputRef}
                                                                                value={product.account}
                                                                                onChange={(e) => {
                                                                                    product.account = e.target.value;
                                                                                }}
                                                                                onKeyDown={(e) => e.stopPropagation()}
                                                                            />
                                                                        </Space>
                                                                        <Divider
                                                                            style={{
                                                                                margin: '8px 0',
                                                                            }}
                                                                        />
                                                                        {menu}
                                                                    </>
                                                                )}
                                                                options={serviceAccounts.map((item) => ({
                                                                    label: item,
                                                                    value: item,
                                                                }))}
                                                            />
                                                        </Layout>
                                                    </Layout>
                                                </Layout>
                                            ) : (
                                                <Layout
                                                    between={2}
                                                    vAlign="center"
                                                    hAlign="space-between"
                                                >
                                                    <Layout>
                                                        <Text extent="S" color={modalTextColor}>
                                                            {getModalPlaceholders('account')}
                                                        </Text>
                                                    </Layout>
                                                    <Layout widthSize="50%">
                                                        <Input
                                                            maxLength={16}
                                                            placeholder="Введите счет"
                                                            ref={inputRef}
                                                            value={product.account}
                                                            onChange={(e) => {
                                                                product.account = e.target.value;
                                                            }}
                                                            onKeyDown={(e) => e.stopPropagation()}
                                                        />
                                                    </Layout>
                                                </Layout>
                                            ))}
                                        {product.type === 'service' && (
                                            <Layout vAlign="center" hAlign="space-between">

                                                <Layout between={5} vAlign="center">
                                                    <Text extent="S" color={modalTextColor}>
                                                        {getModalPlaceholders('amount')}
                                                    </Text>
                                                    {businessSettings?.product_min_max &&
                                                        <Tooltip
                                                            title={`Сумма операции от ${product.min} до ${product.max}`}
                                                            color={primaryColor}
                                                        >
                                                            <Image
                                                                src={InfoIcon}
                                                                height={'18px'}
                                                                width={'18px'}
                                                                style={{
                                                                    filter:
                                                                        'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }

                                                </Layout>
                                                <Layout widthSize="50%">
                                                    <TextInput
                                                        type="text"
                                                        pattern={generateRangeRegex(product.min, product.max)}
                                                        onChange={(e) => {
                                                            setProductField(product, +e.target.value, 'amount');
                                                            setProductField(product, +e.target.value, 'total_amount');
                                                            if (businessSettings?.product_commissions) {
                                                                setTimeout(() => handleCommission(product), 500);
                                                            }

                                                            form.total_amount = 0;
                                                            selectedProducts.map((product: any) => {
                                                                form.total_amount += Number(product.total_amount)
                                                            })

                                                        }
                                                        }
                                                        name="amount"
                                                        value={product.amount}
                                                        minMaxValueCheck={businessSettings?.product_min_max}
                                                    />
                                                </Layout>
                                            </Layout>
                                        )}
                                        {product.type === 'service' && businessSettings?.product_commissions && businessSettings?.product_reverse_commission && (
                                            <Layout vAlign="center" hAlign="space-between">
                                                <Layout hAlign={"space-between"} vAlign={"center"}>
                                                    <Layout>
                                                        <Text extent="S" color={modalTextColor}>
                                                            {'Вычесть комиссию из суммы'}
                                                        </Text>
                                                    </Layout>
                                                </Layout>
                                                <Layout>
                                                    <Switch
                                                        checked={product.commission_from_amount}
                                                        onChange={(e) => {
                                                            product.commission_from_amount = e
                                                            handleCommission(product);
                                                        }
                                                        }
                                                    />
                                                </Layout>

                                            </Layout>
                                        )}
                                        {product.type === 'service' && businessSettings?.product_commissions && (
                                            <Layout vAlign="center" hAlign="space-between">
                                                <Layout>
                                                    <Text extent={'S'} color={modalTextColor}>
                                                        {getModalPlaceholders('commission_amount')}
                                                    </Text>
                                                </Layout>
                                                <Layout widthSize="50%">
                                                    <Text>{product.commission_amount}</Text>
                                                </Layout>
                                            </Layout>
                                        )}
                                        {product.type === 'service' && businessSettings?.product_commissions && (
                                            <Layout vAlign="center" hAlign="space-between">
                                                <Layout>
                                                    <Text extent={'S'} color={modalTextColor}>
                                                        {getModalPlaceholders('total_amount_com')}
                                                    </Text>
                                                </Layout>
                                                <Layout widthSize="50%">
                                                    <Text>{product.amount_with_commission}</Text>
                                                </Layout>
                                            </Layout>
                                        )}
                                        {product.type === 'goods' && (
                                            <Layout vAlign="center" hAlign="space-between">
                                                <Layout>
                                                    <Text extent="S" color={modalTextColor}>
                                                        {product.type === 'service' && businessSettings?.product_commissions ? getModalPlaceholders('total_amount_com') : getModalPlaceholders('total_amount')}
                                                    </Text>
                                                </Layout>
                                                <Layout widthSize="50%">
                                                    <TextInput
                                                        type="text"
                                                        disabled={product.fixed_price}
                                                        onChange={(e) => {
                                                            handleChangeTotalAmount(product, e);
                                                        }}
                                                        name="total_amount"
                                                        value={product.total_amount ? product.total_amount.toString() : ""}
                                                        onBlur={() => {
                                                            handleBlur(product)
                                                        }}
                                                    />
                                                </Layout>
                                            </Layout>
                                        )}
                                        {(product.type === 'goods' && product.has_expiry) && (
                                            <Layout vAlign="center" hAlign={"space-between"}>
                                                <Layout>
                                                    <Text extent='S' color={modalTextColor}>
                                                        Срок годности
                                                    </Text>
                                                </Layout>
                                                <Layout widthSize={'50%'}>
                                                    <Select
                                                        loading={expiryLoading}
                                                        onFocus={() => handleFocusExpiryDates(product.id, getBuID())}
                                                        onChange={(event) =>
                                                            handleChangeExpiryDate(product, +event.target.value)
                                                        }
                                                        placeholder='Выберите срок годности'
                                                        style={{
                                                            width: '100%',
                                                            border: 'unset',
                                                            //border: '2px solid #ddd',
                                                            transition: '300ms',
                                                            borderRadius: '12px',
                                                            backgroundColor: inputBackground,
                                                            color: inputColor,
                                                        }}
                                                    >
                                                        {expiryDatesOptions?.map((option: any, index: number) => (
                                                            <option key={index} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </Layout>
                                            </Layout>
                                        )}
                                        {product.type === 'service' && (
                                            <Layout vAlign="center" hAlign={"space-between"}>
                                                <Layout>
                                                    <Text extent='S' color={modalTextColor}>
                                                        {getModalPlaceholders('outflow')}
                                                    </Text>
                                                </Layout>
                                                <Layout widthSize={'50%'}>
                                                    <Text extent='S' color={product.outflow ? 'red' : 'green'}>
                                                        {product.outflow ? 'Расход' : 'Приход'}
                                                    </Text>
                                                </Layout>
                                            </Layout>
                                        )}

                                        {product.type === 'goods' && product.has_serial === true && (
                                            <Layout vAlign="center" hAlign={"space-between"}>
                                                <Layout>
                                                    <Text extent='S' color={modalTextColor}>
                                                        {'Серийный номер'}
                                                    </Text>
                                                </Layout>
                                                {/*<Select
                                                    mode="multiple"
                                                    allowClear
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    loading={loading}
                                                    placeholder="Веберите серийный номер"
                                                    onChange={(value) => {handleSerialNumbersOptionsChange(value, product)}}
                                                    options={serialNumberOptions}
                                                />*/}

                                                <Select
                                                    showSearch
                                                    placeholder="Выберите серийный номер"
                                                    optionFilterProp="label"
                                                    onChange={(value) => {
                                                        handleSerialNumbersOptionsChange(value, product)
                                                    }}
                                                    options={product.serial_options}
                                                    style={{width: '210px'}}
                                                    dropdownStyle={{maxHeight: 200, minWidth: 350, overflowY: 'auto'}}
                                                />
                                            </Layout>
                                        )}
                                    </Layout>
                                </Panel>
                            </Collapse>
                        ))}
                    </Layout>

                    <Button
                        onClick={handleAddGoodsButtonClick}
                        style={{
                            width: '80%',
                            margin: '25px 45px',
                            marginBottom: 5,
                            boxSizing: 'border-box',
                            background: 'rgb(69,97,134)',
                            height: '35px',
                        }}
                        isLoading={stockLoading}
                        disabled={stockLoading}
                    >
                        {stockLoading ? '' : getModalPlaceholders('add_goods_button')}
                    </Button>

                    <Button
                        onClick={handleAddServiceButtonClick}
                        style={{
                            width: '80%',
                            margin: '0px 25px 45px 45px',
                            marginBottom: 5,
                            boxSizing: 'border-box',
                            background: 'rgb(69,97,134)',
                            height: '35px',
                        }}
                        isLoading={serviceLoading}
                        disabled={serviceLoading}
                    >
                        {serviceLoading ? '' : getModalPlaceholders('add_service_button')}
                    </Button>

                    <Layout vAlign="center" hAlign="space-between">
                        <Layout>
                            <Text extent="S" color={modalTextColor}>
                                {getModalPlaceholders('grand_total')}
                            </Text>
                        </Layout>
                        <Layout widthSize="50%">
                            <Text>
                                {/*{selectedProducts.reduce((_: any, item: any) => {
                                    let totalAmount: number = 0;


                                    //totalAmount += item.outflow ? Number(item.total_amount) + acc : Number(item.total_amount - item.commission_amount) + acc;

                                    totalAmount += item.commission_from_amount ? Number(item.total_amount) : Number(item.total_amount) + Number(item.commission_amount);

                                    if (totalAmount % 1 > 0) {
                                        return totalAmount.toFixed(2);
                                    }

                                    return totalAmount;
                                }, 0)}*/}
                                {form.total_amount}
                            </Text>
                        </Layout>
                    </Layout>

                    <Button
                        onClick={() => {
                            handleSubmit(false)
                        }}
                        style={{
                            alignSelf: 'center',
                        }}
                        isLoading={isLoading}
                        //  disabled={isLoading}
                        disabled={selectedProducts.length < 1 || isLoading}
                    >
                        {isLoading ? '' : 'Потвердить'}
                    </Button>
                </Layout>
                {serviceModal && (
                    <ModalView
                        title={'Выберите сервис'}
                        onClose={handleServiceModalClose}
                    >
                        <ServicesModalView
                            businessUnitID={getBuID()}
                            setModal={setServiceModal}
                        />
                    </ModalView>
                )}

                {confirmDuplicateOperationModal.isModalOpen && (
                    <ModalView
                        title="Недавно была создана аналогичная операция"
                        onClose={() => {
                            setConfirmDuplicateOperationModal(false);
                        }}
                    >
                        <ConfirmDelete
                            warningText={`Подтвердите что данная операция не является ошибочной или случайной`}
                            modalObj={confirmDuplicateOperationModal}
                            callback={() => {
                                setConfirmDuplicateOperationModal(false);
                                handleSubmit(true)
                            }}
                            buttonColor={"#1677FF"}
                            buttonText={'Подтвердить'}
                        />
                    </ModalView>
                )}

                {goodsModal && (
                    <ModalView
                        title={'Выберите товар'}
                        onClose={handleGoodsModalClose}
                    >
                        <StocksModalView
                            businessUnitID={getBuID()}
                            setModal={setGoodsModal}
                        />
                    </ModalView>
                )}
            </>
        );
    });

export default CreateOperationView;
