import {PurchaseBillsPaymentInputsModel} from "./purchase.bills.payment.model";
import {makeAutoObservable} from "mobx";
import {toast} from "react-toastify";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";

const loadingInitialState: boolean = false;
const formInitialValue: PurchaseBillsPaymentInputsModel = {
    id: 0,
    amount: 0,
    details: '',
}

class PurchaseBillsPaymentStore {
    public isLoading = loadingInitialState;
    public form = formInitialValue;
    message: string = '';
    reason: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.isLoading = loadingInitialState;
        this.form = formInitialValue;
        this.message = '';
        this.reason = '';
    }

    public setForm = (form: PurchaseBillsPaymentInputsModel) => {
        this.form = form
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public makeBillPaymentReq = async (billID: number) => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const payload = {
            id: billID,
            amount: Number(this.form.amount),
            details: this.form.details
        }

        try {
            const response = await axios.post(api.purchase_bills + '/pay', payload, {headers});
            this.message = response?.data.message
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.reason = e?.response?.data.reason;
            toast.error(this.reason)
        } finally {
            this.setLoading(false);
        }
    }
}

export default new PurchaseBillsPaymentStore();