import {observer} from "mobx-react";
import {ExpensesModel} from "../expenses.model";
import {Layout} from "../../../../styles/layout";
import ExpensesCreateEditStore from "./expenses.create.edit.store";
import React, {useEffect, useState} from "react";
import {renderInputs} from "../../../../components/form/render.inputs";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import ExpensesStore from "../expenses.store";
import ExpenseCategoriesStore from "../../expense.categories/expense.categories.store";
import BusinessUnitsStore from "../../../business_units/business_units.store";
import {ExpenseCategoriesFiltersModel} from "../../expense.categories/expense.categories.model";
import {Text} from "../../../../styles/text";
import {modalTextColor, primaryColor} from "../../../../utils/variables";
import {Col, InputNumber, Row, Slider} from "antd";
import ExpenseOperationsStore from "../../expense.operations/expense.operations.store";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";

interface ExpensesCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: ExpensesModel | null;
    createOperation: boolean;
}

const ExpensesCreateEditView = observer(({setModal, initialValue, createOperation}: ExpensesCreateEditViewProps) => {

    const {
        form,
        loading,
        setField,
        destroy,
        createOrEditExpense,
        init
    } = ExpensesCreateEditStore;

    const {categories, setCategoriesFilter} = ExpenseCategoriesStore;
    const {businessUnits, clearBusinessUnitFilter, setBusinessUnitFilter} = BusinessUnitsStore;
    const [inputValue, setInputValue] = useState(1);
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        dictionaryListReq('EXPENSES', 'MODAL', controller.signal);
        void init(initialValue, createOperation);
        setCategoriesFilter({page_limit: 999999} as ExpenseCategoriesFiltersModel)
        setBusinessUnitFilter({page_limit: 999999, return_balance: false})
        clearBusinessUnitFilter();

        return () => {
            destroy();
            controller.abort();
        }
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const handleSliderNumberChange = (value: number) => {
        setField({...form, ['period_count']: value})
        setInputValue(value);
    }

    const categoriesOptions = () => {
        if (!categories) return;
        return categories.map((category) => ({
            label: category.name,
            value: category.id
        }));
    }

    const businessUnitsOptions = () => {
        if (!businessUnits) return;
        return businessUnits.map((businessUnit) => ({
            label: businessUnit.name,
            value: businessUnit.id
        }));
    }

    const {
        category_id,
        business_unit_id,
        name,
        description,
        amount,
        post_check,
        type,
        period,
        start_date,
        end_date,
        operation_date
    } = form;

    const {expensesReq} = ExpensesStore;
    const {expenseOperationsReq} = ExpenseOperationsStore;

    const periodOptions = [
        {
            label: 'День',
            value: 'DAY',
        },
        {
            label: 'Неделя',
            value: 'WEEK',
        },
        {
            label: 'Месяц',
            value: 'MONTH',
        },
        {
            label: 'Год',
            value: 'YEAR',
        }
    ]
    const typeOptions = [
        {
            label: 'Единоразовый',
            value: 'ONCE',
        },
        {
            label: 'Периодический',
            value: 'PERIODIC',
        }
    ];

    const inputs = [
        {
            field: category_id,
            fieldName: 'category_id',
            placeholder: getModalPlaceholders('category_id'),
            type: 'select',
            required: true,
            options: categoriesOptions(),
        },
        {
            field: business_unit_id,
            fieldName: 'business_unit_id',
            placeholder: getModalPlaceholders('business_unit_id'),
            type: 'select',
            required: false,
            options: businessUnitsOptions()
        },
        {field: name, fieldName: 'name', placeholder: getModalPlaceholders('name'), type: 'text', required: true},
        {field: description, fieldName: 'description', placeholder: getModalPlaceholders('description'), type: 'text', required: false},
        {field: amount, fieldName: 'amount', placeholder: getModalPlaceholders('amount'), type: 'number', required: true},
        {field: post_check, fieldName: 'post_check', placeholder: getModalPlaceholders('post_check'), type: 'switch', required: false},
        createOperation && {
            field: type,
            fieldName: 'type',
            placeholder: getModalPlaceholders('type'),
            type: 'select', required: true,
            options: typeOptions,
            selectValueType: 'string'
        },
    ];


    const periodic = [
        {
            field: period,
            fieldName: 'period',
            placeholder: getModalPlaceholders('period'),
            type: 'select',
            required: true,
            options: periodOptions,
            selectValueType: 'string'
        },
        {
            field: start_date,
            fieldName: 'start_date',
            placeholder: getModalPlaceholders('start_date'),
            type: 'date',
            required: true,
        },
        {
            field: end_date,
            fieldName: 'end_date',
            placeholder: getModalPlaceholders('end_date'),
            type: 'date',
            required: false,
        },
    ];

    const once = {
        field: operation_date,
        fieldName: 'operation_date',
        placeholder: getModalPlaceholders('operation_date'),
        type: 'date',
        required: false,
    }

    const renderInputValues = () => {
        switch (form.type) {
            case 'PERIODIC':
                inputs.push(...periodic)
                return inputs;
            case 'ONCE':
                inputs.push(once)
                return inputs;
            default:
                return inputs;
        }
    }

    const handleCreateEditExpense = async () => {
        const resp = await createOrEditExpense(!!initialValue, createOperation);

        if (resp) {
            setModal(false);
            destroy();
            if (createOperation) {
                await expenseOperationsReq();
            } else {
                await expensesReq();
            }
        }
    }

    const sliderRailStyle: React.CSSProperties = {
        maxWidth: '100%',
        minWidth: '100%',
        width: '100%',
    }

    const sliderTrackStyle: React.CSSProperties = {
        margin: 0,
        display: 'inline'
    }

    const sliderHandleStyle: React.CSSProperties = {
        color: primaryColor
    }

    const renderSubmitText = !initialValue ? 'Создать' : 'Изменить';

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, renderInputValues(), setField)}
                {form.type === 'PERIODIC' && (
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout widthSize='35%'>
                            <Text extent='S' color={modalTextColor}> {getModalPlaceholders('period_count')}</Text>
                        </Layout>
                        <Layout widthSize='65%'>
                            <Row style={{
                                width: '100%'
                            }}>
                                <Col span={18}>
                                    <Slider
                                        min={1}
                                        max={30}
                                        step={1}
                                        value={inputValue}
                                        onChange={(e) => handleSliderNumberChange(e)}
                                        defaultValue={inputValue}
                                        railStyle={sliderRailStyle}
                                        trackStyle={sliderTrackStyle}
                                        handleStyle={sliderHandleStyle}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={1}
                                        max={30}
                                        onChange={(e) => handleSliderNumberChange(e!)}
                                        autoFocus
                                        step={1}
                                        value={inputValue}
                                        defaultValue={inputValue}
                                        style={{
                                            marginLeft: '16px',
                                            marginRight: 0,
                                            border: '2px solid #ddd',
                                            borderRadius: '12px',
                                            maxWidth: '120%'
                                    }}
                                    />
                                </Col>
                            </Row>
                        </Layout>
                    </Layout>
                )}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleCreateEditExpense}
                    disabled={isValid(renderInputValues())}
                >
                    {loading ? '' : renderSubmitText}
                </Button>
            </Layout>
        </Layout>
    );
});

export default ExpensesCreateEditView;