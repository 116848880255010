import {PurchaseBillOperationsFilterModel, PurchaseBillOperationsModel} from "./purchase.bills.operations.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";

const operationsInitialState: PurchaseBillOperationsModel[] = [];
const loadingInitialState: boolean = false;
const operationsFilterInitialState: PurchaseBillOperationsFilterModel = {
    supplier_id: 0,
    business_unit_id: 0,
    product_id: 0,
    user_id: 0,

    order_by: '',
    order_descending: false,
    page: 1,
    pages: 0,
    page_limit: 25
}

class PurchaseBillsOperationsStore {
    public operations = operationsInitialState;
    public loading = loadingInitialState;
    public operationsFilter = operationsFilterInitialState;

    constructor() {
        makeAutoObservable(this);
    }

    public initBillsOperations = async (signal: AbortSignal) => {
        await this.getBillsOperationsReq(signal)
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setOperations(data: any) {
        const {purchase_bill_operations} = data;
        this.operations = purchase_bill_operations;
    }

    setOperationsFilterPages(pages: number) {
        this.operationsFilter.pages = pages;
    }

    public getBillsOperationsReq = async (signal?: AbortSignal) => {
        this.setLoading(true)

        const {getToken} = AppStore;
        const headers = {
            Authorization: "Bearer " + getToken()!,
        }

        const filterUrl = Object.keys(this.operationsFilter).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof PurchaseBillOperationsFilterModel;
            if (this.operationsFilter[filterKeyName] !== 0 &&
                this.operationsFilter[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.operationsFilter[filterKeyName];
            }

            return filters;
        }, '');


        try {
                const response = await axios.get(api.purchase_bills + '/operations' + filterUrl, {headers, signal});
                this.setOperations(response?.data);
                this.setOperationsFilterPages(response?.data.pages);
        } catch (e: any) {

        } finally {
            this.setLoading(false)
        }
    }

    public setOperationsFilters = ({supplier_id, business_unit_id, product_id, user_id, page, page_limit}: PurchaseBillOperationsFilterModel) => {
            const isUndefined = (value: any) => typeof value === 'undefined';
            this.operationsFilter = {
                ...this.operationsFilter,
                supplier_id: isUndefined(supplier_id) ? this.operationsFilter.supplier_id : supplier_id!,
                business_unit_id: isUndefined(business_unit_id) ? this.operationsFilter.business_unit_id : business_unit_id!,
                product_id: isUndefined(product_id) ? this.operationsFilter.product_id : product_id!,
                user_id: isUndefined(user_id) ? this.operationsFilter.user_id : user_id!,
                page: isUndefined(page) ? this.operationsFilter.pages : page!,
                page_limit: isUndefined(page_limit) ? this.operationsFilter.page_limit : page_limit!,
            }

            if (page_limit) {
                this.operationsFilter.page = 1;
            }

            void this.getBillsOperationsReq();
    }
}

export default new PurchaseBillsOperationsStore();