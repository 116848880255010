import styled from 'styled-components';
import {borderRadius} from "../utils/variables";

export const TablePagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

export const TablePages = styled.div<{
    pages?: number;
}>`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: ${({pages}) => pages! > 5 ? 'column' : 'row'}
`;

export const PageButton = styled.button`
  min-width: 30px;
  height: 30px;
  border-radius: ${borderRadius};
  border: 1px #E5E5E5;
  font-size: 14px;
  color: #374151;
  background-color: #FFFFFF;
  margin-left: 5px;
  padding: 0 8px;
  cursor: pointer;
  :hover {
    filter: brightness(0.9);
  }
  :active {
    filter: brightness(0.75);
  }
`;

export const PagesButtonStyle = {
    active: {
        backgroundColor: '#DDD',
        opacity: 0.9,
    },

    not_active: {
        backgroundColor: '#fff',
    }
};

export const PageLimitDiv = styled.div`
  font-size: 14px;
  color: #374151;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PageLimit = styled.select`
  min-width: 50px;
  height: 30px;
  border-radius: ${borderRadius};
  border: 1px #E5E5E5;
  background-color: #FFFFFF;
  font-size: 16px;
  color: #374151;

  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding: 0 20px 0 10px;
  text-align-last: center;

  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 3px;
  margin-left: 5px;
  cursor: pointer;
  :hover {
    filter: brightness(0.9);
  }
`;