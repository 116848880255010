import { BusinessControl } from '../settings.model';
import axios from 'axios';
import { api } from '../../../api/endpoints';
import { makeAutoObservable } from 'mobx';
import AppStore from '../../../app.store';
import { toast } from 'react-toastify';

const initialValues: BusinessControl = {
  active: false,
  created_at: '',
  employee_bonus: false,
  id: 0,
  name: '',
  phone: '',
  reference: 0,
  transaction_approval: false,
  updated_at: '',
  cheque_double_record: false,
  product_commissions: false,
  changeable_total_amount: false,
  working_hours: false,
  select_employee: false,
  product_reverse_commission: false,
  ask_for_paying_client_info: false,
  paying_client_phone: false,
  paying_client_full_name: false,
  paying_client_date_of_birth: false,
  paying_client_passport_number: false,
  paying_client_passport_issuing_authority: false,
  paying_client_passport_date_of_issue: false,
  paying_client_passport_date_of_expiry: false,
  duplicate_trn_timeout_seconds: 0,
  refund_period_timeout_days: 0,
  product_min_max: false,
  product_dimensions: false,
};

class SettingsCreateEditStore {
  public form = initialValues;

  changeForm = (object: any) => {
    this.form = object;
  };

  public error = '';
  public message = '';
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public businessReq = async (signal?: any) => {
    try {
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.get(api.owner, { headers, signal });

      this.form = response.data.owner;
    } catch (err) {
      console.error(err);
    }
  };

  public updateBusinessReq = async () => {
    try {
      this.isLoading = true;
      const { getToken } = AppStore;

      const headers = {
        Authorization: 'Bearer ' + getToken()!,
      };

      const response = await axios.put(api.owner, this.form, {
        headers,
      });

      this.message = response?.data.message;
      toast.success(this.message);

      const { getBusinessSettings } = AppStore;

      await getBusinessSettings();

      this.isLoading = false;
    } catch (err: any) {
      this.error = err?.response.data.reason;
      toast.error(this.error);
    }
  };
}

export default new SettingsCreateEditStore();
