import {observer} from "mobx-react";
import {Page} from "../../styles/page";
import AppStore from "../../app.store";
import {Headline} from "../../styles/headline";
import {primaryColor} from "../../utils/variables";
import {Layout} from "../../styles/layout";
import {AddButton} from "../../styles/table-header";
import {useEffect, useState} from "react";
import {StatusModel} from "./statuses.model";
import StatusesStore from "./statuses.store";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import {TableContainer, TableContainerMobile} from "../../styles/table";
import StatusesTableDesktopView from "./statuses.table.desktop.view";
import StatusesTableMobileView from "./statuses.table.mobile.view";
import ModalView from "../../components/modal/modal.view";
import StatusesCreateEditView from "./statuses.modal/statuses.create.edit.view";
import AccountModalView from "../../components/modal/account.modal.view";
import StatusesReferencesView from "./statuses.references.view";
import Modal from "../../components/modal/modal.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import RolesModalView from "./roles.modal.view";

const StatusesView = observer(() => {

    const {
        pageLoading,
        statuses,
        statusesPageInit,
        deleteStatusReq
    } = StatusesStore;
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<StatusModel | null>(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [rolesModal, setRolesModal] = useState(false);
    const [referencesModal, setReferencesModal] = useState(false);
    const {isNavOpen} = AppStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void statusesPageInit(true, controller.signal);
        dictionaryListReq('STATUSES', 'TABLE HEADERS', controller.signal);
        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, statusesPageInit]);

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (status: StatusModel) => {
        setInitialValue(status);
        setModal(true);
    }

    const handleDelete = async () => {
        await deleteStatusReq(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const handleReferencesClick = (status: StatusModel) => {
        setInitialValue(status);
        setReferencesModal(true);
    }

    const handleStatusSelected = () => {
        for (let i = 0; i < statuses?.length!; i++) {
            statuses![i].selected = false;
        }
    }

    const handleCloseModal = () => {
        handleStatusSelected();
        setInitialValue(null);
        setModal(false);
        setReferencesModal(false);
    }

    const handleRoleClick = (status: StatusModel) => {
        setRolesModal(true);
        setInitialValue(status);
    }

    const handleRolesModalClose = () => {
        setRolesModal(false);
        setInitialValue(null);
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Headline color={primaryColor}>{getPageTitle('STATUSES')}</Headline>
            <Layout hAlign={'flex-end'}>
                <AddButton type="button" id="add-status" onClick={() => setModal(true)}> Создать </AddButton>
            </Layout>
            <TableContainer isMacOS={AppStore.isMacOS}>
                <StatusesTableDesktopView
                    handleReferencesClick={handleReferencesClick}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    handleContext={handleContext}
                    anchorEl={anchorEl}
                    reference={false}
                    tableHeaders={getTableHeaders}
                    handleRoleClick={handleRoleClick}
                />
            </TableContainer>
            <TableContainerMobile>
                <StatusesTableMobileView
                    handleReferencesClick={handleReferencesClick}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    reference={false}
                    tableHeaders={getTableHeaders}
                    handleRoleClick={handleRoleClick}
                />
            </TableContainerMobile>
            {modal && (
                <ModalView
                    title={initialValue ? 'Изменить статус' : 'Создать статус'}
                    onClose={handleCloseModal}
                >
                    <StatusesCreateEditView
                        setModal={setModal}
                        initialValue={initialValue}
                        statuses={statuses!}
                    />
                </ModalView>
            )}
            {referencesModal && (
                <AccountModalView
                    title={`Статусы после ${initialValue?.description}`}
                    onClose={handleCloseModal}
                >
                    <StatusesReferencesView
                        initialValue={initialValue!}
                    />
                </AccountModalView>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить статус"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить статус ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={pageLoading}
                    />
                </Modal>
            )}
            {rolesModal && (
                <ModalView
                    title={'Кто может менять статус'}
                    onClose={handleRolesModalClose}
                >
                    <RolesModalView initialValue={initialValue} setModal={setRolesModal}/>
                </ModalView>
            )}
        </Page>
    );
});

export default StatusesView;