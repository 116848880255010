import {PurchaseBillsModel} from "../purchase.bills.model";
import {makeAutoObservable} from "mobx";
import {ShipmentsModel} from "../../shipments/shipments.model";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

const formInitialState: PurchaseBillsModel = {
    id: 0,
    owner_purchase_bill_id: 0,
    business_unit_id: 0,
    supplier_id: 0,
    user_id: 0,
    total: 0,
    remaining: 0,
    delivery_status: 0,
    payment_status: 0,
    supplier_name: '',
    business_unit_name: '',
    user_name: '',
    document_number: '',
    delivery_status_text: '',
    payment_status_text: '',
    created_at_text: '',
    document_date_text: '',
    document_date: '',
    extra_expenses: 0,
    cbm_price: 0,
    weight_price: 0,
    exchange_rate: 0,
}
const loadingInitialState: boolean = false;
const productsListInitialState: ShipmentsModel[] = [];

class PurchaseBillsModalStore {
    public form = formInitialState;
    public isLoading = loadingInitialState;
    public productsList = productsListInitialState;
    message: string = '';
    reason: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public destroy = () => {
        this.form = formInitialState;
        this.isLoading = loadingInitialState;
        this.productsList = productsListInitialState;
    }

    public setForm = (form: PurchaseBillsModel) => {
        this.form = form;
    }

    public setBillField = (field: string, value: string | number) => {
        // @ts-ignore
        this.form[field] = value;
    }

    setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    public addSerials = (index: number, serials: string[]) => {
        this.productsList[index].serials.push(...serials);
    }

    public addProductToList = (product: any) => {
        this.productsList.push(product)
    }

    public deleteProductFromList = (index: number) => {
        this.productsList.splice(index, 1);
    }

    public changeProductField = (index: number, field: string, value: string | number) => {
        // @ts-ignore
        this.productsList[index][field] = value;
    }

    public clearProductSerials = (prodIndex: number) => {
        this.productsList[prodIndex].serials = [];
    }

    public initPurchaseBillsCreateEdit = (initialValues: PurchaseBillsModel | null) => {
        if (initialValues) {
            this.form = initialValues
        } else {
            this.form = formInitialState
        }
    }

    public createEditBillReq = async () => {
        this.setLoading(true);
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const payload = {
            purchase_bills: {
                business_unit_id: Number(this.form.business_unit_id),
                supplier_id: Number(this.form.supplier_id),
                total: Number(this.form.total),
                document_number: this.form.document_number,
                document_date_text: this.form.document_date_text
            },
            shipments: []
        }

        // @ts-ignore
        payload.shipments = this.productsList.map((product) => (
            {
                shipment: {
                    business_unit_id: Number(this.form.business_unit_id),
                    product_id: Number(product.product_id),
                    supplier_id: Number(this.form.supplier_id),
                    quantity: Number(product.quantity),
                    unit_cost: Number(product.unit_cost)
                },
                serials: product.serials
            }
        ))

        try {
            let response;

            /*if (edit) {
                response = await axios.put(api.purchase_bills, payload, {headers})
            } else {
                response = await axios.post(api.purchase_bills, payload, {headers})
            }*/

            response = await axios.post(api.purchase_bills, payload, {headers})

            this.message = response?.data.message
            toast.success(this.message);
            return response;

        } catch (e: any) {
            this.reason = e?.response?.data.reason
            toast.error(this.reason);
        } finally {
            this.setLoading(false);
        }
    }
}

export default new PurchaseBillsModalStore();