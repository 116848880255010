import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {observer} from 'mobx-react';
import AppStore from '../../app.store';
import {Page} from '../../styles/page';
import {useEffect, useState} from 'react';
import {TableHeader} from '../../styles/table-header';
import BusinessUnitsStore from '../business_units/business_units.store';
import TransactionsStore from './transactions.store';
import UsersStore from '../users/users.store';
import ProductBrandsStore from '../products/product.brands.store';
import ProductCategoriesStore from '../products/product.categories.store';
import ProductTypeStore from '../products/product.type.store';
import {Switch} from 'antd';
import {Layout} from '../../styles/layout';
import TransactionTypeStore from './transaction.type.store';
import {Image} from '../../styles/image';
import SimpleTable from '../../assets/icons/expand-solid.svg';
import ExtendedTable from '../../assets/icons/table-solid.svg';
import {
    Button,
    ButtonCreateExcel,
    ButtonCreateExcelDiv,
    FilterButton,
} from '../../styles/button';
import {handleDownloadFile} from '../../utils/handleDownloadFile';
import {api} from '../../api/endpoints';
import {Filters} from '../../styles/filters';
import TransactionsFiltersView from './transaction.filters.view';
import Modal from '../../components/modal/modal.view';
import TablePaginationView from '../../utils/table.pagination.view';
import OperationView from '../products_transactions/create.operation.modal/operation.view';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TCell,
    THeadCell,
    TRow,
    TableContainerMobile,
} from '../../styles/table';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import {StatusBadge} from '../../styles/badge';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import {hasPermission} from '../../utils/hasPermission';
import {PermissionGroup} from '../../utils/permission';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import {ApproveActionItem} from '../../components/confirm/confirm.action';
import TransProdsModal from '../../components/modal/transaction.products.modal.view';
import ApproveConfirm from '../../components/confirm/confirm.action';
import TransactionsSimpleTableMobileView from './transaction.mobile.view';
import {TransactionsModel} from "./transactions.model";
import TransactionChangeStatusView from "./transaction.change.status.view";
import StatusesStore from "../statuses/statuses.store";
import CreateOperationView from '../products_transactions/create.operation.modal/create.operation.view';
import createOperationStore from '../products_transactions/create.operation.modal/create.operation.store';
import ModalView from '../../components/modal/modal.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import ProductsMovementsTrackingView from "../utils/products.movements.tracking/products.movements.tracking.view";
import AccountModalView from "../../components/modal/account.modal.view";

const TransactionsView = observer(() => {
    const {
        isNavOpen,
        permissions,
        getToken,
        simpleTableView,
        toggleSimpleTableView,
    } = AppStore;
    const {
        transactions,
        transactionGoods,
        transactionServices,
        transactionsFilter,
        transactionsReq,
        transactionsInit,
        setTransactionsFilter,
        loading,
        transactionJournalReq,
        clearFilter,
        transactionProductsReq,
        transactionApproveReq,
        destroyProds,
        destroyJournal,
        transactionJournal,
        prodsLoading,
    } = TransactionsStore;

    const {init, destroy} = createOperationStore;
    const [initialValue, setInitialValue] = useState<TransactionsModel | null>(
        null
    );
    const {users, setUsersFilter} = UsersStore;
    const {businessUnits, setBusinessUnitFilter, clearBusinessUnitFilter, businessUnitsInit} = BusinessUnitsStore;
    const {productBrands, productBrandsInit} = ProductBrandsStore;
    const {productCategories, productCategoriesInit} = ProductCategoriesStore;
    const {productTypes, productTypesInit} = ProductTypeStore;
    const {transactionTypes, transactionTypesInit} = TransactionTypeStore;

    const [anchorEl, setAnchorEl] = useState(null);
    const [modalActive, setModalActive] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [transactionProdsModal, setTransactionProdsModal] = useState(false);
    const [confirmApproveModal, setConfirmApproveModal] =
        useState<ApproveActionItem>({isModalOpen: false});
    const [journalModal, setJournalModal] = useState(false);
    const [updateStatusModal, setUpdateStatusModal] = useState(false);
    const {statuses, statusesPageInit} = StatusesStore;
    const [updateModal, setUpdateModal] = useState(false);
    const [trackingModal, setTrackingModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const handleTransactionJournalReq = async (id: number) => {
        setJournalModal(true);
        await transactionJournalReq(id);
    };

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('TRANSACTIONS', 'TABLE HEADERS', controller.signal);
        transactionsInit(controller.signal);
        transactionTypesInit(controller.signal);
        setUsersFilter({page_limit: 100000});
        productBrandsInit(controller.signal);
        productBrandsInit(controller.signal);
        productTypesInit(controller.signal);
        productCategoriesInit(controller.signal);
        setBusinessUnitFilter({page_limit: 100000, return_balance: false})
        clearBusinessUnitFilter();
        void statusesPageInit(true, controller.signal);
        return () => controller.abort();
    }, [
        destroyDictionary,
        dictionaryListReq,
        businessUnitsInit,
        productBrandsInit,
        productCategoriesInit,
        productTypesInit,
        transactionTypesInit,
        transactionsInit,
        statusesPageInit,
    ]);
    const {businessSettings} = AppStore;
    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setTransactionsFilter({page: index + 1});
            return;
        }
        setTransactionsFilter({[objectKey]: options[selectedIndex].value});
    };

    const pageLimits = [25, 50, 100, 250, 500, 1000];

    const prevPage = () => {
        if (transactionsFilter.page > 1) {
            setTransactionsFilter({page: transactionsFilter.page - 1});
        }
    };

    const nextPage = () => {
        if (transactionsFilter.page < transactionsFilter.pages) {
            setTransactionsFilter({page: transactionsFilter.page + 1});
        }
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && transactionsFilter.id !== 0) {
            setTransactionsFilter({ id: 0 });
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            if (isNaN(+e.target.value)) return;
            setTransactionsFilter({ id: +e.target.value });
        }
    };


    const setDates = (e: any) => {
        if (e !== null) {
            setTransactionsFilter({date_to: e[1].format('YYYY-MM-DD')});
            setTransactionsFilter({date_from: e[0].format('YYYY-MM-DD')});
        } else {
            setTransactionsFilter({date_from: ''});
            setTransactionsFilter({date_to: ''});
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            setAnchorEl(null);
            e.target.classList.remove('context-menu-focused');
        } else {
            e.target.classList.add('context-menu-focused');
            setAnchorEl(e.currentTarget);
        }
    };

    useEffect(() => {
        function handleWindowClick(event: MouseEvent) {
            const modalEl = document.getElementById('subMenuExcel');
            const profileEl = document.getElementById('profileIconExcel');
            if (
                modalEl &&
                !modalEl.contains(event.target as Node) &&
                profileEl &&
                !profileEl.contains(event.target as Node)
            ) {
                setModalActive(false);
            }
        }

        window.addEventListener('click', handleWindowClick);
        return () => window.removeEventListener('click', handleWindowClick);
    }, [modalActive]);

    const handleSwitchChange = async () => {
        toggleSimpleTableView();
        await transactionsReq();
    };
    const handleTransactionProducts = (transaction_id: number) => {
        setTransactionProdsModal(true);
        void transactionProductsReq(transaction_id);
    };

    const handleTransactionApproveReq = async () => {
        await transactionApproveReq(confirmApproveModal.id!);
        setConfirmApproveModal({isModalOpen: false});
        await transactionsReq();
    };

    const handleCloseJournalModal = () => {
        destroyJournal();
        setJournalModal(false);
    };

    const handleClearFilter = () => {
        clearBusinessUnitFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = () => {
        setFilterModal(false);
    };
    const handleUpdate = (transaction: TransactionsModel) => {
        setInitialValue(transaction);
        void init(transaction);
        setUpdateModal(true);
    };

    const handleCloseUpdateModal = () => {
        destroy();
        setUpdateModal(false);
    };

    const unchangeableStatusIDs: number[] = [3, 4, 5];

    const handleChangeStatusAccess = (transaction: TransactionsModel) => {
        if (!unchangeableStatusIDs.includes(transaction.status_id)) {
            if (transaction.starting_status) {
                return (hasPermission(permissions!, PermissionGroup.ALL_BU_TRANSACTIONS, 'CREATE') ||
                    hasPermission(permissions!, PermissionGroup.TRANSACTIONS, 'CREATE'))
            } else {
                return hasPermission(permissions!, PermissionGroup.TRANSACTIONS, 'UPDATE')
            }
        }
        return false;
    };

    const handleChangeStatusClick = (transaction: TransactionsModel) => {
        setInitialValue(transaction);
        setUpdateStatusModal(true);
    }

    const handleCloseChangeStatusModal = () => {
        setInitialValue(null);
        setUpdateStatusModal(false);
    }

    const handleUpdateTransactionAccess = (transaction: TransactionsModel) => {
        return transaction.can_update && (hasPermission(permissions!, PermissionGroup.TRANSACTIONS, 'CREATE') ||
            hasPermission(permissions!, PermissionGroup.ALL_BU_TRANSACTIONS, 'CREATE'));
    }

    const handleTableColumnsCount = () => {
        let value = 12;
        if (!simpleTableView && businessSettings?.product_commissions) {
            value = 15
        } else if (!simpleTableView && !businessSettings?.product_commissions) {
            value = 13
        } else if (simpleTableView && businessSettings?.product_commissions) {
            value = 14
        }

        return value;
    }

    const handleOpenTrackingHistory = (transaction: TransactionsModel) => {
        setInitialValue(transaction);
        setTrackingModal(true)
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Layout hAlign={'space-between'}>
                <Headline color={primaryColor}>{getPageTitle('TRANSACTIONS')}</Headline>
                <Layout hAlign={'flex-end'} vAlign={'center'} between={5}>
                    <Image
                        src={ExtendedTable}
                        height={'20px'}
                        width={'20px'}
                        style={{
                            filter:
                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                        }}
                    />

                    <Switch
                        key={'table-view'}
                        checked={simpleTableView}
                        onClick={handleSwitchChange}
                    />
                    <Image
                        src={SimpleTable}
                        height={'20px'}
                        width={'20px'}
                        style={{
                            filter:
                                'brightness(0) saturate(100%) invert(17%) sepia(0%) saturate(1%) hue-rotate(224deg) brightness(96%) contrast(90%)',
                        }}
                    />
                </Layout>
            </Layout>
            <TableHeader>
                <Filters>
                    <TransactionsFiltersView
                        setDates={setDates}
                        productBrands={productBrands}
                        productCategories={productCategories}
                        productTypes={productTypes}
                        transactionTypes={transactionTypes}
                        transactionStatuses={statuses}
                        users={users}
                        handleFilterChange={handleFilterChange}
                        businessUnits={businessUnits}
                        inModal={false}
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                    />
                </Filters>
                <FilterButton
                    id={'transaction-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <ButtonCreateExcelDiv dropdown={false}>
                    <ButtonCreateExcel
                        onClick={() => {
                            let url = api.transactions_excel;

                            if (transactionsFilter.date_from !== '') {
                                url += `?date_from=${transactionsFilter.date_from}`;

                                if (transactionsFilter.date_to !== '') {
                                    url += `&date_to=${transactionsFilter.date_to}`;
                                }
                            }

                            if (transactionsFilter.date_from === '') {
                                if (transactionsFilter.date_to !== '') {
                                    url += `?date_to=${transactionsFilter.date_to}`;
                                }
                            }

                            void handleDownloadFile(
                                url,
                                getToken(),
                                'transactions_excel',
                                'excel'
                            );
                        }}
                    >
                        Скачать excel
                    </ButtonCreateExcel>
                </ButtonCreateExcelDiv>
                <div style={{marginLeft: 10}}>
                    <OperationView/>
                </div>
            </TableHeader>
            <TableContainer isMacOS={AppStore.isMacOS}>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{getTableHeaders('owner_transaction_id')}</THeadCell>
                            <THeadCell>{getTableHeaders('business_unit')}</THeadCell>
                            <THeadCell>{getTableHeaders('user')}</THeadCell>
                            <THeadCell>{simpleTableView ? 'Продукты' : 'Продукт'}</THeadCell>
                            <THeadCell>{getTableHeaders('client')}</THeadCell>
                            {!simpleTableView && <THeadCell>{getTableHeaders('account')}</THeadCell>}
                            <THeadCell>{getTableHeaders('payment_method')}</THeadCell>
                            <THeadCell>{getTableHeaders('trans_type')}</THeadCell>
                            <THeadCell>{getTableHeaders('details')}</THeadCell>
                            <THeadCell>{getTableHeaders('amount')}</THeadCell>
                            {businessSettings?.product_commissions && (
                                <THeadCell>{getTableHeaders('commission')}</THeadCell>
                            )}
                            {businessSettings?.product_commissions && (
                                <THeadCell>Общая сумма</THeadCell>
                            )}
                            <THeadCell>{getTableHeaders('status')}</THeadCell>
                            <THeadCell>{getTableHeaders('formatted_date')}</THeadCell>
                            <THeadCell></THeadCell>
                        </TRow>
                    </TableHead>
                    {loading ? (
                        <TableSkeleton columns={handleTableColumnsCount()}/>
                    ) : (
                        <TableBody>
                            {transactions?.length ? (
                                transactions?.map((transaction, index) => (
                                    <TRow key={index} menu>
                                        <TCell>{transaction.owner_transaction_id}</TCell>
                                        <TCell>{transaction.business_unit}</TCell>
                                        <TCell>{transaction.user}</TCell>
                                        <TCell>
                                            {simpleTableView ? (
                                                <Button
                                                    style={{fontWeight: 'bolder'}}
                                                    extent={'SS'}
                                                    background={'#777'}
                                                    onClick={() => {
                                                        handleTransactionProducts(transaction.id);
                                                    }}
                                                >
                                                    Открыть
                                                </Button>
                                            ) : (
                                                transaction.product
                                            )}
                                        </TCell>
                                        <TCell>{transaction.client}</TCell>
                                        {!simpleTableView && <TCell>{transaction.account}</TCell>}
                                        <TCell>{transaction.payment_method}</TCell>
                                        <TCell>{transaction.trans_type}</TCell>
                                        <TCell>{transaction.details}</TCell>
                                        <TCell>{transaction.amount}</TCell>
                                        {businessSettings?.product_commissions && (
                                            <TCell>{transaction.commission}</TCell>
                                        )}
                                        {businessSettings?.product_commissions && (
                                            <TCell>{transaction.amount + transaction.commission}</TCell>
                                        )}
                                        <TCell>
                                            <StatusBadge color={transaction.status_color}>
                                                {transaction.status}
                                            </StatusBadge>
                                        </TCell>
                                        <TCell>{transaction.formatted_date}</TCell>
                                        <TCell relative>
                                            <Image
                                                src={ContextMenuIcon}
                                                height="18px"
                                                onClick={handleContext}
                                            />
                                            <ContextMenu anchorEl={anchorEl}>
                                                {handleChangeStatusAccess(transaction) && (
                                                    <ContextMenuItem
                                                        onClick={() => handleChangeStatusClick(transaction)}
                                                    >
                                                        Изменить статус
                                                    </ContextMenuItem>
                                                )}

                                                {handleUpdateTransactionAccess(transaction) && (
                                                    <ContextMenuItem
                                                        onClick={() => handleUpdate(transaction)}
                                                    >
                                                        Изменить
                                                    </ContextMenuItem>
                                                )}
                                                {hasPermission(
                                                    permissions!,
                                                    PermissionGroup.TRANSACTIONS,
                                                    'DOWNLOAD'
                                                ) && (
                                                    <ContextMenuItem
                                                        onClick={() => {
                                                            void handleDownloadFile(
                                                                api.download_transaction_cheque +
                                                                '?id=' +
                                                                transaction.id,
                                                                getToken(),
                                                                'operation_' + transaction.id,
                                                                'pdf'
                                                            );
                                                        }}
                                                    >
                                                        Скачать PDF
                                                    </ContextMenuItem>
                                                )}
                                                <ContextMenuItem
                                                    onClick={() =>
                                                        handleTransactionJournalReq(transaction.id)
                                                    }
                                                >
                                                    История изменений
                                                </ContextMenuItem>
                                                <ContextMenuItem
                                                    onClick={() =>
                                                        handleOpenTrackingHistory(transaction)
                                                    }
                                                >
                                                    История движения
                                                </ContextMenuItem>
                                            </ContextMenu>
                                        </TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={handleTableColumnsCount()}/>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TableContainerMobile>
                <TransactionsSimpleTableMobileView
                    loading={loading}
                    permissions={permissions}
                    transactions={transactions}
                    getToken={getToken}
                    transactionJournalReq={transactionJournalReq}
                    setJournalModal={setJournalModal}
                    tableHeaders={getTableHeaders}
                    handleChangeStatusClick={handleChangeStatusClick}
                    handleChangeStatusAccess={handleChangeStatusAccess}
                />
            </TableContainerMobile>
            {transactionProdsModal && (
                <TransProdsModal
                    title="Список продуктов"
                    onClose={() => {
                        setTransactionProdsModal(false);
                        destroyProds();
                    }}
                >
                    <>
                        {prodsLoading ? (
                            <TableSkeleton columns={6}/>
                        ) : (
                            <>
                                {transactionGoods?.length ? (
                                    <Layout>
                                        <p>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    color: primaryColor,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Товары
                                            </p>
                                            <Table title={'Товары'}>
                                                <TableHead>
                                                    <TRow tableHead>
                                                        <THeadCell>Название</THeadCell>
                                                        <THeadCell>Бренд</THeadCell>
                                                        <THeadCell>Серийный номер</THeadCell>
                                                        <THeadCell>Единица измерения</THeadCell>
                                                        <THeadCell>Количество</THeadCell>
                                                        <THeadCell>Цена</THeadCell>
                                                        <THeadCell>Общая сумма</THeadCell>
                                                    </TRow>
                                                </TableHead>
                                                <TableBody>
                                                    {transactionGoods?.length ? (
                                                        transactionGoods?.map((goods, index) => (
                                                            <TRow key={index} menu>
                                                                <TCell>{goods.name}</TCell>
                                                                <TCell>{goods.brand}</TCell>
                                                                <TCell>{goods.serial_number}</TCell>
                                                                <TCell>{goods.measure_unit}</TCell>
                                                                <TCell>{goods.quantity}</TCell>
                                                                <TCell>{goods.price}</TCell>
                                                                <TCell>{goods.total}</TCell>
                                                            </TRow>
                                                        ))
                                                    ) : (
                                                        <NoResultTitle colspan={6}/>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </p>
                                    </Layout>
                                ) : (
                                    <></>
                                )}

                                {transactionServices?.length ? (
                                    <Layout>
                                        <p>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    color: primaryColor,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Сервисы
                                            </p>
                                            <Table title={'Сервисы'}>
                                                <TableHead>
                                                    <TRow tableHead>
                                                        <THeadCell>Название</THeadCell>
                                                        <THeadCell>Счет</THeadCell>
                                                        <THeadCell>Сумма</THeadCell>
                                                    </TRow>
                                                </TableHead>
                                                <TableBody>
                                                    {transactionServices?.length ? (
                                                        transactionServices?.map((service, index) => (
                                                            <TRow key={index} menu>
                                                                <TCell>{service.name}</TCell>
                                                                <TCell>{service.account}</TCell>
                                                                <TCell>{service.total}</TCell>
                                                            </TRow>
                                                        ))
                                                    ) : (
                                                        <NoResultTitle colspan={3}/>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </p>
                                    </Layout>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                        {!transactionGoods && !transactionServices && (
                            <Layout hAlign="center">
                                <NoResultTitle colspan={4}/>
                            </Layout>
                        )}
                    </>
                </TransProdsModal>
            )}
            {confirmApproveModal.isModalOpen && (
                <Modal
                    title="Проведение операции"
                    onClose={() => {
                        setConfirmApproveModal({isModalOpen: false});
                    }}
                >
                    <ApproveConfirm
                        warningText={`Вы уверены, что хотите провести операцию номер ${confirmApproveModal.id}?`}
                        setModal={setConfirmApproveModal}
                        callback={handleTransactionApproveReq}
                        isLoading={loading}
                    />
                </Modal>
            )}

            {updateModal && (
                <ModalView
                    title={'Изменить операцию'}
                    onClose={handleCloseUpdateModal}
                >
                    <CreateOperationView
                        initialValue={initialValue}
                        setModal={setUpdateModal}
                    />
                </ModalView>
            )}
            {journalModal && (
                <Modal title={'История изменений'} onClose={handleCloseJournalModal}>
                    <>
                        {transactionJournal?.map((journal) => (
                            <div
                                style={{
                                    maxWidth: '100%',
                                    display: 'flex',
                                    boxShadow: '0 0 5px grey',
                                    padding: '15px',
                                    borderRadius: '5px',
                                    marginBottom: '5px',
                                }}
                            >
                                <p
                                    style={{
                                        width: '250px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {journal.user}
                                </p>
                                <p
                                    style={{
                                        width: '250px',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <StatusBadge color={journal.status_color}>
                                        {journal.status}
                                    </StatusBadge>
                                    <p style={{fontSize: '12px'}}> {journal.description} </p>
                                </p>
                                <p
                                    style={{
                                        width: '250px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {journal.reason}
                                </p>
                                <p
                                    style={{
                                        width: '250px',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {journal.formatted_date}
                                </p>
                            </div>
                        ))}
                    </>
                </Modal>
            )}
            {updateStatusModal && (
                <Modal
                    title={'Изменить статус операции'}
                    onClose={handleCloseChangeStatusModal}
                >
                    <TransactionChangeStatusView
                        initialValue={initialValue}
                        setModal={setUpdateStatusModal}
                    />
                </Modal>
            )}
            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <TransactionsFiltersView
                            setDates={setDates}
                            productBrands={productBrands}
                            productCategories={productCategories}
                            productTypes={productTypes}
                            transactionTypes={transactionTypes}
                            transactionStatuses={statuses}
                            users={users}
                            handleFilterChange={handleFilterChange}
                            businessUnits={businessUnits}
                            inModal={true}
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {trackingModal && (
                <AccountModalView title={'История движения'} onClose={() => setTrackingModal(false)}>
                    <ProductsMovementsTrackingView operationID={initialValue?.id!} operationType={'TRANSACTION'}/>
                </AccountModalView>
            )}
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={transactionsFilter.pages}
                currentPage={transactionsFilter.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
        </Page>
    );
});

export default TransactionsView;
