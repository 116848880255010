import {observer} from "mobx-react";
import {Page} from "../../../styles/page";
import AppStore from "../../../app.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import {useEffect, useState} from "react";
import {Layout} from "../../../styles/layout";
import {Headline} from "../../../styles/headline";
import {borderRadius, primaryColor} from "../../../utils/variables";
import TableIcon from '../../../assets/icons/table.svg';
import GraphIcon from '../../../assets/icons/graph.svg';
import PieIcon from '../../../assets/icons/pie.svg';
import {
    EyeImgIcon,
    TableContainer,
    TableContainerMobile,
} from "../../../styles/table";
import {Filters} from "../../../styles/filters";
import {Button, FilterButton} from "../../../styles/button";
import {TableHeader} from "../../../styles/table-header";
import SalesReportFilterView from "./sales.report.filter.view";
import ModalView from "../../../components/modal/modal.view";
import SalesReportGraphView from "./sales.report.graph.view";
import SalesReportTableView from "./sales.report.table.view";
import SalesReportStore from "./sales.report.store";
import {FilterModel} from "./sales.report.model";
import {Text} from "../../../styles/text";
import SalesReportPieView from "./sales.report.pie.view";
import {Graph} from "../../main/main.style";

const SalesReportView = observer(() => {
    const {isNavOpen} = AppStore;
    const [graphView, setGraphView] = useState(false);
    const [pieView, setPieView] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const {
        salesReportTable,
        loading,
        setSalesReportFilters,
        salesReportFilters,
        clearFilter,
        salesReportGraph,
        totals,
        getTableReportsReq,
    } = SalesReportStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        void dictionaryListReq('SALES REPORTS', 'TABLE HEADERS', controller.signal);
        void getTableReportsReq(!graphView, controller.signal);
        clearFilter();
        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, graphView]);

    const handleTableIconClick = () => {
        setGraphView(false);
        setPieView(false);
    }

    const handleGraphIconClick = () => {
        setGraphView(true);
        setPieView(false);
    }

    const handlePieIconClick = () => {
        setPieView(true);
        setGraphView(false);
    }

    const setDates = (e: any) => {
        if (e !== null) {
            setSalesReportFilters({date_to: e[1].format('YYYY-MM-DD')} as FilterModel, !graphView);
            setSalesReportFilters({date_from: e[0].format('YYYY-MM-DD')} as FilterModel, !graphView);
        } else {
            setSalesReportFilters({date_from: ''} as FilterModel, !graphView);
            setSalesReportFilters({date_to: ''} as FilterModel, !graphView);
        }
    };

    const handleFilterChange = (e: any, objectKey: string) => {
        const {
            target: {options, selectedIndex},
        } = e;
        setSalesReportFilters({[objectKey]: options[selectedIndex].value} as FilterModel, !graphView);
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    }

    const renderContent = () => {
        if (graphView) {
            return (
                <Graph>
                    <SalesReportGraphView reports={salesReportGraph}/>
                </Graph>
            );
        } else if (pieView) {
            return (
                <Layout
                    style={{
                        background: '#ffffff',
                        borderRadius: borderRadius
                    }}
                >
                    <SalesReportPieView/>
                </Layout>
            );
        } else {
            return (
                <>
                    <TableContainer isMacOS={AppStore.isMacOS}>
                        <SalesReportTableView
                            getTableHeaders={getTableHeaders}
                            reports={salesReportTable}
                            loading={loading}
                        />
                    </TableContainer>
                    <TableContainerMobile>
                        <SalesReportTableView
                            getTableHeaders={getTableHeaders}
                            reports={salesReportTable}
                            loading={loading}
                        />
                    </TableContainerMobile>
                </>
            );
        }
    }

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <Layout hAlign={"space-between"} vAlign={"center"}>
                <Headline color={primaryColor}>{getPageTitle('SALES REPORTS')}</Headline>
                <Layout vAlign={"center"} between={20}>
                    <EyeImgIcon
                        src={TableIcon}
                        extent='30px'
                        active={!graphView && !pieView}
                        onClick={handleTableIconClick}
                    />
                    <EyeImgIcon
                        src={PieIcon}
                        extent='30px'
                        active={pieView}
                        onClick={handlePieIconClick}
                    />
                    <EyeImgIcon
                        src={GraphIcon}
                        extent='30px'
                        active={graphView}
                        onClick={handleGraphIconClick}
                    />
                </Layout>
            </Layout>
            <Layout vertical between={10}>
                <TableHeader>
                    <Filters>
                        <SalesReportFilterView
                            setDates={setDates}
                            handleFilterChange={handleFilterChange}
                            inModal={false}
                            graphView={graphView}
                            filters={salesReportFilters}
                            pieView={pieView}
                        />
                    </Filters>
                    <FilterButton
                        id={'sales-report-filter-button'}
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                </TableHeader>
                {!pieView && (
                    <Layout vertical between={10}>
                        <Layout>
                            <Text weight='bold'>Итого</Text>
                        </Layout>
                        <Layout
                            hAlign={"flex-start"}
                            vAlign={"center"}
                            between={30}
                            style={{
                                maxWidth: '100%',
                                padding: '15px',
                                borderRadius: '5px',
                                marginBottom: '5px',
                                background: '#ffffff'
                            }}
                        >
                            <Layout between={5}>
                                <Text>{`${getTableHeaders('turnover')}:`}</Text>
                                <Text>{totals.turnover}</Text>
                            </Layout>
                            <Layout between={5}>
                                <Text>{`${getTableHeaders('average')}:`}</Text>
                                <Text>{totals.average}</Text>
                            </Layout>
                            <Layout between={5}>
                                <Text>{`${getTableHeaders('quantity')}:`}</Text>
                                <Text>{totals.quantity}</Text>
                            </Layout>
                        </Layout>
                    </Layout>
                )}
            </Layout>
            <>
                {renderContent()}
            </>
            {filterModal && (
                <ModalView
                    title='Фильтры'
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SalesReportFilterView
                            setDates={setDates}
                            handleFilterChange={handleFilterChange}
                            inModal={true}
                            graphView={graphView}
                            filters={salesReportFilters}
                            pieView={pieView}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={handleConfirmFilter}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>

                </ModalView>
            )}
        </Page>
    );
});

export default SalesReportView;