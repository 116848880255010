import {Headline} from '../../styles/headline';
import {primaryColor} from '../../utils/variables';
import {observer} from 'mobx-react';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import AppStore from '../../app.store';
import {Page} from '../../styles/page';
import {useEffect, useState} from 'react';
import {AddButton, TableHeader} from '../../styles/table-header';
import {Image} from '../../styles/image';
import Modal from '../../components/modal/modal.view';
import {Filters} from '../../styles/filters';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import {Button, FilterButton} from '../../styles/button';
import BusinessUnitsStore from '../business_units/business_units.store';
import {Layout} from '../../styles/layout';
import TransferStore from './transfer.store';
import {TransferModalFilter, TransferModel} from './transfer.model';
import {TableContainerMobile} from '../../styles/table';
import TransfersTableMobileView from './transfer.mobile.view';
import TransferFiltersView from './transfer.filter.view';
import usersStore from '../users/users.store';
import ModalView from '../../components/modal/modal.view';
import TransferCreateEditView from './transfer_of_goods.modal/transfer.create.view';
import TransferProductsView from './transfer.products.view';
import {Badge} from '../../styles/badge';
import AccountModalView from '../../components/modal/account.modal.view';
import TablePaginationView from '../../utils/table.pagination.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import TransfersGoodsView from "./transfer_of_goods.modal/transfers.goods.view";
import TransfersGoodsStore from "./transfer_of_goods.modal/transfers.goods.store";

export const Transfers = observer(() => {
    const {isNavOpen} = AppStore;
    const [initialValue, setInitialValue] = useState<TransferModel | null>(null);
    const {users, usersReq} = usersStore;
    const {businessUnits, businessUnitsReq} = BusinessUnitsStore;
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalTransferProducts, setTransferProductsModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;
    const {destroyTransfersModal} = TransfersGoodsStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('TRANSFERS', 'TABLE HEADERS', controller.signal);
        void TransferStore.transfersReq(controller.signal);
        void usersReq(controller.signal);
        void businessUnitsReq(controller.signal);

        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, usersReq, businessUnitsReq]);

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleAddClick = () => {
        setInitialValue(null);
        setModal(true);
    };

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            TransferStore.setTransfersFilter({
                page: index + 1,
            } as TransferModalFilter);
            return;
        }
        TransferStore.setTransfersFilter({
            [objectKey]: options[selectedIndex].value,
        } as TransferModalFilter);
    };
    const setDates = (e: any) => {
        if (e !== null) {
            TransferStore.setTransfersFilter({
                date_to: e[1].format('YYYY-MM-DD'),
            } as TransferModalFilter);
            TransferStore.setTransfersFilter({
                date_from: e[0].format('YYYY-MM-DD'),
            } as TransferModalFilter);
        } else {
            TransferStore.setTransfersFilter({
                date_from: '',
            } as TransferModalFilter);
            TransferStore.setTransfersFilter({
                date_to: '',
            } as TransferModalFilter);
        }
    };
    const cancelSearch = (e: any) => {
        if (e.target.value === '' && TransferStore.filter.search !== '') {
            TransferStore.setTransfersFilter({search: ''} as TransferModalFilter);
        }
    };
    const handleTransferProductClick = (transfer: TransferModel) => {
        setInitialValue(transfer);
        setTransferProductsModal(true);
    };
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            TransferStore.setTransfersFilter({
                search: e.target.value,
            } as TransferModalFilter);
        }
    };

    const handleClearFilter = () => {
        TransferStore.clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = (e: any) => {
        setFilterModal(false);
    };
    const prevPage = () => {
        if (TransferStore.filter.page > 1) {
            TransferStore.setTransfersFilter({
                page: TransferStore.filter.page - 1,
            } as TransferModalFilter);
        }
    };

    const nextPage = () => {
        if (TransferStore.filter.page < TransferStore.filter.pages) {
            TransferStore.setTransfersFilter({
                page: TransferStore.filter.page + 1,
            } as TransferModalFilter);
        }
    };

    const pageLimits = [1, 5, 10, 25, 100, 500];

    const handleCloseCreateModal = () => {
        destroyTransfersModal();
        setInitialValue(null);
        setModal(false);
    }
    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('TRANSFERS')}</Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <TransferFiltersView
                            setDates={setDates}
                            handleKeyDown={handleKeyDown}
                            handleFilterChange={handleFilterChange}
                            cancelSearch={cancelSearch}
                            users={users}
                            fromBu={businessUnits}
                            toBu={businessUnits}
                            inModal={false}
                        />
                    </Filters>
                    <FilterButton onClick={() => setFilterModal(true)}>
                        Фильтры
                    </FilterButton>
                    <AddButton type="button" id="user_id" onClick={handleAddClick}>
                        Создать
                    </AddButton>
                </TableHeader>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('owner_transfer_id')}</THeadCell>
                                <THeadCell>{getTableHeaders('user_name')}</THeadCell>
                                <THeadCell>{getTableHeaders('from_business_unit')}</THeadCell>
                                <THeadCell>{getTableHeaders('to_business_unit')}</THeadCell>
                                <THeadCell>{getTableHeaders('details')}</THeadCell>
                                <THeadCell>{getTableHeaders('delivered')}</THeadCell>
                                <THeadCell>{getTableHeaders('date')}</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!TransferStore.loading ? (
                            <TableBody>
                                {TransferStore.transfers!.length ? (
                                    TransferStore.transfers?.map((transfer, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{transfer.owner_transfer_id}</TCell>
                                            <TCell>{transfer.user_name}</TCell>
                                            <TCell>{transfer.from_business_unit}</TCell>
                                            <TCell>{transfer.to_business_unit}</TCell>
                                            <TCell>{transfer.details}</TCell>
                                            <TCell>
                                                <Badge online={transfer.delivered}>
                                                    {transfer.delivered ? 'Да' : 'Нет'}
                                                </Badge>
                                            </TCell>

                                            <TCell>{transfer.date}</TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    <ContextMenuItem
                                                        onClick={() => handleTransferProductClick(transfer)}
                                                    >
                                                        Товары
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={10}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={10}/>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <TransfersTableMobileView
                        loading={TransferStore.loading}
                        transfers={TransferStore.transfers}
                        handleTransferProductClick={handleTransferProductClick}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
            </>

            {filterModal && (
                <Modal title={'Фильтры'} onClose={() => setFilterModal(false)}>
                    <Layout vertical between={10}>
                        <TransferFiltersView
                            setDates={setDates}
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            handleFilterChange={handleFilterChange}
                            fromBu={businessUnits}
                            toBu={businessUnits}
                            users={users}
                            inModal={true}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={(e) => handleConfirmFilter(e)}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={TransferStore.filter.pages}
                currentPage={TransferStore.filter.page!}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {modal && (
                <AccountModalView
                    fullScreen
                    title={initialValue ? 'Изменить перевод' : 'Создать перевод'}
                    onClose={handleCloseCreateModal}
                >
                    <TransfersGoodsView setModal={setModal}/>
                </AccountModalView>
            )}
            {modalTransferProducts && (
                <AccountModalView
                    title={'Товары'}
                    onClose={() => {
                        setTransferProductsModal(false);
                    }}
                >
                    <TransferProductsView transferId={`${initialValue?.id}`}/>
                </AccountModalView>
            )}
        </Page>
    );
});
