import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {ShipmentsModel} from "../../shipments/shipments.model";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {Button} from "../../../styles/button";
import {TextInput} from "../../../styles/form";
import {useEffect, useState} from "react";
import PurchaseBillsModalStore from "./purchase.bills.modal.store";

interface PurchaseBillsModalSerialsViewProps {
    initialValue: ShipmentsModel | null;
    setModal: (isOpen: boolean) => void;
    index: number;
}

interface Tag {
    id: number;
    value: string;
    isDuplicate: boolean;
}

const PurchaseBillsModalSerialsView = observer(({initialValue, setModal, index}: PurchaseBillsModalSerialsViewProps)  => {

    const [inputValue, setInputValue] = useState<string>('');
    const [tags, setTags] = useState<Tag[]>([]);
    const {clearProductSerials} = PurchaseBillsModalStore

    useEffect(() => {
        const existingSerials = initialValue?.serials.map((serial) =>
            ({id: Date.now() + Math.random(), value: serial, isDuplicate: false}));
        setTags(existingSerials!)
    }, []);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const {addSerials} = PurchaseBillsModalStore;

    // Handle adding tags when pressing "Enter" or after pasting
    const handleInputKeyDown = (event: any) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
            event.preventDefault();
            addTags(inputValue);
        }
    };

    // Helper function to add tags
    const addTags = (input: string) => {
        const newTags = input
            .split(/[ ,]+/) // Split by space or comma
            .filter(value => value.trim() !== '') // Remove empty values

        const updatedTags = [...tags];
        newTags.forEach((tagValue) => {
            const isDuplicate = updatedTags.some((tag) => tag.value === tagValue);
            updatedTags.push({ value: tagValue, isDuplicate: isDuplicate, id: Date.now() + Math.random() });
        });

      //      .map(value => ({id: Date.now() + Math.random(), value})); // Create unique IDs for each tag

     //   setTags(prevTags => [...prevTags, ...newTags]);
        setTags(updatedTags);
        setInputValue('');
    };

    // Handle removing a tag
    const handleRemoveTag = (id: number) => {
        setTags(prevTags => prevTags.filter(tag => tag.id !== id));
    };

    let serialList = tags.map(
        ({value}) => (value)
    )

    const handleClearAllClick = () => {
        setTags([]);
        serialList = [];
    }

    const handleSubmit = () => {
        clearProductSerials(index)
        addSerials(index, serialList)
        setModal(false);
        handleClearAllClick();
    }

    const handleCancel = () => {
        setModal(false);
        handleClearAllClick();
    }

    return (
        <Layout vertical between={20}>
            <Layout hAlign={"space-between"} vAlign={'center'}>
                <Layout>
                    <Text color={tags.length > initialValue?.quantity! ? 'red' : modalTextColor}
                          extent={'S'}>Количество: {tags.length}</Text>
                </Layout>
                <Layout>
                    <Button
                        onClick={handleClearAllClick}
                    >
                        Очистить все
                    </Button>
                </Layout>
            </Layout>
            <Layout style={{
                border: '2px solid #ddd',
                padding: '8px',
                marginTop: '10px',
                width: '100%',
                borderRadius: '10px',
                backgroundColor: "rgba(0, 0, 0, .03)",
                color: '#333'
            }} vertical>
                <TextInput
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    placeholder="Введите все серийные номера через пробел или запятая"
                    style={{border: 'none', outline: 'none', width: '100%'}}
                />
                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                    {tags.map(tag => (
                        <Layout
                            key={tag.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: '#e0e0e0',
                                borderRadius: '8px',
                                padding: '5px',
                                margin: '5px',
                                color: tag.isDuplicate ? "red" : "black",
                            }}
                        >
                            <Text extent={'S'} color={'#555'}>{tag.value}</Text>
                            <button
                                onClick={() => handleRemoveTag(tag.id)}
                                style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: '#888',
                                }}
                            >
                                ×
                            </button>
                        </Layout>
                    ))}
                </div>
            </Layout>
            <Layout centered>
                <Layout between={20}>
                    <Layout>
                        <Button
                            background={'#888'}
                            extent="S"
                            heightSize="35px"
                            onClick={handleCancel}
                        >
                            Отменить
                        </Button>
                    </Layout>
                    <Layout>
                        <Button
                            extent="S"
                            heightSize="35px"
                            onClick={handleSubmit}
                            disabled={tags.length != initialValue?.quantity}
                        >
                            Подтвердить
                        </Button>
                    </Layout>
                </Layout>
            </Layout>
        </Layout>
    );
});

export default PurchaseBillsModalSerialsView;