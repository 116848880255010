import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";
import {toast} from "react-toastify";

export interface Products {
    product_id: number;
    quantity: number;
    has_serial: boolean;
    quantity_in_hand: number;
    name: string;
    brand: string;
    part_number: string;
    serial_ids: number[];
}

const products: Products[] = []

const initialValues = {
    from_business_unit_id: 0,
    to_business_unit_id: 0,
    delivered: false,
    details: '',
    products: products,
};


class TransfersGoodsStore {
    public form = initialValues;
    public error: string = '';
    public message: string = '';
    public isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    public setForm = (form: any) => {
        this.form = form
    }

    setLoading(loading: boolean) {
        this.isLoading = loading
    }

    public addProductToList = (product: Products) => {
        this.form.products.push(product);
    }

    public addProductSerials = (index: number, serialID: number[]) => {
        this.form.products[index].serial_ids.push(...serialID)
    }

    public clearProductSerials = (prodIndex: number) => {
        this.form.products[prodIndex].serial_ids = []
    }

    public deleteProduct = (index: number) => {
        this.form.products.splice(index, 1);
    }

    public destroyTransfersModal = () => {
        this.form = initialValues;
        this.form.products = [];
        this.setLoading(false);
    }

    public createTransferReq = async () => {
        this.setLoading(true)
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        try {
            const response = await axios.post(api.transfers, this.form, {headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            this.error = e?.response?.data.reason;
            toast.error(this.error);
        } finally {
            this.setLoading(false)
        }
    }
}

export default new TransfersGoodsStore();