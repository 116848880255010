import {observer} from 'mobx-react';
import AccountStore from './account.store';
import {useEffect, useState} from 'react';
import ConfirmDelete, {
    DeleteItem,
} from '../../components/confirm/confirm.delete';
import {AccountsModel} from './account.model';
import {AddButton} from '../../styles/table-header';
import {Layout} from '../../styles/layout';
import {Image} from '../../styles/image';
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow,
} from '../../styles/table';
import ContextMenuIcon from '../../assets/icons/context-menu.svg';
import NoResultTitle from '../../components/no_result_title/no_result_title.view';
import Modal from '../../components/modal/modal.view';
import AccountCreateEditView from './account.modal/accounts.create.edit.view';
import {PageView} from '../../styles/page';
import BonusPaymentView from './bonus.payment.view';
import AccountModalView from '../../components/modal/account.modal.view';
import AccountRecordsView from './account.records.view';
import TableSkeleton from '../../utils/skeleton/table.skeleton';
import AccountsMobileView from './accounts.mobile.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import AppStore from "../../app.store";

interface AccountViewProps {
    entityType: string;
    entityId: string;
}

const AccountView = observer(({entityType, entityId}: AccountViewProps) => {
    const {accounts, accountsPageInit, deleteAccount, loading} = AccountStore;
    const [modal, setModal] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [initialValue, setInitialValue] = useState<AccountsModel | null>(null);
    const [bonusPaymentModal, setBonusPaymentModal] = useState(false);
    const [supplierPaymentModal, setSupplierPaymentModal] = useState(false);
    const [accountID, setAccountID] = useState<number | null>(null);
    const [accountRecordsModal, setAccountRecordsModal] = useState(false);
    const {getTableHeaders, dictionaryListReq, destroyDictionary} = DictionaryStore;


    useEffect(() => {
        const controller = new AbortController();
        destroyDictionary()
        void accountsPageInit(entityType, entityId, controller.signal);
        void dictionaryListReq('ACCOUNTS', 'TABLE HEADERS', controller.signal)

        return () => {
            controller.abort();
        };
    }, [destroyDictionary, accountsPageInit]);

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    };

    const handleEditClick = (account: AccountsModel) => {
        setInitialValue(account);
        setModal(true);
    };

    const handleBonusPayment = (id: number) => {
        setBonusPaymentModal(true);
        setAccountID(id);
    };
    const handleMakePayment = (id: number) => {
        setSupplierPaymentModal(true);
        setAccountID(id);
    };

    const handleDelete = async () => {
        await deleteAccount(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const handleAccountRecords = (accountID: number) => {
        setAccountRecordsModal(true);
        setAccountID(accountID);
    };

    return (
        <PageView isNavOpen={false} vertical between={10}>
            <>
                <Layout between={10} wrapped hAlign="flex-end">
                    <AddButton
                        type="button"
                        id="add-account"
                        onClick={() => setModal(true)}
                    >
                        Создать
                    </AddButton>
                </Layout>
                <TableContainer isMacOS={AppStore.isMacOS}>
                    <Table>
                        <TableHead>
                            <TRow tableHead>
                                <THeadCell>{getTableHeaders('id')}</THeadCell>
                                <THeadCell>{getTableHeaders('number')}</THeadCell>
                                <THeadCell>{getTableHeaders('category')}</THeadCell>
                                <THeadCell>{getTableHeaders('description')}</THeadCell>
                                <THeadCell>{getTableHeaders('balance')}</THeadCell>
                                <THeadCell></THeadCell>
                            </TRow>
                        </TableHead>
                        {!loading ? (
                            <TableBody>
                                {accounts?.length ? (
                                    accounts?.map((account, index) => (
                                        <TRow key={index} menu>
                                            <TCell width="50">{account.id}</TCell>
                                            <TCell width="50">{account.number}</TCell>
                                            <TCell width="50">{account.category}</TCell>
                                            <TCell width="100">{account.description}</TCell>
                                            <TCell width="50">{account.balance}</TCell>
                                            <TCell width="10" relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    {entityType === 'supplier' &&
                                                    account.category_id === 7 ? (
                                                        <ContextMenuItem
                                                            onClick={() => handleMakePayment(account.id)}
                                                        >
                                                            Провести оплату
                                                        </ContextMenuItem>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <ContextMenuItem
                                                        onClick={() => handleAccountRecords(account.id)}
                                                    >
                                                        История операции
                                                    </ContextMenuItem>
                                                    {entityType === 'user' &&
                                                    account.category_id === 2 ? (
                                                        <ContextMenuItem
                                                            onClick={() => handleBonusPayment(account.id)}
                                                        >
                                                            Оплата бонуса
                                                        </ContextMenuItem>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <ContextMenuItem
                                                        onClick={() => {
                                                            console.log('account:', account);
                                                            handleEditClick(account);
                                                        }}
                                                    >
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: account.id,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={6}/>
                                )}
                            </TableBody>
                        ) : (
                            <TableSkeleton columns={6}/>
                        )}
                    </Table>
                </TableContainer>
                <TableContainerMobile>
                    <AccountsMobileView
                        accounts={accounts}
                        handleEditClick={handleEditClick}
                        handleBonusPayment={handleBonusPayment}
                        handleAccountRecords={handleAccountRecords}
                        setConfirmDeleteModal={setConfirmDeleteModal}
                        entityType={entityType}
                        loading={loading}
                        tableHeaders={getTableHeaders}
                    />
                </TableContainerMobile>
            </>
            {accountRecordsModal && (
                <AccountModalView
                    hasContext={true}
                    title={'История изменений счета'}
                    onClose={() => setAccountRecordsModal(false)}
                    fullScreen={false}
                >
                    <AccountRecordsView accountID={accountID!}/>
                </AccountModalView>
            )}
            {modal && (
                <Modal
                    title={initialValue ? 'Изменить счет' : 'Создать счет'}
                    onClose={() => {
                        setModal(false);
                        setInitialValue(null);
                    }}
                >
                    <AccountCreateEditView
                        initialValue={initialValue}
                        setModal={setModal}
                        entityType={entityType}
                        entityId={entityId}
                    />
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить счет"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить счет?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
            {supplierPaymentModal && (
                <Modal
                    title={'Провести оплату'}
                    onClose={() => setSupplierPaymentModal(false)}
                >
                    <BonusPaymentView
                        id={accountID}
                        entityID={entityId}
                        setModal={setSupplierPaymentModal}
                        paymentType="supplier"
                    />
                </Modal>
            )}
            {bonusPaymentModal && (
                <Modal
                    title={'Оплата бонуса'}
                    onClose={() => setBonusPaymentModal(false)}
                >
                    <BonusPaymentView
                        id={accountID}
                        entityID={entityId}
                        setModal={setBonusPaymentModal}
                        paymentType="bonus"
                    />
                </Modal>
            )}
        </PageView>
    );
});

export default AccountView;
