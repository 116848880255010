import {observer} from "mobx-react";
import {FilterModel} from "./sales.report.model";
import {useEffect} from "react";
import UsersStore from "../../users/users.store";
import ProductsStore from "../../products/products.store";
import BusinessUnitsStore from "../../business_units/business_units.store";
import FilterView from "../../../components/filters/filters.view";

interface SalesReportFilterViewProps {
    inModal?: boolean;
    setDates: (e: any) => void;
    handleFilterChange: (e: any, key: string) => void;
    graphView: boolean;
    pieView: boolean;
    filters: FilterModel;
}

const SalesReportFilterView = observer(({
                                            inModal,
                                            setDates,
                                            handleFilterChange,
                                            graphView,
                                            pieView,
                                            filters
                                        }: SalesReportFilterViewProps) => {
    const {setUsersFilter, users} = UsersStore;
    const {setProductsFilter, products} = ProductsStore;
    const {setBusinessUnitFilter, clearBusinessUnitFilter, businessUnits} = BusinessUnitsStore;
    const entityOptions = [
        {
            label: 'По офисам',
            value: 1
        },
        {
            label: 'По сотрудникам',
            value: 2
        },
        {
            label: 'По продуктам',
            value: 3
        },
    ]

    const periodOptions = [
        {
            label: 'День',
            value: 1
        },
        {
            label: 'Месяц',
            value: 2
        },
        {
            label: 'Год',
            value: 3
        },
    ]

    useEffect(() => {
        setBusinessUnitFilter({page: 1, page_limit: 9999, return_balance: false});
        clearBusinessUnitFilter();
        setUsersFilter({page: 1, page_limit: 9999});
        setProductsFilter({page: 1, page_limit: 999999})
    }, []);

    const businessUnitOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const usersOptions = users?.map(
        ({id, name, surname}) => ({label: `${surname} ${name}`, value: id})
    );

    const productOptions = products?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const filterInputs = [
        {
            fieldName: '',
            placeholder: '',
            type: 'range_picker',
            options: '',
        },
        !pieView && {
            fieldName: 'report_type',
            type: 'select',
            options: entityOptions,
        },
        graphView && {
            fieldName: 'period',
            type: 'select',
            options: periodOptions,
        },
        (graphView && filters.report_type == 1) && {

            fieldName: 'entity_id',
            type: 'select',
            options: businessUnitOptions,
            defaultValue: businessUnitOptions![0].value
        },
        (graphView && filters.report_type == 2) && {

            fieldName: 'entity_id',
            type: 'select',
            options: usersOptions,
            defaultValue: usersOptions![0].value
        },
        (graphView && filters.report_type == 3) && {

            fieldName: 'entity_id',
            type: 'select',
            options: productOptions,
            defaultValue: productOptions![0].value
        },
    ]

    return (
        <FilterView
            filterInputs={filterInputs}
            isInModal={inModal!}
            setDates={setDates}
            handleFilterChange={handleFilterChange}
            filters={filters}
        />
    );
});

export default SalesReportFilterView;