import {observer} from "mobx-react";
import {PayrollsModel} from "../payrolls.model";
import PayrollsCreateEditStore from "./payrolls.create.edit.store";
import PayrollsStore from "../payrolls.store";
import {Layout} from "../../../../styles/layout";
import {renderInputs} from "../../../../components/form/render.inputs";
import {useEffect, useState} from "react";
import {Button} from "../../../../styles/button";
import {isValid} from "../../../../utils/isValid";
import {Text} from "../../../../styles/text";
import {modalTextColor, primaryColor} from "../../../../utils/variables";
import {TextInput} from "../../../../styles/form";
import ModalView from "../../../../components/modal/modal.view";
import PayrollsCreateEditAllowancesView from "./payrolls.create.edit.allowances.view";
import PayrollsCreateEditDeductionsView from "./payrolls.create.edit.deductions.view";
import DictionaryStore from "../../../../utils/dictionary/dictionary.store";
import {CloseOutlined} from "@ant-design/icons";

interface PayrollsCreateEditViewProps {
    setModal: (isOpen: boolean) => void;
    initialValue: PayrollsModel | null;
}

const PayrollsCreateEditView = observer(({setModal, initialValue}: PayrollsCreateEditViewProps) => {
    const {
        form,
        isLoading,
        payrollsCreateEditReq,
        destroy,
        setField,
        employeeOptions,
        init,
        yearsOptions,
        userStore,
        deleteSelectedItem,
        paymentMethodOptions
    } = PayrollsCreateEditStore
    const {payrollsReq} = PayrollsStore;

    const [allowancesModal, setAllowancesModal] = useState(false);
    const [deductionsModal, setDeductionsModal] = useState(false);
    const {destroyModalDictionary, dictionaryListReq, getModalPlaceholders} = DictionaryStore;

    useEffect(() => {
        destroyModalDictionary();
        const controller = new AbortController();
        void dictionaryListReq('PAYROLL PAYMENTS', 'MODAL', controller.signal);
        void init(initialValue)
    }, [destroyModalDictionary, dictionaryListReq, destroy, init, initialValue]);

    const handleSubmit = async () => {
        const resp = await payrollsCreateEditReq(!!initialValue);
        if (resp) {
            setModal(false);
            destroy();
            await payrollsReq();
        }
    }

    const {
        employee_id,
        details,
        month,
        year,
        payment_method_id,
        account,
        financial_institution,
    } = form;

    const changeForm = (field: string, value: string | number) => {
        setField({...form, [field]: value})
    }

    const monthOptions = [
        {
            label: 'Январь',
            value: '01',
        },
        {
            label: 'Февраль',
            value: '02',
        },
        {
            label: 'Март',
            value: '03',
        },
        {
            label: 'Апрель',
            value: '04',
        },
        {
            label: 'Май',
            value: '05',
        },
        {
            label: 'Июнь',
            value: '06',
        },
        {
            label: 'Июль',
            value: '07',
        },
        {
            label: 'Август',
            value: '08',
        },
        {
            label: 'Сентябрь',
            value: '09',
        },
        {
            label: 'Октябрь',
            value: '10',
        },
        {
            label: 'Ноябрь',
            value: '11',
        },
        {
            label: 'Декабрь',
            value: '12',
        },
    ];

    const getSalaryAmount = (employeeID: number) => {
        let salary = 0;
        userStore.users?.forEach((user) => {
            if (user.id === employeeID) {
                salary = user.salary
            }
        })
        return salary;
    }

    const handleTaxAmount = (employeeID: number) => {
        const salaryAmount = getSalaryAmount(employeeID);
        const socialTax = salaryAmount * 0.01; // 1% social tax
        const incomeTax = (salaryAmount - socialTax) * 0.13; // 13% income tax
        return Number((socialTax + incomeTax).toFixed(2));
    }

    const inputs = [
        {
            field: employee_id,
            options: employeeOptions,
            fieldName: 'employee_id',
            placeholder: getModalPlaceholders('employee_id'),
            type: 'select',
            required: true
        },
        {
            field: month,
            options: monthOptions,
            fieldName: 'month',
            placeholder: getModalPlaceholders('month'),
            type: 'select',
            required: true,
            selectValueType: 'number',
        },
        {
            field: year,
            options: yearsOptions,
            fieldName: 'year',
            placeholder: getModalPlaceholders('year'),
            type: 'select',
            required: true
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: getModalPlaceholders('details'),
            type: 'text',
            required: false
        },
        {
            field: payment_method_id,
            options: paymentMethodOptions,
            fieldName: 'payment_method_id',
            placeholder: getModalPlaceholders('payment_method_id'),
            type: 'select',
            required: true
        },
        form.payment_method_id === 2 && {
            field: account,
            fieldName: 'account',
            placeholder: getModalPlaceholders('account'),
            type: 'text',
            required: false
        },
        form.payment_method_id === 2 && {
            field: financial_institution,
            fieldName: 'financial_institution',
            placeholder: getModalPlaceholders('financial_institution'),
            type: 'text',
            required: false
        },
    ];

    const handleCloseAllowancesModal = () => {
        setAllowancesModal(false);
    }

    const handleCloseDeductionsModal = () => {
        setDeductionsModal(false);
    }

    const handleDeleteItem = (itemID: number, itemType: string) => {
        deleteSelectedItem(itemID, itemType)
    }

    const handleTotalAllowances = () => {
        let amount = 0;
        form.allow_ded.forEach((all_ded) => {
            if (all_ded.type === 'ALLOWANCE') {
                amount += Number(all_ded.amount)
            }
        })
        return amount;
    }

    const handleTotalDeductions = () => {
        let amount = 0;
        form.allow_ded.forEach((all_ded) => {
            if (all_ded.type === 'DEDUCTION') {
                amount += Number(all_ded.amount)
            }
        })
        return amount;
    }

    const handleTotalAmount = () => {
        return Number((getSalaryAmount(form.employee_id) + handleTotalAllowances() - handleTotalDeductions() - handleTaxAmount(form.employee_id)).toFixed(2))
    }

    const renderSubmitText = initialValue ? 'Изменить' : 'Создать'

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setField)}
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize="35%" hAlign={"space-between"} vAlign={"center"}>
                        <Text color={modalTextColor} extent={'S'}>{getModalPlaceholders('salary')}</Text>
                    </Layout>
                    <Layout widthSize="65%" hAlign={"space-between"} vAlign={"center"}>
                        <TextInput
                            value={getSalaryAmount(form.employee_id)}
                            readOnly
                            onChange={(e) => {
                                changeForm('amount', +e.target.value)
                            }}
                            name='amount'
                        />
                    </Layout>
                </Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize='35%'>
                        <Text color={modalTextColor} extent={'S'}>{getModalPlaceholders('tax_amount')}</Text>
                    </Layout>
                    <Layout widthSize="65%" hAlign={"space-between"} vAlign={"center"}>
                        <TextInput
                            value={handleTaxAmount(form.employee_id)}
                            readOnly
                        />
                    </Layout>
                </Layout>
            </Layout>
            <Layout vertical between={10}>
                <Layout hAlign={"center"}>
                    <Text color={primaryColor} weight={'bold'}>{getModalPlaceholders('all_ded_title')}</Text>
                </Layout>
                {form.allow_ded.map((all_ded, index) => (
                    <Layout
                        key={index}
                        style={{
                            border: all_ded.type === 'ALLOWANCE' ? '2px #00A86B solid' : '2px grey solid',
                            borderRadius: '5px',
                            padding: '4px',
                        }}
                        hAlign={"space-between"}
                        vAlign={"center"}
                    >
                        <Layout widthSize={'85%'}>
                            <Text color={"#999"} extent={"S"}>{all_ded.name}</Text>
                        </Layout>
                        <Layout widthSize={'20%'} hAlign={"space-between"} vAlign={"center"} between={5}>
                            <TextInput
                                value={all_ded.amount}
                                readOnly
                                style={{
                                    width: '5%',
                                    textAlign: "center"
                                }}
                            />
                            <Layout
                                onClick={() => handleDeleteItem(all_ded.id, all_ded.type)}
                                style={{
                                    cursor: "pointer"
                                }}
                            >
                                <CloseOutlined />
                            </Layout>
                        </Layout>
                    </Layout>
                ))}
            </Layout>

            <Layout hAlign={"center"} vAlign={"center"} between={10}>
                <Button
                    id={'allowances-modal'}
                    onClick={() => setAllowancesModal(true)}
                    disabled={form.employee_id === 0}
                >
                    {getModalPlaceholders('add_allowance_button')}
                </Button>
                <Button
                    id={'deductions-modal'}
                    onClick={() => setDeductionsModal(true)}
                    disabled={form.employee_id === 0}
                >
                    {getModalPlaceholders('add_deduction_button')}
                </Button>
            </Layout>

            <Layout>
                <Layout hAlign={"space-between"} vAlign={"center"}>
                    <Layout widthSize={'65%'}>
                        <Text color={modalTextColor} extent='S'>{getModalPlaceholders('total_amount')}</Text>
                    </Layout>
                    <Layout widthSize={'35%'}>
                        <TextInput
                            readOnly
                            value={handleTotalAmount()}
                        />
                    </Layout>
                </Layout>
            </Layout>

            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    disabled={isValid(inputs)}
                >
                    {isLoading ? '' : renderSubmitText}
                </Button>
            </Layout>
            {allowancesModal && (
                <ModalView
                    title={'Выберите пособия'}
                    onClose={handleCloseAllowancesModal}
                >
                    <PayrollsCreateEditAllowancesView
                        employeeID={form.employee_id}
                        setModal={setAllowancesModal}
                    />
                </ModalView>
            )}
            {deductionsModal && (
                <ModalView
                    title={'Выберите удержание'}
                    onClose={handleCloseDeductionsModal}
                >
                    <PayrollsCreateEditDeductionsView
                        employeeID={form.employee_id}
                        setModal={setDeductionsModal}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default PayrollsCreateEditView;