import MainIcon from '../../assets/icons/house-solid.svg';
import StatisticsIcon from '../../assets/icons/statistics-icon.svg';
import BusinessUnitIcon from '../../assets/icons/business-unit-icon.svg';
import BusinessStocksIcon from '../../assets/icons/warehouse.svg';
import ProductIcon from '../../assets/icons/table-columns-solid.svg';
import Cash from '../../assets/icons/money-bill-solid.svg';
import RolesIcon from '../../assets/icons/people-group-solid.svg';
import UserIcon from '../../assets/icons/user.svg';
import DollarIcon from '../../assets/icons/dollar-icon.svg';
import {PermissionGroup} from '../../utils/permission';
import ReportsIcon from '../../assets/icons/reports-icon.svg';
import Settings from '../../assets/icons/settings.svg';
import BulletPoint from '../../assets/icons/bullet.svg';
import GetCash from '../../assets/icons/cash.png';
import DictionaryStore from "../../utils/dictionary/dictionary.store";
import {makeAutoObservable} from "mobx";

interface NavItemsModel {
    label: string;
    route?: string;
    icon?: string;
    permission?: string;
    hidden?: boolean;
    children?: any[];
}

const NavItemsInitialState: NavItemsModel[] = []

class LinksStore {
    public NavItems = NavItemsInitialState;
    public dictionary = DictionaryStore;

    constructor() {
        makeAutoObservable(this);
    }

    public NavItemsReq = async (signal: AbortSignal) => {
        await this.dictionary.sidebarDictionaryReq(signal);
        this.NavItems = [
            {
                label: this.dictionary.getSidebarLabel('MAIN'),
                route: '/',
                icon: MainIcon,
                hidden: false,
            },
            {
                label: this.dictionary.getSidebarLabel('TRANSACTIONS'),
                route: '/transactions',
                icon: StatisticsIcon,
                permission: PermissionGroup.TRANSACTIONS,
            },
            {
                label: this.dictionary.getSidebarLabel('CASHIER'),
                icon: Cash,
                permission: PermissionGroup.BUSINESS_SERVICES,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('SERVICES'),
                        route: '/cashier/services',
                        icon: BulletPoint,
                        permission: PermissionGroup.BUSINESS_SERVICES,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('STOCKS'),
                        route: '/cashier/goods',
                        icon: BulletPoint,
                        hidden: false,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('BUSINESS UNITS'),
                route: '/business_units',
                icon: BusinessUnitIcon,
                permission: PermissionGroup.BUSINESS_UNITS,
            },
            {
                label: this.dictionary.getSidebarLabel('PRODUCTS'),
                icon: ProductIcon,
                permission: PermissionGroup.PRODUCTS,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('PRODUCTS'),
                        route: '/products/list',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCTS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('BRANDS'),
                        route: '/products/brands',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCT_BRANDS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('PRODUCT CATEGORIES'),
                        route: '/products/categories',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCT_CATEGORIES,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('SUPPLIERS'),
                        route: '/products/suppliers',
                        icon: BulletPoint,
                        permission: PermissionGroup.SUPPLIERS,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('WAREHOUSE'),
                icon: BusinessStocksIcon,
                permission: PermissionGroup.BUSINESS_STOCKS,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('BUSINESS STOCKS'),
                        route: '/business_stock/list',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCTS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('TRANSFERS'),
                        route: '/business_stock/transfers',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCT_BRANDS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('GOODS_MOVEMENTS'),
                        route: '/business_stock/movements',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCTS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('PURCHASE_BILLS'),
                        route: '/business_stock/bills',
                        icon: BulletPoint,
                        permission: PermissionGroup.BUSINESS_STOCKS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('PURCHASE_BILLS_OPERATIONS'),
                        route: '/business_stock/bills_operations',
                        icon: BulletPoint,
                        permission: PermissionGroup.BUSINESS_STOCKS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('SHIPMENTS'),
                        route: '/business_stock/shipments',
                        icon: BulletPoint,
                        permission: PermissionGroup.PRODUCTS,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('CLIENTS'),
                route: '/clients',
                icon: UserIcon,
                permission: PermissionGroup.USERS,
            },
            {
                label: this.dictionary.getSidebarLabel('REPORTS'),
                icon: ReportsIcon,
                permission: PermissionGroup.REPORTS,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('SALES REPORTS'),
                        route: '/reports/sales',
                        icon: BulletPoint,
                        permission: PermissionGroup.REPORTS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('FINANCIAL REPORTS'),
                        route: '/reports/financial',
                        icon: BulletPoint,
                        permission: PermissionGroup.REPORTS,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('STAFF'),
                permission: PermissionGroup.ROLES,
                icon: RolesIcon,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('ROLES'),
                        route: '/roles',
                        icon: BulletPoint,
                        permission: PermissionGroup.ROLES,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('USERS'),
                        route: '/users',
                        icon: BulletPoint,
                        permission: PermissionGroup.USERS,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('EXPENSES'),
                permission: PermissionGroup.EXPENSES,
                icon: DollarIcon,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('EXPENSE OPERATIONS'),
                        route: '/expenses/operations',
                        icon: BulletPoint,
                        permission: PermissionGroup.EXPENSES,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('PLANNED EXPENSES'),
                        route: '/expenses/list',
                        icon: BulletPoint,
                        permission: PermissionGroup.EXPENSES,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('EXPENSE CATEGORIES'),
                        route: '/expenses/categories',
                        icon: BulletPoint,
                        permission: PermissionGroup.EXPENSES,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('PAYROLLS'),
                permission: PermissionGroup.PAYROLLS,
                icon: GetCash,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('PAYROLL PAYMENTS'),
                        route: '/payrolls/payments',
                        icon: BulletPoint,
                        permission: PermissionGroup.PAYROLLS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('EMPLOYEE ALLOWANCES'),
                        route: '/payrolls/employee/allowances',
                        icon: BulletPoint,
                        permission: PermissionGroup.PAYROLLS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('EMPLOYEE DEDUCTIONS'),
                        route: '/payrolls/employee/deductions',
                        icon: BulletPoint,
                        permission: PermissionGroup.PAYROLLS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('ALLOWANCES'),
                        route: '/payrolls/allowances',
                        icon: BulletPoint,
                        permission: PermissionGroup.PAYROLLS,
                    },
                    {
                        label: this.dictionary.getSidebarLabel('DEDUCTIONS'),
                        route: '/payrolls/deductions',
                        icon: BulletPoint,
                        permission: PermissionGroup.PAYROLLS,
                    },
                ],
            },
            {
                label: this.dictionary.getSidebarLabel('SETTINGS'),
                permission: PermissionGroup.STATUSES,
                icon: Settings,
                children: [
                    {
                        label: this.dictionary.getSidebarLabel('STATUSES'),
                        route: '/settings/statuses',
                        icon: BulletPoint,
                        permission: PermissionGroup.STATUSES,
                    },
                ],
            },
        ];
    }
}

export default new LinksStore();
