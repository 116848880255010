import styled from 'styled-components';
import { getKeyframes } from '../utils/getKeyframes';
import { darkBackground, miniNavbarSize, navbarMinSize, navbarSize } from '../utils/variables';
import { Image } from './image';
import { Text } from './text';
import { Col } from 'antd';
import {media} from "../utils/variables";
import {Burger} from "../components/navbar/burger.style";

interface NavItemProps {
    isActive?: boolean;
    isNavOpen?: boolean;
}

export const NavList = styled.ul<{
    burgerOpen?: boolean
}>`
    list-style: none;
    padding: 0;
    margin: 0;

  ${media.mobile} {
    flex-flow: column nowrap;
    background-color: ${darkBackground};
    position: fixed;
    transform: ${({burgerOpen}) => burgerOpen ? `translateX(0)` : `translateX(-100%)`};
    top: 0;
    left: 0;
    height: 100vh;
    width: 75%;
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    overflow: auto;
    z-index: 19;
  }

  ${media.tablet} {
    flex-flow: column nowrap;
    background-color: ${darkBackground};
    position: fixed;
    transform: ${({burgerOpen}) => burgerOpen ? `translateX(0)` : `translateX(-100%)`};
    top: 0;
    left: 0;
    height: 100vh;
    width: 280px;
    padding-top: 50px;
    transition: transform 0.3s ease-in-out;
    overflow: auto;  
    z-index: 4;
  }
`;

export const NavItem = styled.li<NavItemProps>`
    padding: 16px 20px;
    transition: 300ms;
    &:hover {
        opacity: .8;
        background-color: rgba(0,0,0,.1);
    }
    ${({ isActive }) => isActive
        ? `
            opacity: 1;
            background-color: rgba(0,0,0,.3);
            &:hover {
                opacity: 1;
                background-color: rgba(0,0,0,.3);
            }
        `
        : `
            opacity: .5;
        `
    }

    ${media.mobile} {
      //padding-top: 8px;
    }

    ${media.tablet} {
      //padding-top: 8px;
    }

`;

export const Arrow = styled(Image)`
    position: absolute;
    width: 24px;
    right: 0;
    bottom: 16px;
    border: 1px solid #666;
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 250ms;
    transition: unset;
    ${({ rotate }) => rotate
        ? `
            border-left: none;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        `
        : `
            border-right: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        `
    }
    :hover {
        border-color: #fff;
    }
  
  ${media.mobile} {
    display: none;
  }

  ${media.tablet} {
    display: none;
  }
`;

export const Nav = styled.nav<{
    collapsed?: boolean;
    burgerOpen?: boolean;
}>`
    width: ${({ collapsed }) => collapsed ? navbarSize : miniNavbarSize};
    min-width: ${({ collapsed }) => collapsed ? navbarMinSize : miniNavbarSize};
    height: 100vh;
    background-color: ${darkBackground};
    position: fixed;
    left: 0;
    top: 0;
    transition: 250ms;
    position: relative;
  
    overflow-x: hidden;
    overflow-y: auto;
    //max-height: fit-content;

  ${({ collapsed }) => !collapsed
          ? `
            ${NavItem} {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            ${Text} {
                display: none;
            }
        `
          : ``
  };

  > * {
    opacity: 0;
    animation: fadeIn 500ms forwards;
  }
  
  ${media.mobile} {
    display: ${({burgerOpen}) => burgerOpen ? `flex` : `none`};
    transform: ${({burgerOpen}) => burgerOpen ? 'translateX(-100%)' : 'translateX(0)'};
  }

  ${media.tablet} {
    display: ${({burgerOpen}) => burgerOpen ? `flex` : `none`};
    transform: ${({burgerOpen}) => burgerOpen ? 'translateX(-100%)' : 'translateX(0)'};
  }

  ${getKeyframes('fadeIn')}
  
`;

export const NavChildItem = styled.li<NavItemProps>`
    list-style-type: none; 
    color: white;
    padding: 10px;
    &:hover {
      ${({ isActive }) => isActive !== false ? `` : `
          opacity: 0.8;
          background-color: rgba(0,0,0,.1);`}
    };
  
    ${({ isActive }) => isActive
    ? `
            opacity: 1;
            background-color: rgba(0,0,0,.3);
            &:hover {
                opacity: 1;
                background-color: rgba(0,0,0,.3);
            }
        `
    : `
            opacity: 0.5;
        `
    };

    ${({ isNavOpen }) => isNavOpen
    ? `
            padding-left: 47px !important;
        `
    : `
            padding-left: 20px !important;
        `
    };
`;

export const StyledBadge = styled(Col)<NavItemProps>`
    &:hover {
        .ant-collapse-header {
          opacity: 0.8;
          background-color: rgba(0, 0, 0, .1);
        }
    }
  
    .ant-collapse {
        border: none;
    }
    .ant-collapse-item:last-child {
        border-radius: 0 !important;
    }
    .ant-collapse-content {
        border-top: none !important;
        border-bottom: none !important;
        border-radius: 0 !important;
    }
    .ant-collapse-item {
        border-bottom: none;
    }
    .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
        background-color: #272c3b;
        font-size: 14px;
        padding: 0;
    }
  
    .ant-collapse-header {
      ${({ isActive }) => isActive
              ? `
            opacity: 1;
            background-color: rgba(0,0,0,.3);
            &:hover {
                opacity: 1;
                background-color: rgba(0,0,0,.3);
            }
        `
              : `
            opacity: 0.5;
        `
      }
    }
  
    .ant-collapse-header-text {
        color: white;
        font-size: 14px;
        margin-left: 32px;

        ${({ isNavOpen }) => !isNavOpen
              ? `opacity: 0;`
              : ``}
    }
    .ant-collapse-arrow {
        color: white !important;
    }
    .ant-collapse-expand-icon {
        padding-inline-start: 155px;
        position: absolute;
    }   
`;
export const UL = styled.li`
  list-style-type: none; 
  color: white;
  padding-left: 70px !important;
  padding: 10px;
  &:hover {
    opacity: .8;
    background-color: rgba(0,0,0,.1);
}
`;