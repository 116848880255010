import {observer} from "mobx-react";
import {Burger, BurgerBar, BurgerContainer, BurgerXMark} from "./burger.style";
import {useEffect, useRef, useState} from "react";
import NavbarListView from "./navbar.list";

const BurgerView = observer(() => {
    const [open, setOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickScreen = (e: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickScreen);
        return () => {
            document.removeEventListener('mousedown', handleClickScreen);
        };
    }, []);

    return (
        <BurgerContainer ref={menuRef}>
            <Burger
                open={open}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                }}
            >
                <BurgerBar open={open}/>
                <BurgerBar open={open}/>
                <BurgerBar open={open}/>
            </Burger>
            <NavbarListView
                burgerOpen={open}
                setOpen={setOpen}
            />
            {open && <div style={{position: 'fixed', top: 0, left: 0 ,width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 10 /* Ниже sidebar, но выше контента */}} onClick={() => setOpen(false)} />}
        </BurgerContainer>
    );
});

export default BurgerView;