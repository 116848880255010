import {useEffect, useState} from "react";
import {Headline} from "../../styles/headline"
import {primaryColor} from "../../utils/variables";
import {observer} from "mobx-react";
import {
    ContextMenu,
    ContextMenuItem,
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import AppStore from "../../app.store";
import {Page} from "../../styles/page";
import RolesStore from "./roles.store"
import Modal from "../../components/modal/modal.view";
import {AddButton, Search, SearchLayout, TableHeader} from "../../styles/table-header";
import {Image} from "../../styles/image";
import SearchIcon from "../../assets/icons/search.svg";
import {RoleCreateModel, RoleModel} from "./roles.model";
import RolesCreateEditView from "./roles.modal/roles.create.edit.view";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import { Filters } from "../../styles/filters";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {Button, FilterButton} from "../../styles/button";
import {Layout} from "../../styles/layout";
import TablePaginationView from "../../utils/table.pagination.view";
import RolesTableMobileView from "./roles.mobile.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";

const RolesView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        roles,
        rolesFilter,
        rolesInit,
        setRolesFilter,
        deleteRole,
        loading,
        clearRolesFilter
    } = RolesStore;

    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<RoleCreateModel | null>(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({ isModalOpen: false });
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('ROLES', 'TABLE HEADERS', controller.signal);
        rolesInit(controller.signal);

        return () => controller.abort();
    }, [destroyDictionary, dictionaryListReq, rolesInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {
                options,
                selectedIndex
            }
        } = e;
        if (objectKey === 'page' && index) {
            setRolesFilter({page: index + 1});
            return;
        }
        setRolesFilter({[objectKey]: options[selectedIndex].value})
    }

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && rolesFilter.search !== '') {
            setRolesFilter({search: ''});
        }
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setRolesFilter({search: e.target.value});
        }
    }

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector('.context-menu-focused');
        if (e.target.classList.contains('context-menu-focused')) {
            e.target.classList.remove('context-menu-focused');
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add('context-menu-focused');
        }
    }

    const handleEditClick = (role: RoleModel) => {
        setModal(true);

        const roleCreateModel: RoleCreateModel = {
            id: role.id,
            name: role.name,
            description: role.description,
            default_status_id: role.default_status_id,
            accesses: []
        }

        setInitialValue(roleCreateModel);
    }

    const handleDelete = async () => {
        await deleteRole(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    }

    const prevPage = () => {
        if (rolesFilter.page > 1) {
            setRolesFilter({page: rolesFilter.page - 1});
        }
    }

    const nextPage = () => {
        if (rolesFilter.page < rolesFilter.pages) {
            setRolesFilter({page: rolesFilter.page + 1});
        }
    }

    const handleClearFilter = () => {
        clearRolesFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    }

    const pageLimits = [25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
            <>
                <Headline color={primaryColor}>{getPageTitle('ROLES')}</Headline>
                <TableHeader>
                    <Filters hAlign="space-between" wrapped between={10}>
                        <SearchLayout>
                            <Search type="search" id="search" placeholder="Поиск по названию"
                                    onKeyDown={e => handleKeyDown(e)}
                                    onChange={e => cancelSearch(e)}>
                            </Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                    </Filters>
                    <FilterButton
                        onClick={() => setFilterModal(true)}
                    >
                        Фильтры
                    </FilterButton>
                    <AddButton type="button" id="add-role" onClick={() => setModal(true)}>Создать</AddButton>
                </TableHeader>
                <TableContainer isMacOS={AppStore.isMacOS}>
                <Table>
                    <TableHead>
                    <TRow tableHead>
                        <THeadCell>{getTableHeaders('name')}</THeadCell>
                        <THeadCell>{getTableHeaders('description')}</THeadCell>
                        <THeadCell></THeadCell>
                    </TRow>
                    </TableHead>
                    {!loading ? (
                    <TableBody>
                {roles!.length ? roles?.map((role, index) => <TRow key={index} menu>
                    <TCell>{role.name}</TCell>
                    <TCell>{role.description}</TCell>
                    <TCell relative>
                        <Image src={ContextMenuIcon} height="18px" onClick={handleContext} />
                        <ContextMenu anchorEl={anchorEl}>
                            <ContextMenuItem onClick={() => handleEditClick(role)}>
                                Изменить
                            </ContextMenuItem>
                            <ContextMenuItem
                                onClick={() => setConfirmDeleteModal({
                                    isModalOpen: true,
                                    id: role.id,
                                    name: role.name
                                })}
                                    >
                                    Удалить
                            </ContextMenuItem>
                        </ContextMenu>
                    </TCell>
                </TRow>) : <NoResultTitle colspan={3} /> }
                </TableBody>
                    ) : (
                        <TableSkeleton columns={3}/>
                    )}
            </Table>
            </TableContainer>
            <TableContainerMobile>
                <RolesTableMobileView
                    loading={loading}
                    roles={roles}
                    handleEditClick={handleEditClick}
                    setConfirmDeleteModal={setConfirmDeleteModal}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={rolesFilter.pages}
                currentPage={rolesFilter.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            </>
            {filterModal && (
                <Modal
                    title={"Фильтры"}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <Search type="search" id="search" placeholder="Поиск по названию"
                                    onKeyDown={e => handleKeyDown(e)}
                                    onChange={e => cancelSearch(e)}>
                            </Search>
                            <Image src={SearchIcon} height="16px"/>
                        </SearchLayout>
                        <Layout hAlign={"space-between"} between={10}>
                            <Button
                                extent={"L"}
                                id={"clear-filter-button"}
                                onClick={handleClearFilter}
                                background={"#333"}
                                color={"red"}
                            > Очистить </Button>
                            <Button
                                extent={"L"}
                                id={"confirm-filter-button"}
                                onClick={handleConfirmFilter}
                            > Потвердить </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
            {modal && (
                <Modal title={initialValue ? 'Изменение роли' : 'Создание роли'}
                       onClose={() => {
                           setModal(false);
                           setInitialValue(null);
                       }}>
                    <RolesCreateEditView initialValue={initialValue} setModal={setModal}></RolesCreateEditView>
                </Modal>
            )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить пользователя"
                    onClose={() => {
                        setConfirmDeleteModal({ isModalOpen: false });
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить роли ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={loading}
                    />
                </Modal>
            )}
        </Page>
    );
});

export default RolesView