import {observer} from "mobx-react";
import {Page} from "../../styles/page";
import AppStore from "../../app.store";
import ProductCategoriesStore from "./product_categories.store";
import {useEffect, useState} from "react";
import ConfirmDelete, {DeleteItem} from "../../components/confirm/confirm.delete";
import {ProductCategoryModel} from "./product_categories.model";
import {Headline} from "../../styles/headline";
import {AddButton, Search, SearchLayout, TableHeader} from "../../styles/table-header";
import {primaryColor} from "../../utils/variables";
import {
    ContextMenu,
    ContextMenuItem, Table,
    TableBody,
    TableContainer, TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../styles/table";
import NoResultTitle from "../../components/no_result_title/no_result_title.view";
import {Badge} from "../../styles/badge";
import {Image} from "../../styles/image";
import ContextMenuIcon from "../../assets/icons/context-menu.svg";
import Modal from "../../components/modal/modal.view";
import ProductCategoriesCreateEditView from "./product_categories.modal/product_categories.create.edit.view";
import {Filters} from "../../styles/filters";
import SearchIcon from "../../assets/icons/search.svg";
import TableSkeleton from "../../utils/skeleton/table.skeleton";
import {Button, FilterButton} from "../../styles/button";
import {Layout} from "../../styles/layout";
import TablePaginationView from "../../utils/table.pagination.view";
import ProductCategoriesTableMobileView from "./product_categories.mobile.view";
import DictionaryStore from "../../utils/dictionary/dictionary.store";

const ProductCategoriesView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        productCategories,
        categoriesFilter,
        productCategoriesPageInit,
        deleteCategory,
        isLoading,
        setCategoriesFilter,
        clearFilter,
    } = ProductCategoriesStore;

    const [modal, setModal] = useState(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
        isModalOpen: false,
    });
    const [initialValue, setInitialValue] = useState<ProductCategoryModel | null>(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController;
        dictionaryListReq('PRODUCT CATEGORIES', 'TABLE HEADERS', controller.signal);
        void productCategoriesPageInit(controller.signal);

        return () => {
            controller.abort();
        };
    }, [destroyDictionary, dictionaryListReq, productCategoriesPageInit]);

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === "page" && index !== undefined) {
            setCategoriesFilter({page: index + 1});
            return;
        }
        setCategoriesFilter({[objectKey]: options[selectedIndex].value});
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && categoriesFilter.search !== '') {
            setCategoriesFilter({search: ''});
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setCategoriesFilter({search: e.target.value});
        }
    };

    const handleContext = (e: any) => {
        e.stopPropagation();
        document.querySelector(".context-menu-focused");
        if (e.target.classList.contains("context-menu-focused")) {
            e.target.classList.remove("context-menu-focused");
            setAnchorEl(null);
        } else {
            setAnchorEl(e.currentTarget);
            e.target.classList.add("context-menu-focused");
        }
    };

    const handleEditClick = (category: ProductCategoryModel) => {
        setModal(true);
        setInitialValue(category);
    };

    const handleDelete = async () => {
        await deleteCategory(confirmDeleteModal.id!);
        setConfirmDeleteModal({isModalOpen: false});
    };

    const prevPage = () => {
        if (categoriesFilter.page > 1) {
            setCategoriesFilter({page: categoriesFilter.page - 1});
        }
    };

    const nextPage = () => {
        if (categoriesFilter.page < categoriesFilter.pages) {
            setCategoriesFilter({page: categoriesFilter.page + 1});
        }
    };

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    }

    const handleConfirmFilter = () => {
        setFilterModal(false);
    }

    const pageLimits = [5, 10,  25, 100, 500];

    return (
        <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
                <>
                    <Headline color={primaryColor}>{getPageTitle('PRODUCT CATEGORIES')}</Headline>
                    <TableHeader>
                        <Filters hAlign="space-between" wrapped between={10}>
                            <SearchLayout>
                                <Search
                                    type="search"
                                    id="search"
                                    placeholder="Поиск по названию"
                                    onKeyDown={e => handleKeyDown(e)}
                                    onChange={e => cancelSearch(e)}>
                                </Search>
                                <Image src={SearchIcon} height="16px"/>
                            </SearchLayout>
                        </Filters>
                        <FilterButton
                            id={'categories-filter-button'}
                            onClick={() => setFilterModal(true)}
                        >
                            Фильтры
                        </FilterButton>
                        <AddButton type="button" id="add-category" onClick={() => setModal(true)}>
                            Создать
                        </AddButton>
                    </TableHeader>
                    <TableContainer isMacOS={AppStore.isMacOS}>
                        <Table>
                            <TableHead>
                                <TRow tableHead>
                                    <THeadCell>{getTableHeaders('name')}</THeadCell>
                                    <THeadCell>{getTableHeaders('description')}</THeadCell>
                                    <THeadCell>{getTableHeaders('active')}</THeadCell>
                                    <THeadCell>{getTableHeaders('formatted_date')}</THeadCell>
                                    <THeadCell></THeadCell>
                                </TRow>
                            </TableHead>
                            {isLoading ? (<TableSkeleton columns={6}/>) : (
                            <TableBody>
                                {productCategories!.length ? (
                                    productCategories?.map((category, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{category.name}</TCell>
                                            <TCell>{category.description}</TCell>
                                            <TCell>
                                                <Badge online={category.active}>
                                                    {category.active ? "Активный" : "Неактивный"}
                                                </Badge>
                                            </TCell>
                                            <TCell>{category.formatted_date}</TCell>
                                            <TCell relative>
                                                <Image
                                                    src={ContextMenuIcon}
                                                    height="18px"
                                                    onClick={handleContext}
                                                />
                                                <ContextMenu anchorEl={anchorEl}>
                                                    <ContextMenuItem onClick={() => handleEditClick(category)}>
                                                        Изменить
                                                    </ContextMenuItem>
                                                    <ContextMenuItem
                                                        onClick={() =>
                                                            setConfirmDeleteModal({
                                                                isModalOpen: true,
                                                                id: category.id,
                                                                name: category.name,
                                                            })
                                                        }
                                                    >
                                                        Удалить
                                                    </ContextMenuItem>
                                                </ContextMenu>
                                            </TCell>
                                        </TRow>
                                    ))
                                ) : (
                                    <NoResultTitle colspan={6}/>
                                )}
                            </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TableContainerMobile>
                        <ProductCategoriesTableMobileView
                            productCategories={productCategories}
                            loading={isLoading}
                            handleEditClick={handleEditClick}
                            setConfirmDeleteModal={setConfirmDeleteModal}
                            tableHeaders={getTableHeaders}
                        />
                    </TableContainerMobile>
                    <TablePaginationView
                        prevPage={prevPage}
                        nextPage={nextPage}
                        pages={categoriesFilter.pages}
                        currentPage={categoriesFilter.page}
                        pageLimits={pageLimits}
                        handleFilterChange={handleFilterChange}
                    />
                </>
            {filterModal && (
                <Modal
                    title={"Фильтры"}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <SearchLayout>
                            <Search
                                type="search"
                                id="search"
                                placeholder="Поиск по названию"
                                onKeyDown={(e) => handleKeyDown(e)}
                                onChange={(e) => cancelSearch(e)}
                            ></Search>
                            <Image src={SearchIcon} height="16px" />
                        </SearchLayout>
                        <Layout hAlign={"space-between"} between={10}>
                            <Button
                                extent={"L"}
                                id={"clear-filter-button"}
                                onClick={handleClearFilter}
                                background={"#333"}
                                color={"red"}
                            > Очистить </Button>
                            <Button
                                extent={"L"}
                                id={"confirm-filter-button"}
                                onClick={handleConfirmFilter}
                            > Потвердить </Button>
                        </Layout>
                    </Layout>
                </Modal>
            )}
        {modal && (
            <Modal
                title={
                    initialValue ? "Изменить категорию" : "Создать категорию"
                }
                onClose={() => {
                    setModal(false);
                    setInitialValue(null);
                }}>
                <ProductCategoriesCreateEditView
                    initialValue={initialValue}
                    setModal={setModal}
                />
            </Modal>
        )}
            {confirmDeleteModal.isModalOpen && (
                <Modal
                    title="Удалить категорию"
                    onClose={() => {
                        setConfirmDeleteModal({isModalOpen: false});
                    }}
                >
                    <ConfirmDelete
                        warningText={`Вы уверены, что хотите удалить категорию? ${confirmDeleteModal.name}?`}
                        setModal={setConfirmDeleteModal}
                        callback={handleDelete}
                        isLoading={isLoading}
                    />
                </Modal>
            )}
        </Page>
    )
});

export default ProductCategoriesView;