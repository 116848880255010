import {observer} from "mobx-react";
import {BusinessUnitsTransferFundsModel} from "../business_units.transfer_funds.model";
import BusinessUnitsTransferFundsCreateStore from "./business_units.transfer_funds.create.store";
import {Layout} from "../../../styles/layout";
import BusinessUnitsStore from "../../business_units/business_units.store";
import {useEffect} from "react";
import BusinessUnitsTransferFundsStore from "../business_units.transfer_funds.store";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";

interface TransferFundsModalViewProps {
    setModal: (isOpen: boolean) => void;
}

const BusinessUnitsTransferFundsCreateView = observer(({setModal}: TransferFundsModalViewProps) => {
    const {
        form,
        setForm,
        loading,
        transferFundsCreateInit,
        transferFundsCreateReq,
        destroy
    } = BusinessUnitsTransferFundsCreateStore;
    const {getTransferFundsReq} = BusinessUnitsTransferFundsStore
    const {type_id, from_bu_id, to_bu_id, total_amount, details} = form;
    const {businessUnits, setBusinessUnitFilter} = BusinessUnitsStore;

    useEffect(() => {
        destroy();
        transferFundsCreateInit()
        setBusinessUnitFilter({page: 1, page_limit: 100000})
    }, [transferFundsCreateInit, setBusinessUnitFilter]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    );

    const typeIdOptions = [{label: "Перевод между офисами", value: "1"}, {label: "Вывод средств из бизнеса", value: "2"}, {label: "Внесение средств в бизнес извне", value: "3"}]

    const handleSubmit = async () => {
        const response = await transferFundsCreateReq();

        if (response) {
            destroy();
            setModal(false);
            await getTransferFundsReq()
        }
    }

    const inputs = [
        {
            field: type_id,
            options: typeIdOptions,
            fieldName: 'type_id',
            placeholder: 'Тип операции',
            type: 'select',
            required: true
        },
        {
            field: from_bu_id,
            options: businessUnitsOptions,
            fieldName: 'from_bu_id',
            placeholder: type_id === 1 ? 'Отправитель' : 'Подразделение',
            type: 'select',
            required: true
        },
        type_id === 1 && {
            field: to_bu_id,
            options: businessUnitsOptions,
            fieldName: 'to_bu_id',
            placeholder: 'Получатель',
            type: 'select',
            required: true
        },
        {
            field: total_amount,
            fieldName: 'total_amount',
            placeholder: 'Сумма',
            type: 'text',
            required: true
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: 'Описание',
            type: 'text',
            required: false
        },
    ]

    const renderSubmitText = 'Подтвердить';

    const handleButtonValidity = () => {
        return isValid([inputs])
    }

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={loading}
                    onClick={handleSubmit}
                    disabled={handleButtonValidity()}
                >{loading ? '' : renderSubmitText}</Button>
            </Layout>
        </Layout>
    )
});

export default BusinessUnitsTransferFundsCreateView