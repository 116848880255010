import { createGlobalStyle } from "styled-components";
import { textColorDark, textColorLight } from "../utils/variables";

export const GlobalStyles = createGlobalStyle`
    * {
        outline: none;
        box-sizing: border-box;
    }
    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
        overflow-x: hidden; /* Убираем горизонтальный скролл */
    }
    body {
        background-color: ${textColorLight};
        font-family: Open-Sans, Helvetica, Sans-Serif;
        color: ${textColorDark};
    }
    a {
        text-decoration: none;
    }

    #root {
        min-height: 100vh; /* Используем динамическую высоту экрана */
        display: flex;
        flex-direction: column;
    }
`;
