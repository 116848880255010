import { Headline } from '../../styles/headline';
import { primaryColor } from '../../utils/variables';
import { observer } from 'mobx-react';
import { TableContainer, TableContainerMobile } from '../../styles/table';
import AppStore from '../../app.store';
import { Page } from '../../styles/page';
import UsersStore from './users.store';
import RolesStore from '../roles/roles.store';
import { useEffect, useState } from 'react';
import { AddButton, TableHeader } from '../../styles/table-header';
import BusinessUnitsStore from '../business_units/business_units.store';
import Modal from '../../components/modal/modal.view';
import AccountModal from '../../components/modal/account.modal.view';
import UsersCreateEditView from './users.modal/users.create.edit.view';
import { Layout } from '../../styles/layout';
import { UsersModel } from './users.model';
import ConfirmDelete, {
  DeleteItem,
} from '../../components/confirm/confirm.delete';
import { Filters } from '../../styles/filters';
import AccountView from '../accounts/account.view';
import { Button, FilterButton } from '../../styles/button';
import UsersFiltersView from './users.filters.view';
import UsersTableMobileView from './users.table.mobile.view';
import UsersTableDesktopView from './users.table.desktop.view';
import TablePaginationView from '../../utils/table.pagination.view';
import DictionaryStore from "../../utils/dictionary/dictionary.store";

const UsersView = observer(() => {
  const { isNavOpen, devices } = AppStore;
  const {
    users,
    usersFilter,
    usersPageInit,
    setUsersFilter,
    deleteUser,
    loading,
    clearFilter,
  } = UsersStore;
  const { roles, clearRolesFilter, setRolesFilter, rolesInit } = RolesStore;
  const { businessUnits, clearBusinessUnitFilter, setBusinessUnitFilter } = BusinessUnitsStore;
  const [modal, setModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<DeleteItem>({
    isModalOpen: false,
  });
  const [initialValue, setInitialValue] = useState<UsersModel | null>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [accountModal, setAccountModal] = useState(false);
  const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

  useEffect(() => {
    destroyDictionary();
    const controller = new AbortController();
    dictionaryListReq('USERS', 'TABLE HEADERS', controller.signal);
    usersPageInit(controller.signal);
    setBusinessUnitFilter({page: 1, page_limit: 100000, return_balance: false});
    clearBusinessUnitFilter();
    setRolesFilter({page: 1, page_limit: 100000});
    clearRolesFilter();

    return () => {
      controller.abort();
    };
  }, [destroyDictionary, dictionaryListReq, setBusinessUnitFilter, rolesInit, usersPageInit]);

  const handleFilterChange = (e: any, objectKey: string, index?: number) => {
    const {
      target: { options, selectedIndex },
    } = e;
    if (objectKey === 'page' && index !== undefined) {
      setUsersFilter({ page: index + 1 });
      return;
    }
    setUsersFilter({ [objectKey]: options[selectedIndex].value });
  };

  const cancelSearch = (e: any) => {
    if (e.target.value === '' && usersFilter.search !== '') {
      setUsersFilter({ search: '' });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setUsersFilter({ search: e.target.value });
    }
  };

  const handleContext = (e: any) => {
    e.stopPropagation();
    document.querySelector('.context-menu-focused');
    if (e.target.classList.contains('context-menu-focused')) {
      e.target.classList.remove('context-menu-focused');
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
      e.target.classList.add('context-menu-focused');
    }
  };

  const handleEditClick = (user: UsersModel) => {
    setModal(true);
    setInitialValue(user);
  };

  const handleAccountClick = (user: UsersModel) => {
    setAccountModal(true);
    setInitialValue(user);
  };

  const handleDelete = async () => {
    await deleteUser(confirmDeleteModal.id!);
    setConfirmDeleteModal({ isModalOpen: false });
  };

  const prevPage = () => {
    if (usersFilter.page > 1) {
      setUsersFilter({ page: usersFilter.page - 1 });
    }
  };

  const nextPage = () => {
    if (usersFilter.page < usersFilter.pages) {
      setUsersFilter({ page: usersFilter.page + 1 });
    }
  };

  const handleClearFilter = () => {
    clearFilter();
    setFilterModal(false);
  };

  const handleConfirmFilter = (e: any) => {
    setFilterModal(false);
  };

  const handleCloseAccountsModal = () => {
    void dictionaryListReq('USERS', 'TABLE HEADERS');
    setAccountModal(false)
  }

  const pageLimits = [5, 10, 25, 100, 500];

  return (
    <Page isNavOpen={isNavOpen} vertical fadeIn between={20}>
      <>
        <Headline color={primaryColor}>{getPageTitle('USERS')}</Headline>
        <TableHeader>
          <Filters hAlign="space-between" wrapped between={10}>
            <UsersFiltersView
              handleKeyDown={handleKeyDown}
              handleFilterChange={handleFilterChange}
              cancelSearch={cancelSearch}
              roles={roles}
              businessUnits={businessUnits}
              inModal={false}
            />
          </Filters>
          <FilterButton
            id={'user-filters'}
            onClick={() => setFilterModal(true)}
          >
            Фильтры
          </FilterButton>
          <AddButton type="button" id="add-user" onClick={() => setModal(true)}>
            Создать
          </AddButton>
        </TableHeader>
        <TableContainer isMacOS={AppStore.isMacOS}>
          <UsersTableDesktopView
            loading={loading}
            users={users!}
            handleAccountClick={handleAccountClick}
            handleEditClick={handleEditClick}
            setConfirmDeleteModal={setConfirmDeleteModal}
            handleContext={handleContext}
            anchorEl={anchorEl}
            tableHeaders={getTableHeaders}
          />
        </TableContainer>
        <TableContainerMobile>
          <UsersTableMobileView
            loading={loading}
            users={users!}
            handleAccountClick={handleAccountClick}
            handleEditClick={handleEditClick}
            setConfirmDeleteModal={setConfirmDeleteModal}
            tableHeaders={getTableHeaders}
          />
        </TableContainerMobile>
        <TablePaginationView
          prevPage={prevPage}
          nextPage={nextPage}
          pages={usersFilter.pages}
          currentPage={usersFilter.page}
          pageLimits={pageLimits}
          handleFilterChange={handleFilterChange}
        />
      </>
      {accountModal && (
        <AccountModal
          title={'Счета'}
          onClose={handleCloseAccountsModal}
          hasContext={true}
        >
          <AccountView entityType={'user'} entityId={`${initialValue?.id}`} />
        </AccountModal>
      )}
      {filterModal && (
        <Modal
          title={'Фильтры'}
          onClose={() => setFilterModal(false)}
          mobile={devices.mobile}
        >
          <Layout vertical between={10}>
            <UsersFiltersView
              handleKeyDown={handleKeyDown}
              cancelSearch={cancelSearch}
              handleFilterChange={handleFilterChange}
              roles={roles}
              businessUnits={businessUnits}
              inModal={true}
            />
            <Layout hAlign={'space-between'} between={10}>
              <Button
                extent={'L'}
                id={'clear-filter-button'}
                onClick={handleClearFilter}
                background={'#333'}
                color={'red'}
              >
                Очистить
              </Button>
              <Button
                extent={'L'}
                id={'confirm-filter-button'}
                onClick={(e) => handleConfirmFilter(e)}
              >
                Потвердить
              </Button>
            </Layout>
          </Layout>
        </Modal>
      )}
      {modal && (
        <Modal
          title={
            initialValue ? 'Изменить пользователя' : 'Создать пользователя'
          }
          onClose={() => {
            setModal(false);
            setInitialValue(null);
          }}
          mobile={devices.mobile}
        >
          <UsersCreateEditView
            initialValue={initialValue}
            setModal={setModal}
          />
        </Modal>
      )}
      {confirmDeleteModal.isModalOpen && (
        <Modal
          title="Удалить пользователя"
          onClose={() => {
            setConfirmDeleteModal({ isModalOpen: false });
          }}
        >
          <ConfirmDelete
            warningText={`Вы уверены, что хотите удалить пользователя ${confirmDeleteModal.name}?`}
            setModal={setConfirmDeleteModal}
            callback={handleDelete}
            isLoading={loading}
          />
        </Modal>
      )}
    </Page>
  );
});

export default UsersView;
