import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import { api } from './api/endpoints';
import { Permission } from './app.model';
import { UsersModel } from './pages/users/users.model';
import Firebase from './firebase';
import {
  BusinessControl,
  ProfileModel,
  AccessGroup,
} from './components/header/settings.model';
import { sizes } from './utils/variables';
import DictionaryStore from "./utils/dictionary/dictionary.store";

class AppStore {
  public isLoggedIn = !!localStorage.getItem('visor-access-token');
  public isNavOpen =
    localStorage.getItem('visor-is-nav-open') === 'false' ? false : true;
  public permissions?: Permission[];
  public userData?: UsersModel;
  public businessSettings?: BusinessControl;
  public profileUserData?: ProfileModel;
  public profileAccesses?: AccessGroup;
  public devices = {
    mobile: false,
    tablet: false,
    desktop: false,
  };
  public isMacOS = false


  public simpleTableView: boolean =
    localStorage.getItem('visor-simple-table-view') !== 'false';

  constructor() {
    makeAutoObservable(this);
  }

  public init = async () => {
    const dictionaryStore = DictionaryStore;
    await dictionaryStore.pageTitleDictionaryReq();
    const loginStatus = await this.checkToken();
    const deviceWidth = window.innerWidth;
    if (loginStatus === 401) {
      localStorage.removeItem('visor-access-token');
      localStorage.removeItem('visor-refresh-token');
      this.isLoggedIn = false;
    }

    if (deviceWidth <= sizes.mobile) {
      this.devices.mobile = true;
    } else if (deviceWidth <= sizes.tablet) {
      this.devices.tablet = true;
    } else {
      this.devices.desktop = true;
    }


    // @ts-ignore
    this.isMacOS = navigator.userAgentData?.platform === 'macOS';
    console.log("isMacOS:", this.isMacOS)
  };

  private checkToken = async () => {
    try {
      const headers = {
        Authorization: 'Bearer ' + this.getToken()!,
      };
      const response = await axios.get(api.get_me, { headers });
      this.setPermissions(response.data.me.access_list);
      this.setUserData(response.data.me.user_data);

      const { firebaseInit } = Firebase;
      await firebaseInit();

      await this.getBusinessSettings();

      return response.status;
    } catch (err: any) {
      console.error(err);
      return err?.response?.status;
    }
  };

  setIsLoggedIn = (status: boolean) => {
    this.isLoggedIn = status;
  };

  toggleSimpleTableView = () => {
      this.simpleTableView = !this.simpleTableView;
      localStorage.setItem(
        'visor-simple-table-view',
        JSON.stringify(this.simpleTableView)
      );
    };

  toggleIssNavOpen = () => {
    this.isNavOpen = !this.isNavOpen;
    localStorage.setItem('visor-is-nav-open', JSON.stringify(this.isNavOpen));
  };

  public getToken = () => {
    return localStorage.getItem('visor-access-token');
  };

  private setPermissions = (permissions: Permission[]) => {
    this.permissions = permissions;
  };

  private setUserData = (userData: UsersModel) => {
    this.userData = userData;
  };

  public getBusinessSettings = async () => {
    try {
      const headers = {
        Authorization: 'Bearer ' + this.getToken()!,
      };
      const responseSettings = await axios.get(api.owner, { headers });
      this.businessSettings = responseSettings.data.owner;
    } catch (err: any) {
      console.error(err);
    }
  };

  public getProfile = async () => {
    try {
      const headers = {
        Authorization: 'Bearer ' + this.getToken()!,
      };
      const responseSettings = await axios.get(api.profile, { headers });
      this.profileUserData = responseSettings.data.user_data;
      this.profileAccesses = responseSettings.data.accesses;
    } catch (err: any) {
      console.error(err);
    }
  };
}

export default new AppStore();
