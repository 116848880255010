import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {PurchaseBillsModel} from "../purchase.bills.model";
import PurchaseBillsPaymentStore from "./purchase.bills.payment.store";
import {renderInputs} from "../../../components/form/render.inputs";
import {Button} from "../../../styles/button";
import {isValid} from "../../../utils/isValid";
import {useEffect} from "react";

interface PurchaseBillsPaymentViewProps {
    initialValue: PurchaseBillsModel;
    setModal: (isOpen: boolean) => void;
}

const PurchaseBillsPaymentView = observer(({initialValue, setModal}: PurchaseBillsPaymentViewProps) => {

    const {
        isLoading,
        form,
        makeBillPaymentReq,
        setForm,
        destroy
    } = PurchaseBillsPaymentStore;

    useEffect(() => {
        destroy()
    }, [destroy]);

    const {amount} = form;

    const handleSubmit = async () => {
        const response = await makeBillPaymentReq(initialValue.id);

        if (response) {
            setModal(false);
            destroy();
        }
    }

    const inputs = [
        {field: amount, fieldName: 'amount', placeholder: 'Сумма', type: 'number', required: true},
    ]

    return (
        <Layout vertical between={20}>
            <Layout vertical between={10}>
                {renderInputs(form, inputs, setForm)}
            </Layout>
            <Layout hAlign="center">
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    disabled={isValid([inputs]) || isLoading}
                >{isLoading ? '' : 'Потвердить'}</Button>
            </Layout>
        </Layout>
    );
});

export default PurchaseBillsPaymentView;