import {observer} from "mobx-react";
import AppStore from "../../../app.store";
import {Page} from "../../../styles/page";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import {AddButton, TableHeader} from "../../../styles/table-header";
import {Filters} from "../../../styles/filters";
import EmployeeDeductionsFilterView from "./employee.deductions.filter.view";
import {useEffect, useState} from "react";
import EmployeeDeductionsStore from "./employee.deductions.store";
import {EmployeeDeductionsFilterModel, EmployeeDeductionsModel} from "./employee.deductions.model";
import {Button, FilterButton} from "../../../styles/button";
import TablePaginationView from "../../../utils/table.pagination.view";
import {TableContainer, TableContainerMobile} from "../../../styles/table";
import EmployeeDeductionsDesktopView from "./employee.deductions.desktop.view";
import EmployeeDeductionsCreateEditStore from "./employee.deductions.modal/employee.deductions.create.edit.store";
import EmployeeDeductionsMobileView from "./employee.deductions.mobile.view";
import ModalView from "../../../components/modal/modal.view";
import {Layout} from "../../../styles/layout";
import EmployeeDeductionsCreateEditView from "./employee.deductions.modal/employee.deductions.create.edit.view";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

const EmployeeDeductionsView = observer(() => {
    const {isNavOpen} = AppStore;
    const {
        employeeDeductions,
        isLoading,
        employeeDeductionsFilters,
        setEmployeeDeductionFilter,
        clearFilter,
        init
    } = EmployeeDeductionsStore;
    const {destroy} = EmployeeDeductionsCreateEditStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('EMPLOYEE DEDUCTIONS', 'TABLE HEADERS', controller.signal);
        init(controller.signal);
        return () => {
            controller.abort();
        }
    }, [destroyDictionary, dictionaryListReq, init]);

    const [filterModal, setFilterModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [initialValue, setInitialValue] = useState<EmployeeDeductionsModel | null>(null)

    const handleClearFilter = () => {
        clearFilter();
        setFilterModal(false);
    };

    const handleConfirmFilter = (e: any) => {
        setFilterModal(false);
    };

    const handleCloseCreateEditModal = () => {
        setModal(false);
        destroy();
    }

    const handleFilterChange = (e: any, objectKey: string, index?: number) => {
        const {
            target: {options, selectedIndex},
        } = e;
        if (objectKey === 'page' && index !== undefined) {
            setEmployeeDeductionFilter({page: index + 1} as EmployeeDeductionsFilterModel);
            return;
        }
        setEmployeeDeductionFilter({[objectKey]: options[selectedIndex].value} as EmployeeDeductionsFilterModel);
    };

    const cancelSearch = (e: any) => {
        if (e.target.value === '' && employeeDeductionsFilters.search !== '') {
            setEmployeeDeductionFilter({search: ''} as EmployeeDeductionsFilterModel);
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            setEmployeeDeductionFilter({search: e.target.value} as EmployeeDeductionsFilterModel);
        }
    };

    const prevPage = () => {
        if (employeeDeductionsFilters.page > 1) {
            setEmployeeDeductionFilter({page: employeeDeductionsFilters.page - 1} as EmployeeDeductionsFilterModel);
        }
    };

    const nextPage = () => {
        if (employeeDeductionsFilters.page < employeeDeductionsFilters.pages) {
            setEmployeeDeductionFilter({page: employeeDeductionsFilters.page + 1} as EmployeeDeductionsFilterModel);
        }
    };

    const pageLimits = [25, 100, 500, 1000];

    return (
        <Page isNavOpen={isNavOpen} vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('EMPLOYEE DEDUCTIONS')}</Headline>
            <TableHeader>
                <Filters hAlign={"space-between"} wrapped between={10}>
                    <EmployeeDeductionsFilterView
                        handleKeyDown={handleKeyDown}
                        cancelSearch={cancelSearch}
                        handleFilterChange={handleFilterChange}
                        inModal={false}
                    />
                </Filters>
                <FilterButton
                    id={'employee-deductions-filters'}
                    onClick={() => setFilterModal(true)}
                >
                    Фильтры
                </FilterButton>
                <AddButton type="button" id="add-employee-deduction" onClick={() => setModal(true)}>
                    Создать
                </AddButton>
            </TableHeader>
            <TableContainer isMacOS={AppStore.isMacOS}>
                <EmployeeDeductionsDesktopView
                    loading={isLoading}
                    deductions={employeeDeductions}
                    tableHeaders={getTableHeaders}
                />
            </TableContainer>
            <TableContainerMobile>
                <EmployeeDeductionsMobileView
                    loading={isLoading}
                    deductions={employeeDeductions}
                    tableHeaders={getTableHeaders}
                />
            </TableContainerMobile>
            <TablePaginationView
                prevPage={prevPage}
                nextPage={nextPage}
                pages={employeeDeductionsFilters.pages}
                currentPage={employeeDeductionsFilters.page}
                pageLimits={pageLimits}
                handleFilterChange={handleFilterChange}
            />
            {modal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseCreateEditModal}
                >
                    <EmployeeDeductionsCreateEditView
                        setModal={setModal}
                        initialValue={initialValue}
                    />
                </ModalView>
            )}
            {filterModal && (
                <ModalView
                    title={'Фильтры'}
                    onClose={() => setFilterModal(false)}
                >
                    <Layout vertical between={10}>
                        <EmployeeDeductionsFilterView
                            handleFilterChange={handleFilterChange}
                            handleKeyDown={handleKeyDown}
                            cancelSearch={cancelSearch}
                            inModal={true}
                        />
                        <Layout hAlign={'space-between'} between={10}>
                            <Button
                                extent={'L'}
                                id={'clear-filter-button'}
                                onClick={handleClearFilter}
                                background={'#333'}
                                color={'red'}
                            >
                                Очистить
                            </Button>
                            <Button
                                extent={'L'}
                                id={'confirm-filter-button'}
                                onClick={(e) => handleConfirmFilter(e)}
                            >
                                Потвердить
                            </Button>
                        </Layout>
                    </Layout>
                </ModalView>
            )}
        </Page>
    );
});

export default EmployeeDeductionsView;