import {PurchaseBillsFiltersModel, PurchaseBillsModel} from "./purchase.bills.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../api/endpoints";

const billsInitialState: PurchaseBillsModel[] = [];
const loadingInitialState: boolean = false;
const filtersInitialState: PurchaseBillsFiltersModel = {
    supplier_id: 0,
    business_unit_id: 0,
    product_id: 0,
    user_id: 0,
    delivery_status_id: 0,
    payment_status_id: 0,
    show_deleted: '',

    order_by: '',
    order_descending: false,
    page: 1,
    pages: 1,
    page_limit: 25
}

class PurchaseBillsStore {
    public bills = billsInitialState;
    public loading = loadingInitialState;
    public billsFilters = filtersInitialState;
    public receiveLoading = loadingInitialState;
    public cancelLoading = loadingInitialState;
    message = '';
    reason = '';

    constructor() {
        makeAutoObservable(this)
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setBills(data: any) {
        const {purchase_bill} = data;
        this.bills = purchase_bill;
    }

    setBillsFilterPages(pages: number) {
        this.billsFilters.pages = pages;
    }

    public initBillsPage = async (signal: AbortSignal) => {
        await this.getBillsReq(signal);
    }

    public getBillsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const headers = this.getHeaders();

        const filterUrl = Object.keys(this.billsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof PurchaseBillsFiltersModel;
            if (this.billsFilters[filterKeyName] !== 0 &&
                this.billsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.billsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.purchase_bills + filterUrl, {headers, signal});
            this.setBills(response?.data);
            this.setBillsFilterPages(response?.data.pages);
        } catch (e: any) {
            this.reason = e?.response?.data?.reason;
            toast.error(this.reason);
        } finally {
            this.setLoading(false);
        }
    }

    public setBillsFilter = ({
                                 supplier_id,
                                 business_unit_id,
                                 user_id,
                                 product_id,
                                 delivery_status_id,
                                 payment_status_id,
                                 show_deleted,
                                 page,
                                 page_limit
                             }: PurchaseBillsFiltersModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.billsFilters = {
            ...this.billsFilters,
            supplier_id: isUndefined(supplier_id) ? this.billsFilters.supplier_id! : supplier_id!,
            business_unit_id: isUndefined(business_unit_id) ? this.billsFilters.business_unit_id! : business_unit_id!,
            user_id: isUndefined(user_id) ? this.billsFilters.user_id! : user_id!,
            product_id: isUndefined(product_id) ? this.billsFilters.product_id! : product_id!,
            delivery_status_id: isUndefined(delivery_status_id) ? this.billsFilters.delivery_status_id! : delivery_status_id!,
            payment_status_id: isUndefined(payment_status_id) ? this.billsFilters.payment_status_id! : payment_status_id!,
            show_deleted: isUndefined(show_deleted) ? this.billsFilters.show_deleted! : show_deleted!,
            page: isUndefined(page) ? this.billsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.billsFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.billsFilters.page = 1;
        }

        void this.getBillsReq();
    }

    public receiveBillReq = async (billID: number) => {
        this.receiveLoading = true;
        const headers = this.getHeaders();
        const payload = {}

        try {
            const response = await axios.put(api.purchase_bills + `/receive/${billID}`, payload,{headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response
        } catch (e: any) {
            this.reason = e?.response?.data.reason;
            toast.error(this.reason);
        } finally {
            this.receiveLoading = false;
        }
    }

    public cancelBillReq = async (billID: number) => {
        this.cancelLoading = true;
        const headers = this.getHeaders();
        const payload = {}

        try {
            const response = await axios.put(api.purchase_bills + `/cancel/${billID}`, payload,{headers});
            this.message = response?.data.message;
            toast.success(this.message);
            return response
        } catch (e: any) {
            this.reason = e?.response?.data.reason;
            toast.error(this.reason);
        } finally {
            this.cancelLoading = false;
        }
    }

    private getHeaders = () => {
        const {getToken} = AppStore;
        return {
            Authorization: 'Bearer ' + getToken()!
        }
    }
}

export default new PurchaseBillsStore();