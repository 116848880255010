import {observer} from "mobx-react";
import {Page} from "../../../styles/page";
import AppStore from "../../../app.store";
import {Layout} from "../../../styles/layout";
import {Headline} from "../../../styles/headline";
import {primaryColor} from "../../../utils/variables";
import {useEffect, useState} from "react";
import {AddButton} from "../../../styles/table-header";
import {
    Table,
    TableBody,
    TableContainer,
    TableContainerMobile,
    TableHead,
    TCell,
    THeadCell,
    TRow
} from "../../../styles/table";
import AllowancesStore from "./allowances.store";
import TableSkeleton from "../../../utils/skeleton/table.skeleton";
import NoResultTitle from "../../../components/no_result_title/no_result_title.view";
import ModalView from "../../../components/modal/modal.view";
import {AllowancesModel} from "./allowances.model";
import AllowancesCreateEditView from "./allowance.modal/allowances.create.edit.view";
import AllowancesCreateEditStore from "./allowance.modal/allowances.create.edit.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";

const AllowancesView = observer(() => {
    const {isNavOpen} = AppStore;
    const [modal, setModal] = useState(false);
    const {isLoading, allowances, init} = AllowancesStore;
    const [initialValue, setInitialValue] = useState<AllowancesModel | null>(null);
    const {destroy} = AllowancesCreateEditStore;
    const {destroyDictionary, getTableHeaders, dictionaryListReq, getPageTitle} = DictionaryStore;

    useEffect(() => {
        destroyDictionary();
        const controller = new AbortController();
        dictionaryListReq('ALLOWANCES', 'TABLE HEADERS', controller.signal);
        void init(controller.signal);

        return () => {
            controller.abort();
        };

    }, [destroyDictionary, dictionaryListReq, init]);

    const handleCloseModal = () => {
        setModal(false);
        destroy();
    }

    return (
        <Page isNavOpen={isNavOpen} vertical between={20}>
            <Headline color={primaryColor}>{getPageTitle('ALLOWANCES')}</Headline>
            <Layout hAlign={"flex-end"}>
                <AddButton
                    id={'create-allowance'}
                    type={'button'}
                    onClick={() => setModal(true)}
                >
                    Создать
                </AddButton>
            </Layout>
            <TableContainer isMacOS={AppStore.isMacOS}>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{getTableHeaders('name')}</THeadCell>
                            <THeadCell>{getTableHeaders('description')}</THeadCell>
                        </TRow>
                    </TableHead>
                    {isLoading ? (
                        <TableSkeleton columns={2}/>
                    ) : (
                        <TableBody>
                            {allowances?.length! ? (
                                allowances.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.description}</TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={2}/>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            <TableContainerMobile>
                <Table>
                    <TableHead>
                        <TRow tableHead>
                            <THeadCell>{getTableHeaders('name')}</THeadCell>
                            <THeadCell>{getTableHeaders('description')}</THeadCell>
                        </TRow>
                    </TableHead>
                    {isLoading ? (
                        <TableSkeleton columns={2}/>
                    ) : (
                        <TableBody>
                            {allowances?.length! ? (
                                allowances.map((allowance, index) => (
                                    <TRow key={index}>
                                        <TCell>{allowance.name}</TCell>
                                        <TCell>{allowance.description}</TCell>
                                    </TRow>
                                ))
                            ) : (
                                <NoResultTitle colspan={2}/>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainerMobile>
            {modal && (
                <ModalView
                    title={initialValue ? 'Изменить' : 'Создать'}
                    onClose={handleCloseModal}
                >
                    <AllowancesCreateEditView
                        setModal={setModal}
                        initialValue={initialValue}
                    />
                </ModalView>
            )}
        </Page>
    );
});

export default AllowancesView;