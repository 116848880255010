import {BusinessUnitsTransferFundsModel} from "../business_units.transfer_funds.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import {toast} from "react-toastify";
import axios from "axios";
import {api} from "../../../api/endpoints";

const loadingInitialValue: boolean = false;
const formInitialValues: BusinessUnitsTransferFundsModel = {
    user_id: 0,
    type_id: 0,
    from_bu_id: 0,
    to_bu_id: 0,
    total_amount: 0,
    details: '',
}

class BusinessUnitsTransferFundsCreateView {
    public loading = loadingInitialValue;
    public form = formInitialValues;
    public message: string = '';
    public reason: string = '';

    constructor() {
        makeAutoObservable(this)
    }

    public destroy = () => {
        this.form = formInitialValues;
        this.loading = loadingInitialValue;
        this.message = '';
        this.reason = '';
    }

    public setForm = (form: BusinessUnitsTransferFundsModel) => {
        this.form = form
    }

    public transferFundsCreateInit = () => {
        this.form = formInitialValues;
    }

    public transferFundsCreateReq = async () => {
        this.loading = true
        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const payload = {
            from_bu_id: Number(this.form.from_bu_id),
            to_bu_id: Number(this.form.to_bu_id),
            total_amount: Number(this.form.total_amount),
            details: this.form.details,
            type_id: Number(this.form.type_id),
        }

        try {
            let response;

            response = await axios.post(api.transfer_funds, payload, {headers});

            this.message = response?.data.message;
            toast.success(this.message);
            return response;
        } catch (e: any) {
            console.log('e:', e)
            this.reason = e?.response?.data.reason
            toast.error(this.reason);
        } finally {
            this.loading = false;
        }
    }
}

export default new BusinessUnitsTransferFundsCreateView

