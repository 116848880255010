import {ProductsMovementsTrackingModel, ProductsMovementsTrackingFilterModel} from "./products.movements.tracking.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../../app.store";
import axios from "axios";
import {api} from "../../../api/endpoints";

const trackListInitialState: ProductsMovementsTrackingModel[] = [];
const loadingInitialState: boolean = false;
const filtersInitialState: ProductsMovementsTrackingFilterModel = {
    movement_id: 0,
    transaction_id: 0,
    transfer_id: 0,
    serial_number: '',
    serial_id: 0,
    shipment_id: 0
}

class ProductsMovementsTrackingStore {
    public trackList = trackListInitialState;
    public loading = loadingInitialState;
    public trackHistoryFilters = filtersInitialState;

    constructor() {
        makeAutoObservable(this);
    }

    setTrackList(data: any) {
        const {transactions} = data;
        this.trackList = transactions
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    public getTrackListReq = async (signal: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!
        }

        const filterUrl = Object.keys(this.trackHistoryFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof ProductsMovementsTrackingFilterModel;
            if (this.trackHistoryFilters[filterKeyName] !== 0 &&
                this.trackHistoryFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.trackHistoryFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.track_movement + filterUrl, {headers, signal});
            this.setTrackList(response?.data);
        } catch (e: any) {

        } finally {
            this.setLoading(false);
        }
    }

    public setMovementTrackFilters = ({
                                          movement_id,
                                          transaction_id,
                                          transfer_id,
                                          serial_number,
                                          serial_id,
                                          shipment_id,
    }: ProductsMovementsTrackingFilterModel, signal: AbortSignal) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.trackHistoryFilters = {
            ...this.trackHistoryFilters,
            movement_id: isUndefined(movement_id) ? this.trackHistoryFilters.movement_id! : movement_id!,
            transaction_id: isUndefined(transaction_id) ? this.trackHistoryFilters.transaction_id! : transaction_id!,
            transfer_id: isUndefined(transfer_id) ? this.trackHistoryFilters.transfer_id! : transfer_id!,
            serial_number: isUndefined(serial_number) ? this.trackHistoryFilters.serial_number! : serial_number!,
            serial_id: isUndefined(serial_id) ? this.trackHistoryFilters.serial_id! : serial_id!,
            shipment_id: isUndefined(shipment_id) ? this.trackHistoryFilters.shipment_id! : shipment_id!,
        }

        console.log('filters:', this.trackHistoryFilters)

        void this.getTrackListReq(signal)
    }

    public destroy = () => {
        this.trackHistoryFilters = filtersInitialState;
        this.loading = loadingInitialState;
        this.trackList = trackListInitialState;
    }
}

export default new ProductsMovementsTrackingStore();