import {
    BusinessUnitsTransferFundsFilterModel,
    BusinessUnitsTransferFundsModel
} from "./business_units.transfer_funds.model";
import {makeAutoObservable} from "mobx";
import AppStore from "../../app.store";
import axios from "axios";
import {api} from "../../api/endpoints";

const loadingInitialState: boolean = false;
const transferFundsInitialState: BusinessUnitsTransferFundsModel[] = [];

const transferFundsFiltersInitialState: BusinessUnitsTransferFundsFilterModel = {
    type_id: 0,
    business_unit_id: 0,

    order_by: '',
    order_descending: false,
    page: 1,
    pages: 0,
    page_limit: 25
}

class BusinessUnitsTransferFundsStore {
    public transferFunds = transferFundsInitialState;
    public loading = loadingInitialState;
    public transferFundsFilters = transferFundsFiltersInitialState;
    public message: string = '';
    public reason: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    public transferFundsPageInit = async (signal: AbortSignal) => {
        await this.getTransferFundsReq(signal)
    }

    setLoading(loading: boolean) {
        this.loading = loading;
    }

    setTransferFundsPages(pages: number) {
        this.transferFundsFilters.pages = pages;
    }

    setTransferFunds(data: any) {
        const {transfers} = data;
        this.transferFunds = transfers;
    }

    public getTransferFundsReq = async (signal?: AbortSignal) => {
        this.setLoading(true);

        const {getToken} = AppStore;
        const headers = {
            Authorization: 'Bearer ' + getToken()!,
        }

        const filterUrl = Object.keys(this.transferFundsFilters).reduce((filters, filter) => {
            const symbol = filters ? '&' : '?';
            const filterKeyName = filter as keyof BusinessUnitsTransferFundsFilterModel;
            if (this.transferFundsFilters[filterKeyName] !== 0 &&
                this.transferFundsFilters[filterKeyName] !== '') {
                filters += symbol + filter + '=' + this.transferFundsFilters[filterKeyName];
            }

            return filters;
        }, '');

        try {
            const response = await axios.get(api.transfer_funds + filterUrl, {headers, signal});
            this.setTransferFunds(response?.data);
            this.setTransferFundsPages(response?.data.pages);
        } catch (e: any) {

        } finally {
            this.setLoading(false);
        }
    }

    public setTransferFundsFilters = ({
                                          type_id,
                                          business_unit_id,
                                          page,
                                          page_limit
                                      }: BusinessUnitsTransferFundsFilterModel) => {
        const isUndefined = (value: any) => typeof value === 'undefined';
        this.transferFundsFilters = {
            ...this.transferFundsFilters,
            type_id: isUndefined(type_id) ? this.transferFundsFilters.type_id! : type_id!,
            business_unit_id: isUndefined(business_unit_id) ? this.transferFundsFilters.business_unit_id! : business_unit_id!,
            page: isUndefined(page) ? this.transferFundsFilters.page! : page!,
            page_limit: isUndefined(page_limit) ? this.transferFundsFilters.page_limit! : page_limit!
        };

        if (page_limit) {
            this.transferFundsFilters.page = 1;
        }

        void this.getTransferFundsReq();
    }
}

export default new BusinessUnitsTransferFundsStore()