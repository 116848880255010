import {observer} from "mobx-react";
import {Layout} from "../../../styles/layout";
import {Button} from "../../../styles/button";
import TransfersGoodsStore, {Products} from "./transfers.goods.store";
import DictionaryStore from "../../../utils/dictionary/dictionary.store";
import {isValid} from "../../../utils/isValid";
import TransferStore from "../transfer.store";
import BusinessUnitsStore from "../../business_units/business_units.store";
import React, {useEffect, useState} from "react";
import {renderInputs} from "../../../components/form/render.inputs";
import {Text} from "../../../styles/text";
import {modalTextColor, primaryColor} from "../../../utils/variables";
import {Table, TableBody, TableContainer, TableHead, TCell, THeadCell, TRow} from "../../../styles/table";
import {Select, Spin} from "antd";
import StockStore from "../../stock/stock.store";
import {Required, SelectView, TextInput} from "../../../styles/form";
import {runInAction} from "mobx";
import ModalView from "../../../components/modal/modal.view";
import TransfersGoodsSerialsView from "./transfers.goods.serials.view";

interface TransfersGoodsViewProps {
    setModal: (isOpen: boolean) => void;
}

const TransfersGoodsView = observer(({setModal}: TransfersGoodsViewProps) => {

    const {
        isLoading,
        addProductToList,
        deleteProduct,
        createTransferReq,
        form,
        destroyTransfersModal,
        setForm,
    } = TransfersGoodsStore;
    const {destroyModalDictionary, getModalPlaceholders, dictionaryListReq} = DictionaryStore;
    const {transfersReq} = TransferStore;
    const {businessUnits, setBusinessUnitFilter, clearBusinessUnitFilter} = BusinessUnitsStore;
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const {setStocksFilter, stocks, loading, destroyStocks} = StockStore;
    const [serialsModal, setSerialsModal] = useState(false);
    const [initialValue, setInitialValue] = useState<any>(null);
    const [initialValueIndex, setInitialValueIndex] = useState(0);

    useEffect(() => {
        destroyModalDictionary()
        const controller = new AbortController();
        void dictionaryListReq('TRANSFERS', 'MODAL', controller.signal);
        setBusinessUnitFilter({page_limit: 100000, return_balance: false})
        clearBusinessUnitFilter();
        return () => controller.abort();
    }, [setBusinessUnitFilter, clearBusinessUnitFilter]);

    const businessUnitsOptions = businessUnits?.map(
        ({id, name}) => ({label: name, value: id})
    )

    const stocksOptions = stocks?.map(
        ({quantity, part_number, name, brand, goods_id, has_serial}) =>
            ({
                label: `${part_number} | ${name} | ${brand}`,
                product_id: goods_id,
                quantity_in_hand: quantity,
                brand: brand,
                part_number: part_number,
                has_serial: has_serial,
                value: goods_id,
                name: name,
            })
    )

    const {from_business_unit_id, to_business_unit_id, details, products} = form;

    const inputs = [
        {
            field: to_business_unit_id,
            fieldName: 'to_business_unit_id',
            placeholder: getModalPlaceholders('to_business_unit_id'),
            type: 'select',
            required: true,
            options: businessUnitsOptions,
        },
        {
            field: details,
            fieldName: 'details',
            placeholder: getModalPlaceholders('details'),
            type: 'text',
        },
    ]

    const handleSelectProduct = (option: any) => {
        const product: Products = {
            product_id: option.product_id,
            quantity: 1,
            has_serial: option.has_serial,
            quantity_in_hand: option.quantity_in_hand,
            name: option.name,
            brand: option.brand,
            part_number: option.part_number,
            serial_ids: [],
        }
        addProductToList(product);
        setSelectedOption(null);
    }

    const handleChangeFromBusinessUnitID = (buID: number) => {
        destroyStocks()
        runInAction(() => {
            form.from_business_unit_id = buID;
            setStocksFilter({page: 1, page_limit: 1000000, business_unit_id: from_business_unit_id})
        })
    }

    const handleClickSubmit = async () => {
        const resp = await createTransferReq();

        if (resp) {
            setModal(false);
            destroyTransfersModal();
            await transfersReq();
        }
    }

    const handleDeleteProduct = (index: number) => {
        deleteProduct(index)
    }

    const handleChangeQuantity = (index: number, value: number) => {
        runInAction(() => {
            products[index].quantity = value
        })
    }

    const handleSerialsClick = (product: any, index: number) => {
        setInitialValue(product);
        setSerialsModal(true);
        setInitialValueIndex(index)
    }

    const handleCloseSerialModal = () => {
        setInitialValue(null);
        setSerialsModal(false);
        setInitialValueIndex(0)
    }

    return (
        <Layout vertical between={20}>
            <Layout hAlign={"space-around"}>

                {/* start of inputs */}
                <Layout widthSize={'30%'} vertical between={10}>
                    <Layout hAlign={"space-between"} vAlign={"center"}>
                        <Layout widthSize={'35%'}>
                            <Text color={modalTextColor} extent={'S'}>Отправитель</Text>
                            <Required>*</Required>
                        </Layout>
                        <Layout widthSize={'65%'}>
                            <SelectView
                                extent={'S'}
                                onChange={(e) => {
                                    handleChangeFromBusinessUnitID(+e.target.value)
                                }}
                                value={from_business_unit_id || 0}
                            >
                                <option key={0} disabled value={0}>
                                    Выберите отправителя
                                </option>
                                {businessUnitsOptions?.map((option: any, index: any) => (
                                    <option key={`${index}_${option.value}`} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </SelectView>
                        </Layout>
                    </Layout>
                    {renderInputs(form, inputs, setForm)}
                </Layout>
                {/* end of inputs */}


                {/* start of table */}
                <Layout widthSize={'65%'} vertical between={15}>
                    <Layout centered>
                        <Text extent={'M'} color={primaryColor} weight={'bold'}>Товары</Text>
                    </Layout>
                    <Layout>
                        <TableContainer style={{maxHeight: '45vh'}}>
                            <Table>
                                <TableHead>
                                    <TRow tableHead>
                                        <THeadCell style={{width: '50%'}}>Наименование</THeadCell>
                                        <THeadCell>Номер</THeadCell>
                                        <THeadCell>Бренд</THeadCell>
                                        <THeadCell>В наличии</THeadCell>
                                        <THeadCell>Количество</THeadCell>
                                        <THeadCell></THeadCell>
                                    </TRow>
                                </TableHead>
                                <TableBody>
                                    {products?.map((product, index) => (
                                        <TRow key={index} menu>
                                            <TCell>{product.name}</TCell>
                                            <TCell>{product.part_number}</TCell>
                                            <TCell>{product.brand}</TCell>
                                            <TCell>{product.quantity_in_hand}</TCell>
                                            <TCell>
                                                <Layout vertical between={5}>
                                                    <TextInput
                                                        value={product.quantity}
                                                        onChange={(e) => handleChangeQuantity(index, +e.target.value)}
                                                    />
                                                    {product.has_serial &&
                                                        <Text
                                                            color={primaryColor}
                                                            extent={'S'}
                                                            style={{fontStyle: 'italic', cursor: 'pointer'}}
                                                            onClick={() => handleSerialsClick(product, index)}
                                                        >
                                                            Серийные номера
                                                        </Text>
                                                    }
                                                </Layout>
                                            </TCell>
                                            <TCell>
                                                <Button
                                                    onClick={() => handleDeleteProduct(index)}
                                                >
                                                    -
                                                </Button>
                                            </TCell>
                                        </TRow>
                                    ))}
                                    <TRow>
                                        <TCell colSpan={2}>
                                            <Select
                                                disabled={from_business_unit_id === 0}
                                                showSearch
                                                options={stocksOptions}
                                                showArrow={false}
                                                notFoundContent={loading ? <Spin size={'small'}/> :
                                                    <Layout centered> Данных нет</Layout>}
                                                filterOption={(inputValue, option: any) =>
                                                    (option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                                placeholder={'Выберите продукт'}
                                                onChange={(_, option) => {
                                                    handleSelectProduct(option)
                                                }}
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={selectedOption}
                                                loading={loading}
                                            />
                                        </TCell>
                                        <TCell></TCell>
                                        <TCell></TCell>
                                        <TCell></TCell>
                                        <TCell></TCell>
                                    </TRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Layout>
                </Layout>
                {/* end of table */}

            </Layout>
            <Layout centered>
                <Button
                    extent="S"
                    heightSize="35px"
                    isLoading={isLoading}
                    onClick={handleClickSubmit}
                    disabled={isValid(inputs) || isLoading || products.length === 0}
                >
                    {isLoading ? '' : 'Потвердить'}
                </Button>
            </Layout>
            {serialsModal && (
                <ModalView
                    title={`Серийные номера ${initialValue.name}`}
                    onClose={handleCloseSerialModal}
                >
                    <TransfersGoodsSerialsView
                        initialValue={initialValue}
                        productIndex={initialValueIndex}
                        setModal={setSerialsModal}
                        businessUnitID={from_business_unit_id}
                    />
                </ModalView>
            )}
        </Layout>
    );
});

export default TransfersGoodsView;